<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { auth_store, common_store, route, $t, $services, $toast } = useCommonImports();
const form = ref(null);

const state = reactive({
  is_open: false,
  is_edit: false,
  show_warning: false,
});

const admin_users = computed(() => {
  return common_store.users.filter(user => user.is_manager).map(user => user.uid);
});

async function onSave() {
  try {
    await $services.users_v2.global_admins_patch({
      body: form.value,
    });
    await common_store.update_global_data({ users: true });
    $toast({
      title: $t('Role updated'),
      text: $t('Your changes to the role have been successfully saved'),
      type: 'success',
    });
    state.is_edit = false;
  }
  catch (err) {
    logger.error(err);
    $toast({
      text: err?.data?.message || $t('Something went wrong'),
      title: $t('Error'),
      type: 'error',
    });
  }
}
</script>

<template>
  <div>
    <div class="px-6 py-2.5 grid hover:bg-gray-50 grid-cols-12 text-sm items-center cursor-pointer" @click="state.is_open = !state.is_open;state.show_warning = false">
      <div class="col-span-8">
        <div class="font-medium text-gray-900">
          {{ $t('Global admins') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Full access to all modules and resources in the entire organization and manage asset admin users.') }}
        </div>
      </div>
      <div class="col-span-3">
        <HawkMembers :members="admin_users" type="badge" popover_avatar_size="badge" />
      </div>
      <div class="col-span-1 flex justify-end">
        <HawkButton icon type="text">
          <IconHawkChevronUp v-if="state.is_open" />
          <IconHawkChevronDown v-else />
        </HawkButton>
      </div>
    </div>
    <div v-if="state.is_open" class="relative p-6 border-t pt-3">
      <HawkButton
        v-if="!route?.params?.asset_id && !state.is_edit && !admin_users.length && (auth_store.logged_in_user_details?.is_owner
          || (auth_store.logged_in_user_details.is_internal && auth_store.check_permission('modify_users', route?.params?.asset_id)))" type="link" @click="state.is_edit = true;state.show_warning = false"
      >
        <IconHawkPlus />
        {{ $t('Add members') }}
      </HawkButton>
      <div v-else-if="state.is_edit" class="flex justify-between">
        <Vueform
          v-model="form"
          size="sm"
          :format-load="(data) => data"
          :display-errors="false"
          :endpoint="onSave" class="w-full"
          :columns="{ sm: { container: 12, label: 4, wrapper: 12 } }"
        >
          <div class="col-span-12">
            <HawkAssigneeInput
              :callbacks="{ user_filter: (item) => (item.organization && item.organization === auth_store?.current_organization_uid) }"
              class="w-[568px]"
              :multi="true"
              :options="{
                'name': 'users',
                'has_teams': false,
                'placeholder': $t('Select members'),
                'group-select': false,
                'object': false,
                'default': admin_users,
              }"
              :show_scoped_users="false"
              :show_scoped_teams="false"
              :format_load="true"
              @removed="state.show_warning = true"
            />
            <hr class="my-4">
            <div class="flex justify-between items-center gap-3 sticky bottom-0 mt-4">
              <div v-if="state.show_warning">
                <HawkAlertBanner color="warning">
                  <template #icon>
                    <IconHawkAlertTriangle />
                  </template>
                  <template #content>
                    {{ $t('Access provided directly or through teams will still be retained.') }}
                  </template>
                </HawkAlertBanner>
              </div>
              <div v-else />
              <div class="flex gap-3">
                <HawkButton type="outlined" @click="state.is_edit = false;state.show_warning = false">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  button-class="bg-blue-600"
                  name="submit"
                  :submits="true"
                >
                  {{ $t("Save") }}
                </ButtonElement>
              </div>
            </div>
          </div>
        </Vueform>
      </div>
      <template v-else>
        <div class="grid grid-cols-12 gap-8 text-sm">
          <div class="col-span-10 flex">
            <HawkMembers :members="admin_users" type="badge" :max_badges_to_display="5" popover_avatar_size="badge" />
          </div>
          <div class="col-span-2 flex justify-end">
            <HawkButton
              v-if="!route?.params?.asset_id && !state.is_edit && (auth_store.logged_in_user_details?.is_owner
                || (auth_store.logged_in_user_details.is_internal && auth_store.check_permission('modify_users', route?.params?.asset_id)))" type="link" @click="state.is_edit = true;state.show_warning = false"
            >
              {{ $t('Update') }}
            </HawkButton>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
