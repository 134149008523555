<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import DashboardCreateStandardMaps from '~/dashboard/components/create-dashboard/dashboard-create-standard-maps.vue';

const emit = defineEmits(['close', 'save']);
const route = useRoute();
const authStore = useAuthStore();
const dashboard_store = useDashboardStore();
const form$ = ref(null);

async function onCreate() {
  const form_data = form$.value.data;
  let references;
  if (route.name === 'pm-dashboard')
    references = [
      {
        resource_id: route.params.schedule_id,
        resource_type: 'project_management',
      },
    ];
  else if (route.name === 'form-template-detail-report')
    references = [
      {
        resource_id: route.params.form_id,
        resource_type: 'forms',
      },
    ];
  else references = [];

  const sharing_data = {
    ...(form_data.assignees_input.assignees_type === 'Everyone'
      ? { public: true, members: {} }
      : {
          members: form_data.assignees_input.assignees.reduce((acc, member) => {
            acc[member.uid] = 'read';
            return acc;
          }, {}),
          public: false,
        }
    ),
  };

  let body;
  if (form_data.configuration_option === 'standard')
    body = {
      name: form_data.dashboard_name,
      report_data: {
        module: form_data.module,
        data: {
          id: form_data.map,
        },
      },
      default: false,
      standard: true,
      organization: authStore.current_organization?.uid,
      ...(dashboard_store.scope !== 'organization'
        ? {
            asset: dashboard_store.widget_asset,
          }
        : {}),
      references,
      ...sharing_data,
    };

  else
    body = {
      name: form_data.dashboard_name,
      report_data: {},
      default: false,
      organization: authStore.current_organization?.uid,
      ...(dashboard_store.scope !== 'organization'
        ? {
            asset: dashboard_store.widget_asset,
          }
        : {}),
      references,
      ...sharing_data,
    };

  try {
    await dashboard_store.create_dashboard(body);
    emit('close');
  }
  catch (err) {
    emit('close');
  }
}
</script>

<template>
  <HawkModalTemplate content_class="rounded-lg -mt-[calc(35vh)]" @close="emit('close')">
    <template #header_left>
      <div class="flex flex-col justify-start">
        {{ $t("New Dashboard") }}
      </div>
    </template>
    <template #default>
      <Vueform
        ref="form$"
        size="sm"
        class="w-[600px]"
        :columns="{
          default: { container: 12, label: 4, wrapper: 12 },
          sm: { container: 12, label: 4, wrapper: 12 },
          md: { container: 12, label: 4, wrapper: 12 },
        }"
      >
        <TextElement
          name="dashboard_name"
          class="w-full"
          :label="$t('Name')"
          :placeholder="$t('Enter name')"
        />
        <RadiogroupElement
          v-if="['global'].includes(dashboard_store.scope)"
          class="mt-4"
          default="custom"
          :label="$t('Type')"
          name="configuration_option"
          :items="[
            {
              value: 'custom',
              label: $t('Custom'),
              description: $t('Custom'),
            },
            {
              value: 'standard',
              label: $t('Standard'),
              description:
                $t('Standard'),
            },
          ]"
        />
        <SelectElement
          :conditions="[['configuration_option', '==', 'standard']]"
          name="module"
          default="maps"
          disabled
          :can-deselect="false"
          :native="false"
          :search="true"
          :label="$t('Module')"
          :items="[{
            label: 'Maps',
            value: 'maps',
          }]"
          class="my-4"
        />
        <ObjectElement
          class="my-4"
          name="assignees_input"
          :label="$t('Share with')"
        >
          <RadiogroupElement
            name="assignees_type"
            :items="[
              'Everyone',
              'Custom',
            ]"
            default="Custom"
          />
          <hawk-assignee-input
            :options="{
              name: 'assignees',
              conditions: [
                ['assignees_input.assignees_type', 'Custom'],
              ],
              has_teams: true,
              placeholder: $t('Select members'),
              columns: {
                default: { container: 12, label: 12, wrapper: 12 },
                sm: { container: 12, label: 12, wrapper: 12 },
                md: { container: 12, label: 12, wrapper: 12 },
              },
            }"
            :multi="true"
          />
        </ObjectElement>
        <DashboardCreateStandardMaps :conditions="[['module', '==', 'maps']]" />
      </Vueform>
    </template>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="onCreate()"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
