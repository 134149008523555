<script setup>
import { useRouter } from 'vue-router';

const form = ref({});
const state = reactive({
  is_loading: false,
  error_data: null,
});

const $toast = inject('$toast');
const $services = inject('$services');
const router = useRouter();

async function handleForgotPassword() {
  try {
    state.is_loading = true;
    const response = await $services.auth.requestForgotPassword(form.value);
    if (response.status === 200) {
      state.is_loading = false;
      router.push({
        name: 'check-email',
        state: form.value,
      });
    }
    else {
      state.is_loading = false;
      $toast({
        text: 'Something went wrong',
        type: 'error',
      });
    }
  }
  catch (error) {
    state.is_loading = false;
    state.error_data = error.data;
    logger.log('error: ', error);
  }
}
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-6">
        <HawkIconTaskmapperIcon />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t('Reset your password') }}
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-[0_2px_16px_8px_rgba(112,117,126,0.08)] sm:rounded-lg sm:px-10">
        <div class="flex text-left font-medium text-sm mb-4">
          {{ $t("Enter your email address and we'll send you a link to reset your password") }}
        </div>
        <Vueform
          v-model="form" :display-errors="false"
          size="sm"
          :columns="{
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
          }"
          @submit="handleForgotPassword"
        >
          <div class="col-span-12 grid gap-6">
            <TextElement name="email" input-type="email" rules="required|email" :placeholder="$t('Email')" />
            <ButtonElement
              :disabled="!form.email || form.email.trim().length === 0"
              name="submit" :button-label="$t('Reset Password')"
              :submits="true" :loading="state.is_loading"
              button-class="w-full bg-blue-600 !py-[9px] !text-sm"
            />
            <div v-if="state.error_data" class="col-span-12">
              <HawkInfo type="error" :title="state.error_data.title" capitalize_title :description="state.error_data.message" :redirect="state.error_data.code === 'CORE_51' ? $t('Sign up') : ''" @redirectTo="$router.push({ name: 'sign-up' })" />
            </div>
          </div>
        </Vueform>
      </div>
      <div class="py-8 flex justify-center text-gray-600 text-sm">
        {{ $t('Back') }} {{ $t('to') }}
        <RouterLink class="mx-1" to="sign-in">
          <div class="text-blue-700 hover:text-blue-500 font-semibold text-sm">
            {{ $t("Sign in") }}
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
