<script setup>
import { Validator } from '@vueform/vueform';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  published: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  is_sub_step: {
    type: Boolean,
  },
  sub_step: {
    type: Object,
  },
  submit: {
    type: Function,
    default: () => {
      return true;
    },
  },
  existing_members: {
    type: Array,
  },
});

const emit = defineEmits(['close']);
const auth_store = useAuthStore();

const sub_step = computed(() => props.sub_step);
const is_sub_step = computed(() => props.is_sub_step);

const form$ = ref(null);

const membersCount = class extends Validator {
  check(value) {
    return form$.value?.data?.members?.length >= value;
  }

  get message() {
    return 'The entered value should not be greater than the members count';
  }
};

const existing_members_excluding_current_user = new Set([...(props.existing_members || []), auth_store.logged_in_user_details?.user_id]);

const existing_members = computed(() => {
  return props.published ? Array.from(existing_members_excluding_current_user) : props.existing_members; // Filter out the current user while creating transmittal
});
</script>

<template>
  <hawk-modal-container content_class="rounded-lg w-[600px]">
    <Vueform
      ref="form$"
      :default="sub_step"
      :presets="['']"
      size="sm"
      :display-errors="false"
      :endpoint="props.submit"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 4 },
      }"
      @mounted="form$ => props.sub_step ? form$.load(props.sub_step, true) : null"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <span v-if="sub_step"> {{ $t('Edit') }} </span>
            <span v-else-if="is_sub_step"> {{ $t('Add sub step') }} </span>
            <span v-else> {{ $t('Add step') }} </span>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false" :class="{ 'pointer-events-none': disabled }">
          <TextElement name="name" :disabled="published || disabled" :label="$t('Name')" />
          <RadiogroupElement
            class="mt-4"
            :label="$t('Condition')"
            rules="required"
            :disabled="published || disabled"
            name="condition"
            :items="[
              { label: 'Require all members to approve', value: 'AND' },
              { label: 'Require any members to approve', value: 'OR' },
            ]"
          />
          <hawk-assignee-input
            class="mt-4"
            :multi="true"
            :format_load="true"
            :options="{
              label: $t('Add members'),
              name: 'members',
              existing_users: existing_members,
              disabled,
              rules: 'required',
              placeholder: $t('Add members and teams'),
            }"
          />
          <TextElement
            name="min_approvals" :label="$t('Minimum Approval')" input-type="number" class="mt-4" :default="1"
            :conditions="[
              ['condition', '==', 'OR'],
            ]"
            :rules="['required', 'numeric', 'min:1', membersCount]"
          />
          <RadiogroupElement
            v-if="!(sub_step || is_sub_step)"
            class="mt-4"
            rules="required"
            :label="$t('If rejected')"
            name="on_reject"
            :items="[
              { label: 'Continue to the next block', value: 'continue' },
              { label: 'Back to the initiator', value: 'revert' },
            ]"
          />
        </hawk-modal-content>
        <hawk-modal-footer v-if="!disabled">
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" text="Cancel" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
