<script setup>
import { useRoute, useRouter } from 'vue-router';
import { find } from 'lodash-es';
import FamShare from '~/forms-as-module/components/fam-share.vue';
import FormTemplateIcon from '~/forms/atoms/form-template-icon.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import FormTemplateDetailDropdown from '~/forms/components/form-template-detail/form-template-detail-dropdown.vue';
import { useFormsStore } from '~/forms/store/forms.store';

const emit = defineEmits('viewActivity');
const $t = inject('$t');
const router = useRouter();
const route = useRoute();

const forms_store = useFormsStore();
const form_template_detail_store = useFormTemplateDetailStore();

const is_share_loading = ref(false);

const view_items = computed(() => {
  return [
    ...(form_template_detail_store.is_template_flow
      ? [{
          uid: 'overview',
          route_name: 'fam-overview',
          leftSlot: IconHawkGridOne,
          tooltip_text: $t('Overview'),
        }]
      : []),
    {
      uid: 'list',
      route_name: 'fam-list',
      leftSlot: IconHawkTableTwo,
      tooltip_text: $t('List view'),
    },
    {
      uid: 'calendar',
      route_name: 'fam-calendar',
      leftSlot: IconHawkCalendar,
      tooltip_text: $t('Calendar view'),
    },
    {
      uid: 'kanban',
      route_name: 'fam-kanban',
      leftSlot: IconHawkAlignTopArrowTwo,
      tooltip_text: $t('Kanban view'),
    },
    {
      uid: 'gallery',
      route_name: 'fam-gallery',
      leftSlot: IconHawkImageOne,
      tooltip_text: $t('Gallery view'),
    }]
  ;
});

function changeLayout(event) {
  switch (event.uid) {
    case 'overview':{
      if (form_template_detail_store.is_template_flow)
        router.push({ name: 'fam-overview', params: { ...route.params } });
      break;
    }
    case 'list':
      router.push({ name: 'fam-list', params: { ...route.params } });
      break;
    case 'calendar':
      router.push({ name: 'fam-calendar', params: { ...route.params } });
      break;
    case 'kanban':
      router.push({ name: 'fam-kanban', params: { ...route.params } });
      break;
    case 'gallery':
      router.push({ name: 'fam-gallery', params: { ...route.params } });
      break;
  }
  setTimeout(() => {
    forms_store.forms_track_events('View changed');
  }, 10);
}
</script>

<template>
  <div>
    <HawkPageHeader>
      <template #title>
        <div class="flex items-center gap-2">
          <FormTemplateIcon
            v-if="form_template_detail_store?.form_template_detail?.icon"
            :icon_id="form_template_detail_store?.form_template_detail?.icon"
            class="h-5 w-5"
          />
          <HawkText :content="form_template_detail_store?.form_template_detail?.name" :length="50" />
        </div>
      </template>
      <template #left>
        <HawkButtonGroup
          :items="view_items"
          icon
          size="md"
          :active_item="find(view_items, function(item) { return item.route_name === route.name; })?.uid"
          @select="changeLayout($event)"
        />
      </template>
      <template #right>
        <div class="flex">
          <FamShare v-if="form_template_detail_store.is_template_flow && form_template_detail_store?.form_template_detail?.can_modify_template" :is_share_loading="is_share_loading" />
          <new-form-button class="ml-3" />
          <FormTemplateDetailDropdown v-if="form_template_detail_store.is_template_flow && form_template_detail_store?.form_template_detail?.uid" is_form_v2 @viewActivity="emit('viewActivity')" @isShareLoading="is_share_loading = $event" />
        </div>
      </template>
    </HawkPageHeader>
  </div>
</template>
