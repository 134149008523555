<script setup>
import { computed } from 'vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  truncate: {
    type: Boolean,
    default: false,
  },
  active_classes: {
    type: String,
    default: '',
  },
});
const store = useInventoryStore();

const vendor = computed(() => {
  return store.get_location_details({
    uid: props?.uid,
    type: 'vendor',
  });
},
);
</script>

<template>
  <div
    class="text-gray-600 text-sm"
    :class="active_classes"
  >
    {{ vendor && vendor.name || '-' }}
  </div>
</template>
