<script setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { watchDebounced } from '@vueuse/core';
import { cloneDeep, isEqual, isNil } from 'lodash-es';
import { getLocaleObj } from '~/project-management/composables/pm-i18n';
import { useI18nStore } from '~/common/stores/i18n.store.js';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useProjectManagementStore } from '~/project-management/store/pm.store.js';
import { getGroupingFunction } from '~/project-management/constants/pm-grouping.js';
import PmCustomizeColumns from '~/project-management/components/pm-customize-columns.vue';

const $t = inject('$t');
const $date = inject('$date');
const $toast = inject('$toast');

const common_store = useCommonStore();
const i18n_store = useI18nStore();
const project_management_store = useProjectManagementStore();
const {
  active_task,
  active_schedule,
  active_view,
  gantt_instance,
  markers,
  is_fullscreen,
  is_searching,
  is_refresh_required,
  search_config,
  active_schedule_data,
} = storeToRefs(project_management_store);
const { current_lang } = storeToRefs(i18n_store);

const {
  modify_config,
  set_active_task_uid,
  set_search_config,
  set_view_dirtiness,
  set_children_tasks,
  set_all_tasks_open_state,
  search_tasks,
} = project_management_store;

// data
const state = reactive({
  search_results: [],
  parents_of_search_results: [],
  attached_events: [],
  filtered_task_ids: [],
  groups_cache: [],
  is_recalculation_enabled: true,
  gantt_sort_field: null,
  is_initializing: true,
  set_wbs_to_max: false,
  expand_all_after_parse: false,
});

// computed
const search_string = computed(() => search_config.value.search_string);
const current_match_index = computed(() => search_config.value.current_match_index);
const active_date_range = computed(() => active_view.value.data.active_date_range);
const wbs_level = computed(() => active_view.value.data.wbs_level);
const layout = computed(() => active_view.value.data.layout);
const group_by = computed(() => active_view.value.data.group_by);
const columns = computed(() => active_view.value.data.columns);
const grid_width = computed(() => active_view.value.data.grid_width);
const filters = computed(() => active_view.value.data.filters);
const zoom_level = computed(() => active_view.value.data.zoom_level);

// lifecycle methods
onMounted(() => {
  const new_gantt_instance = Gantt.getGanttInstance();
  gantt_instance.value = new_gantt_instance;
  window.gantt = new_gantt_instance;
  const interval = setInterval(() => {
    if (active_view.value.uid) {
      clearInterval(interval);
      setupGantt();
    }
  }, 500);
});

onUnmounted(() => {
  gantt_instance.value?.destructor?.();
  gantt_instance.value = null;
  project_management_store.$reset();
});

// watches ⌚
watchDebounced(
  search_string,
  async (value) => {
    set_active_task_uid(null);
    state.search_results = [];
    state.parents_of_search_results = [];

    const search_string_lower_case = value.trim().toLowerCase();

    if (search_string_lower_case && active_schedule.value?.is_dynamic_loading) {
      is_searching.value = true;
      await search_tasks(search_string_lower_case);
      gantt.clearAll();
      state.set_wbs_to_max = true;
      state.expand_all_after_parse = true;
      gantt.parse(active_schedule_data.value);
      is_searching.value = false;
    }

    if (search_string_lower_case)
      gantt.eachTask((task) => {
        if (task.name.toLowerCase().includes(search_string_lower_case) || task.id.toLowerCase().includes(search_string_lower_case)) {
          state.search_results.push(task.id);
          gantt.eachParent((task) => {
            state.parents_of_search_results.push(task.id);
          }, task.id);
        }
      });

    const match_index = state.search_results.length ? 0 : -1;

    set_search_config({
      current_match_index: match_index,
      match_count: state.search_results.length,
      search_results: state.search_results,
    });
    gantt.refreshData();
    gantt.scrollTo(0, 0);

    if (match_index >= 0)
      gantt.showTask(state.search_results?.[match_index]);
  },
  { debounce: active_schedule.value?.is_dynamic_loading ? 500 : 100 },
);

watch(current_lang, () => {
  logger.info('%cLanguage change detected. Reloading...', 'font-weight: bold; color: tomato;');
  is_refresh_required.value = true;
});

watch(current_match_index, (value) => {
  if (value >= 0)
    gantt.showTask(state.search_results?.[value]);
});

watch(zoom_level, (level) => {
  gantt.ext.zoom.setLevel(level);
  gantt.render();
});

watch(active_date_range, (value) => {
  if (!value?.from || !value?.to) {
    gantt.render();
    return;
  }
  gantt.config.start_date = value.from || null;
  gantt.config.end_date = value.to || null;
  addDateRangeBuffer();
  gantt.render();
  gantt.showDate(value.from);
});

watch(wbs_level, () => {
  filterTasks();
  gantt.refreshData();
  gantt.render();
});

watch(group_by, (value) => {
  groupBy(value);
});

watch(layout, () => {
  setLayout();
});

watch(columns, (value) => {
  setupColumns(value);
  gantt.render();
});

watch(grid_width, (value) => {
  gantt.config.grid_width = value;
  gantt.render();
});

watch(filters, (value) => {
  try {
    filterTasks(value);
  }
  catch (error) {
    state.filtered_task_ids = [];
    logger.error(error);
  }
  gantt.render();
},
{ deep: true });

// methods
const customize_columns_modal = useModal({
  component: PmCustomizeColumns,
  attrs: {
    onClose() {
      customize_columns_modal.close();
    },
  },
});

function initializePlugins() {
  gantt.plugins({
    marker: true,
    tooltip: true,
  });
}

function setupGanttConfig() {
  gantt.i18n.setLocale(getLocaleObj($t));
  // enables converting server-side dates from UTC to a local time zone (and backward) while sending data to the server
  gantt.config.server_utc = true;
  // stores the types of links dependencies
  gantt.config.links.start_to_start = 'SS';
  gantt.config.links.start_to_finish = 'SF';
  gantt.config.links.finish_to_start = 'FS';
  gantt.config.links.finish_to_finish = 'FF';
  // enables sorting in the table
  gantt.config.sort = true;
  // matches dates in ISO format. example: "2022-07-21T08:37:23.823Z"
  gantt.config.date_format = '%Y-%m-%d %T %H:%i%Z';
  // enables the possibility to reorder grid columns by drag and drop
  gantt.config.reorder_grid_columns = true;
  // enables automatic adjusting of the grid's columns to the grid's width
  gantt.config.autofit = true;
  // sets the default height for rows of the table
  gantt.config.row_height = 40;
  // enables keyboard navigation in gantt
  gantt.config.keyboard_navigation = true;
  // sets the height of task bars in the timeline area
  gantt.config.bar_height = 6;
  // specifies the name of the dataStore connected to the resourceGrid/resourceTimeline/resourceHistogram views
  gantt.config.resource_store = 'resource';
  // defines the property of a task object that stores a resource id associated with resourceGrid/Timeline/Histogram/Calendar
  gantt.config.resource_property = 'assignees';
  // opens all branches on load
  gantt.config.open_tree_initially = false;
  // tells the resource timeline to render elements and call templates for non-allocated cells
  gantt.config.resource_render_empty_cells = true;
  // sets the height of the time scale and the header of the grid
  gantt.config.scale_height = 40;
  // enables showing tasks that are not between gantt.config.start_date and gantt.config.end_date in the Gantt chart
  gantt.config.show_tasks_outside_timescale = false;
  // enables showing error alerts in case of unexpected behavior
  gantt.config.show_errors = false;

  // performance options [https://docs.dhtmlx.com/gantt/desktop__performance.html]
  gantt.config.show_task_cells = true;
  // generates a background image for the timeline area instead of rendering actual columns' and rows' lines
  gantt.config.static_background = true;
  // specifies that only visible part of the time scale is rendered on the screen
  gantt.config.smart_scales = true;
  // it's set to marker for optimized performance [https://docs.dhtmlx.com/gantt/api__gantt_order_branch_config.html]
  gantt.config.order_branch = 'marker';
  // activates the read-only mode for the Gantt chart
  gantt.config.readonly = true;
  // enables dynamic loading in the Gantt chart
  gantt.config.branch_loading = true;
  // custom types
  gantt.config.types.virtual = 'virtual';
  gantt.config.types.wbs = 'wbs';
}

function setupTemplates() {
  gantt.templates.parse_date = date => new Date(date);
  gantt.templates.format_date = date => $date(date, 'DATE_MED');
  gantt.templates.tooltip_text = (_start, _end, task) => {
    if (task?.type === 'virtual')
      return '';
    return `
      <span class="font-semibold">Activity</span>: ${task.name} <br/>
      <span class="font-semibold">Progress</span>: ${task.progress_str} <br/>
      <span class="font-semibold">Start</span>: ${task.start_date_str} <br/>
      <span class="font-semibold">Finish</span>: ${task.end_date_str} <br/>
      <span class="font-semibold">Critical</span>: ${task?.is_critical ? 'Yes' : 'No'}
    `;
  };
  gantt.templates.grid_open = (item) => {
    const children = gantt.getChildren(item.id);
    if (item?.is_loading_children)
      return '<div class="gantt_tree_icon gantt_loading_children"></div>';
    if (!children.length && item.$open)
      return '<div class="gantt_tree_icon gantt_open"></div>';
    return `<div class="gantt_tree_icon gantt_${item.$open ? 'close' : 'open'}"></div>`;
  };
  gantt.templates.marker_class = (marker) => {
    if (
      (marker.name === 'today' && !active_view.value.data.feature_visibility.today_line)
            || (marker.name === 'data-date'
              && !active_view.value.data.feature_visibility.data_date_line)
    )
      return 'hidden';
  };
  gantt.templates.task_class = (_start, _end, task) => {
    const classes = [];
    switch (task.type) {
      case 'virtual':
        classes.push('pm-virtual');
        break;
      case gantt.config.types.project:
        classes.push('pm-project');
        break;
      case gantt.config.types.wbs:
        classes.push('pm-wbs');
        break;
    }
    if (active_view.value.data.feature_visibility.critical_path && task?.is_critical)
      classes.push('pm-critical-task');

    if (task.progress < task.percent_schedule_complete)
      classes.push('pm-overdue');

    if (task.progress === 1)
      classes.push('pm-completed');

    return classes.join(' ');
  };
  gantt.templates.link_class = (link) => {
    if (!active_view.value.data.feature_visibility.links)
      return 'is-display-none';

    if (active_view.value.data.feature_visibility.critical_path) {
      const source = gantt.getTask(link.source);
      const target = gantt.getTask(link.target);
      if (source?.is_critical && target?.is_critical)
        return 'pm-critical-link';
    }
    return '';
  };
  gantt.templates.timeline_cell_class = (_task, date) => {
    const classes = ['!border-0'];
    const from = dayjs(active_date_range.value.from);
    const to = dayjs(active_date_range.value.to);
    if (from.isValid() && to.isValid() && date >= new Date(from.format()) && date <= new Date(to.format()))
      classes.push('!bg-gray-200');

    return classes.join(' ');
  };
  gantt.templates.grid_row_class = (_start, _end, task) => {
    const classes = [];

    if (task.type === 'virtual')
      classes.push('pm-virtual-row');
    else if (
      task.type === gantt.config.types.project
            || task.type === gantt.config.types.wbs
    )
      classes.push('pm-project-row');

    if (search_string.value && state.search_results.includes(task.id))
      classes.push('pm-highlighted-grey');

    if (state.search_results[current_match_index.value] === task.id)
      classes.push('pm-highlighted');

    return classes.join(' ');
  };
  const getTaskFitValue = (task) => {
    const task_start_pos = gantt.posFromDate(task.start_date);
    const task_end_pos = gantt.posFromDate(task.end_date);

    const width = task_end_pos - task_start_pos;
    const progress_text = active_view.value.data.feature_visibility.percentage_complete
      ? `, ${Math.round(task.progress * 100)}%`
      : '';
    const task_text = `${task.name}${progress_text}`;
    const text_width = task_text.length * 9;

    if (width < text_width) {
      const gantt_last_date = gantt.getState().max_date;
      const gantt_end_pos = gantt.posFromDate(gantt_last_date);
      if (gantt_end_pos - task_end_pos < text_width)
        return 'left';

      else
        return 'right';
    }
    else {
      return 'center';
    }
  };
  gantt.templates.leftside_text = (_start, _end, task) => {
    if (!active_view.value.data.feature_visibility.activity_name)
      return '';

    if (task.type === 'wbs')
      return '';

    if (task.type === 'milestone') {
      return '';
    }
    else if (getTaskFitValue(task) === 'left') {
      const progress_text = active_view.value.data.feature_visibility.percentage_complete
        ? `, ${Math.round(task.progress * 100)}%`
        : '';
      return `${task.name}${progress_text}`;
    }
    return '';
  };
  gantt.templates.rightside_text = (_start, _end, task) => {
    if (task.type === 'wbs')
      return '';

    let rightside_text = '';

    if (active_view.value.data.feature_visibility.overdue && task.progress < task.percent_schedule_complete)
      rightside_text = '<span class="w-full bg-red-100"></span>';

    if (!active_view.value.data.feature_visibility.activity_name) {
      if (
        task.type === 'milestone'
              || task.type === gantt.config.types.project
              || task.type === gantt.config.types.wbs
      )
        return '';

      return rightside_text;
    }

    if (task.type === 'milestone')
      return task.name;

    if (
      task.type === gantt.config.types.project
            || task.type === gantt.config.types.wbs
    )
      return '';

    if (getTaskFitValue(task) === 'right') {
      const progress_text = active_view.value.data.feature_visibility.percentage_complete
        ? `, ${Math.round(task.progress * 100)}%`
        : '';
      const task_text = `${task.name}${progress_text}`;
      rightside_text += task_text;
    }
    return rightside_text;
  };
  gantt.templates.task_text = (_start, _end, task) => {
    if (!active_view.value.data.feature_visibility.activity_name)
      return '';
    if (task.type === 'milestone') {
      return task.name;
    }
    else if (['wbs', 'project'].includes(task.type)) {
      return `${task.name}&nbsp;&nbsp;<span>${new Date(
              task.start_date,
            ).toDateString()} &mdash; ${new Date(
              task.end_date,
            ).toDateString()}<span>`;
    }
    else if (getTaskFitValue(task) === 'center') {
      const progress_text = active_view.value.data.feature_visibility.percentage_complete
        ? `, ${Math.round(task.progress * 100)}%`
        : '';
      return `${task.name}${progress_text}`;
    }
    return '';
  };
}

function addMarkers() {
  markers.value.forEach(marker => gantt.addMarker(marker));
}

function addDateRangeBuffer() {
  const range = gantt.getSubtaskDates();
  const scale_unit = gantt.getState().scale_unit;
  const date_buffer_amount = 1;

  if (range.start_date && range.end_date) {
    gantt.config.start_date = gantt.calculateEndDate(
      range.start_date,
      -date_buffer_amount,
      scale_unit,
    );
    gantt.config.end_date = gantt.calculateEndDate(
      range.end_date,
      date_buffer_amount,
      scale_unit,
    );
  }
}

function setupTaskLayers() {
  setupFloatTaskLayer();
  setupBaselineTaskLayer();
}

function setupFloatTaskLayer() {
  gantt.addTaskLayer({
    renderer: {
      render: (task) => {
        if (!active_view.value.data.feature_visibility.float || task.type !== 'task')
          return false;

        if (!task?.free_slack)
          return false;

        const state = gantt.getState().drag_mode;
        if (state === 'resize' || state === 'move')
          return false;

        const slack_start = new Date(task.end_date);
        const slack_end = gantt.calculateEndDate(
          slack_start,
          task.free_slack,
        );
        const sizes = gantt.getTaskPosition(task, slack_start, slack_end);
        const el = document.createElement('div');

        el.className = 'slack';
        el.style.left = `${sizes.left}px`;
        el.style.top = `${sizes.top + 13}px`;
        el.style.width = `${sizes.width}px`;
        el.style.height = `${sizes.height}px`;

        return el;
      },
    },
  });
}

function setupBaselineTaskLayer() {
  const has_baseline = active_schedule.value?.baseline;
  const baseline_start = has_baseline ? 'bl_start' : 'planned_start';
  const baseline_end = has_baseline ? 'bl_finish' : 'planned_finish';

  gantt.addTaskLayer({
    renderer: {
      render: (task) => {
        if (!active_view.value.data.feature_visibility.baseline)
          return false;

        if (task[baseline_start] && task[baseline_end]) {
          const sizes = gantt.getTaskPosition(
            task,
            task[baseline_start],
            task[baseline_end],
          );
          const el = document.createElement('div');
          el.className = task.type === 'task' ? 'baseline' : '';
          el.style.left = `${sizes.left}px`;
          el.style.width = `${sizes.width}px`;
          el.style.top = `${sizes.top + gantt.config.bar_height}px`;
          return el;
        }
        return false;
      },
      getRectangle: (task, view) => {
        if (task[baseline_start] && task[baseline_end])
          return gantt.getTaskPosition(
            task,
            task[baseline_start],
            task[baseline_end],
          );

        return null;
      },
    },
  });
}

function setupGanttEvents() {
  state.attached_events.push(
    gantt.attachEvent('onTaskClick', async (id, e) => {
      if (!e.target.className.includes('gantt_tree_icon'))
        return false;
      const task = gantt.getTask(id);
      if (!task?.$has_child)
        return true;
      const children = gantt.getChildren(id);
      if (children.length || task?.is_loading_children)
        return true;
      task.is_loading_children = true;
      await set_children_tasks(id);
      task.is_loading_children = false;
      gantt.clearAll();
      state.set_wbs_to_max = true;
      gantt.parse(active_schedule_data.value);
      gantt.eachParent((t) => {
        t.$open = true;
        gantt.open(t.id);
      }, id);
      gantt.open(id);
      gantt.eachTask((t) => {
        t.$open = true;
        gantt.open(t.id);
      }, id);
      gantt.showTask(id);
      return true;
    }),
    gantt.attachEvent('onBeforeParse', () => {
      gantt.clearAll();
    }),
    gantt.attachEvent('onParse', () => {
      addMarkers();
      addDateRangeBuffer();
      if (state.is_recalculation_enabled) {
        let max_level = 0;
        gantt.eachTask((task) => {
          const task_level = gantt.calculateTaskLevel(task);
          if (!state.set_wbs_to_max)
            task.$open = task_level < 1;
          if (task_level > max_level)
            max_level = task_level;
        });

        if (state.set_wbs_to_max || wbs_level.value === Number.MAX_SAFE_INTEGER) {
          modify_config({ key: 'wbs_level', value: max_level });
          state.set_wbs_to_max = false;
        }

        modify_config({ key: 'wbs_level_max', value: max_level });
      }
      filterTasks();
      if (state.expand_all_after_parse) {
        state.expand_all_after_parse = false;
        set_all_tasks_open_state(true);
      }
    }),
    gantt.attachEvent('onGanttReady', () => {
      const grid = gantt.$ui.getView('grid');
      const tooltips = gantt.ext.tooltips;
      tooltips.tooltipFor({
        selector: '.gantt_grid_head_cell',
        html: (_event, node) => node.innerText,
      });
      grid.attachEvent(
        'onBeforeColumnDragStart',
        column =>
          !['wbs', 'select-columns'].includes(column.draggedColumn.name),
      );
      setupTaskLayers();
    }),
    gantt.attachEvent('onGanttRender', () => {
      if (state.is_initializing) {
        state.is_initializing = false;
        gantt.showDate(new Date());
      }
      const el = document.querySelector('#select-columns-button');
      el && el.addEventListener('click', function () {
      // eslint-disable-next-line @typescript-eslint/no-invalid-this
        gantt.$showDropdown(this);
      });
    }),
    gantt.attachEvent('onTaskSelected', (id) => {
      const task = gantt.getTask(id);
      set_active_task_uid(task.uid);
    }),
    gantt.attachEvent('onAfterSort', (field, direction) => {
      if (state.gantt_sort_field !== field) {
        state.gantt_sort_field = field;
        return;
      }
      if (direction === false) {
        state.gantt_sort_field = '';
        gantt._sort.name = null;
        reloadData(true);
      }
    }),
    gantt.attachEvent('onColumnResizeEnd', () => {
      set_view_dirtiness(true);
      return true;
    }),
    gantt.attachEvent('onGridResizeEnd', () => {
      set_view_dirtiness(true);
      return true;
    }),
    gantt.attachEvent('onBeforeTaskDisplay', (id) => {
      if (state.groups_cache?.length)
        return true;

      return state.filtered_task_ids.length === 0 || state.filtered_task_ids.includes(id);
    }),
  );
}

function setupZoomLevels() {
  const zoom_levels = {
    levels: [
      [{ unit: 'year', step: 1, format: '%Y' }],
      [
        { unit: 'year', step: 1, format: '%Y' },
        {
          unit: 'quarter',
          step: 1,
          format: (date) => {
            const dts = gantt.date.date_to_str('%M');
            const end = gantt.date.add(
              gantt.date.add(date, 3, 'month'),
              -1,
              'day',
            );
            return `${dts(date)} - ${dts(end)}`;
          },
        },
      ],
      [
        { unit: 'year', step: 1, format: '%Y' },
        { unit: 'month', step: 1, format: '%F' },
      ],
      [
        { unit: 'month', step: 1, format: '%F %Y' },
        {
          unit: 'week',
          step: 1,
          format: date => `W${gantt.date.date_to_str('%W')(date)}`,
        },
      ],
      [
        { unit: 'month', step: 1, format: '%F %Y' },
        { unit: 'day', step: 1, format: '%d' },
      ],
    ],
    useKey: 'ctrlKey',
    trigger: 'wheel',
    handler: (event) => {
      event.preventDefault();
      let level = zoom_level.value;
      if (event.deltaY < 0 && zoom_level.value < 4)
        level += 1;
      else if (event.deltaY > 0 && zoom_level.value > 0)
        level -= 1;
      modify_config({ key: 'zoom_level', value: level });
      set_view_dirtiness(true);
    },
    element: () => gantt.$root.querySelector('.gantt_task'),
  };

  gantt.ext.zoom.init(zoom_levels);
  gantt.ext.zoom.setLevel(zoom_level.value ?? 4);
}

function generateWBSTemplate(task) {
  if (task.type === gantt.config.types.placeholder)
    return '<div></div>';

  if (task.associated_task)
    return `<div class="is-a-task">${gantt.getWBSCode(gantt.getTask(task.id))}</div>`;

  return `<div>${gantt.getWBSCode(gantt.getTask(task.id))}</div>`;
}

function generateNameTemplate(task) {
  if (
    [gantt.config.types.placeholder, 'virtual'].includes(task.type)
  )
    return task.name;

  return `
    <div class='pm-task-details'>
      <div class="flex items-center">
        <span>
          ${task.name}
        </span>
        ${
          (active_view.value.data.feature_visibility.overdue
          && task.progress < task.percent_schedule_complete)
            ? '<i class="inline-block w-5 h-5 ml-2 pm-overdue-icon"></i>'
            : ''
        }
        ${
          task.progress === 1
            ? '<i class="inline-block w-5 h-5 ml-2 pm-completed-icon"></i>'
            : ''
        }
      </div>
    </div>`;
}

function generateStatusTemplate(task) {
  if (task.progress === 1)
    return 'Completed';

  else if (!task.progress)
    return 'Not started';

  else
    return 'Started';
}

function generateAssigneeEditorTemplate(task) {
  if (task.type === gantt.config.types.placeholder)
    return '';

  let text = '';
  if (task.assignees?.length)
    text = $t('Assignee');

  else
    text = $t('Assignees');

  return `${task.assignees?.length || 0} ${text}`;
}

function generateAbsoluteWorkRateTemplate(task, name) {
  const num = Number(task?.[name]);
  if (isNil(task?.[name]) || Number.isNaN(num))
    return '';
  const fixed = Math.abs(num).toFixed(2);
  if (num >= 0)
    return `${fixed}`;
  return `<span class="text-red-600">${fixed}</span>`;
}

function generateDefaultTemplate(task, name, type) {
  if (task.type === gantt.config.types.placeholder)
    return '';

  if (type === 'date' && task?.[name])
    return $date(task[name], 'DATE_MED');

  return task?.[name] || '';
}

function generateCustomFieldTemplate(task, name) {
  const custom_fields = active_schedule.value.custom_fields || {};
  const code_name = name.replace('custom_field_', '');
  if (custom_fields[code_name]?.type === 'money')
    return task.custom_field_values?.[code_name]
      ? `${custom_fields[code_name]?.config.currency.symbol}${task.custom_field_values[code_name]}`
      : '';
  else if (custom_fields[code_name]?.type === 'date')
    return task.custom_field_values?.[code_name]
      ? $date(task.custom_field_values[code_name], 'DATE_MED')
      : '';
  else
    return task.custom_field_values?.[code_name] ?? '';
}

function templateForFixedFields(name, type) {
  switch (name) {
    case 'wbs':
      return task => generateWBSTemplate(task);

    case 'name':
      return task => generateNameTemplate(task);

    case 'status':
      return task => generateStatusTemplate(task);

    case 'work_rate':
      return (task) => {
        return (task?.work_rate && !Number.isNaN(task.work_rate))
          ? `${task.work_rate}/day`
          : '';
      };

    case 'total_duration':
      return (task) => {
        if (task.type === gantt.config.types.placeholder)
          return '';

        return `${Math.ceil(task?.[name] || 0)}`;
      };

    case 'assignee_editor':
      return task => generateAssigneeEditorTemplate(task);

    case 'is_critical':
    case 'is_milestone':
      return (task) => {
        return task[name] ? 'Yes' : 'No';
      };

    case 'percent_work_complete':
    case 'percent_schedule_complete':
    case 'progress':
      return task => `${Math.round(task[name] * 100)}%`;

    case 'overall_weight':
      return task => (isNil(task[name]) ? '—' : `${task[name] * 100}%`);

    case 'predecessors':
    case 'successors':
      return task =>
        task[name].map(id => gantt.getTask(id).name).join(', ');

    case 'absolute_remaining_duration':
    case 'absolute_work_rate':
      return task => generateAbsoluteWorkRateTemplate(task, name);

    default:
      return task => generateDefaultTemplate(task, name, type);
  }
}

function getCustomTemplate({ name, type }) {
  if (name.startsWith('activity_code_'))
    return (task) => {
      const code_name = name.replace('activity_code_', '');
      const found = task.activity_code_values.find(
        code => code.name === code_name,
      );
      return found?.value?.description ?? '';
    };

  if (name.startsWith('custom_field_'))
    return task => generateCustomFieldTemplate(task, name);

  return templateForFixedFields(name, type);
}

function groupTasks(groups, tasks, parent = null) {
  const group = groups.shift();

  // redo the case when grouping is wbs
  if (group.option.key === 'wbs')
    return tasks;

  let result = [];

  try {
    result = getGroupingFunction(group.option.key)(
      tasks,
      parent,
      group?.suboption?.key,
      $t,
    );
  }
  catch (error) {
    $toast({
      title: 'Grouping failed',
      text: 'One or more tasks don\'t support the property used for grouping',
      type: 'error',
      timeout: 4000,
    });
    modify_config({ key: 'group_by', value: [] });
    return null;
  }

  if (groups.length) {
    const filtered_result = [];
    for (const { filtered_tasks, wrapper_task_id } of result) {
      const filtered = groupTasks(
        cloneDeep(groups),
        filtered_tasks,
        wrapper_task_id,
      );
      filtered_result.push(...filtered_tasks, ...filtered);
    }
    return filtered_result;
  }
  else {
    return result.map(obj => obj.filtered_tasks).flat();
  }
}

function groupBy(groups) {
  const gantt_container = document.getElementById('gantt_container');

  groups = groups.map((g) => {
    return {
      option: {
        key: g.option.uid,
        label: g.option.label,
      },
      ...(g.suboption
        ? {
            suboption: {
              key: g.suboption.uid,
              label: g.suboption.label,
            },
          }
        : {
            suboption: null,
          }),
    };
  });

  if (!groups?.length) {
    if (state.groups_cache.length)
      reloadData(true);

    return;
  }

  if (
    isEqual(groups, state.groups_cache)
      || groups.some(group => !group?.option?.key)
  )
    return;

  state.is_recalculation_enabled = false;
  state.groups_cache = cloneDeep(groups);
  const copied_data = cloneDeep(active_schedule_data.value);

  const grouped_data = groupTasks(cloneDeep(groups), copied_data.data);

  if (!grouped_data)
    return;

  const new_data = {
    data: grouped_data,
    links: copied_data.links,
  };

  gantt.init(gantt_container);
  gantt.parse(new_data);
}

function reloadData(recalculate) {
  const gantt_container = document.getElementById('gantt_container');
  state.is_recalculation_enabled = recalculate;
  state.groups_cache = [];
  gantt.clearAll();

  gantt.init(gantt_container);
  gantt.parse(active_schedule_data.value);
}

function sortDateColumns(column) {
  return (a, b) => {
    const date_a = a.custom_field_values?.[column?.label];
    const date_b = b.custom_field_values?.[column?.label];
    return dayjs(date_b).isAfter(dayjs(date_a)) ? -1 : 1;
  };
}

function sortOtherColumns(column) {
  return (a, b) => {
    return a.custom_field_values?.[column?.label] < b.custom_field_values?.[column?.label] ? -1 : 1;
  };
}

function customizeColumnSorting() {
  for (const [index, column] of gantt.config.columns.entries()) {
    if (column.group !== 'custom_fields')
      continue;

    if (column.name === 'select-columns' || column.name === 'wbs')
      gantt.config.columns[index].sort = false;

    if (column.custom_type === 'date')
      gantt.config.columns[index].sort = sortDateColumns(column);

    if (['number', 'money', 'text'].includes(column.custom_type))
      gantt.config.columns[index].sort = sortOtherColumns(column);
  }
}

function setupColumns(columns_arr = null, override_size = false) {
  gantt.config.columns = [
    ...(columns_arr || columns.value).map((column) => {
      const new_col = cloneDeep(column);
      const template = getCustomTemplate(column);
      if (template)
        new_col.template = template;
      if (override_size)
        new_col.width = column.min_width || 100;
      return new_col;
    }),
    {
      name: 'select-columns',
      label: '<div id="select-columns-button" class="gantt_add"></div>',
      align: 'center',
      resize: false,
      sort: false,
      max_width: 40,
      min_width: 40,
    },
  ];
  let grid_width_temp = 0;
  if (active_view.value?.data?.grid_width)
    grid_width_temp = active_view.value.data.grid_width;
  else
    grid_width_temp = gantt.config.columns.reduce((acc, curr) => {
      acc += curr.width;
      return acc;
    }, 0);
  gantt.config.grid_width = grid_width_temp;

  customizeColumnSorting();
}

function setupGanttFunctions() {
  gantt.$showDropdown = () => {
    customize_columns_modal.open();
  };
}

function setLayout(is_initialization = false) {
  const final_layout = layout.value || 'grid-timeline';
  const gantt = window.gantt;

  if (final_layout === 'timeline') {
    gantt.config.show_grid = false;
    gantt.config.show_chart = true;
  }
  else if (final_layout === 'grid') {
    gantt.config.show_grid = true;
    gantt.config.show_chart = false;
  }
  else {
    gantt.config.show_grid = true;
    gantt.config.show_chart = true;

    if (is_initialization)
      return;
    setupColumns(gantt.config.columns.filter(column => column.name !== 'select-columns'), true);
  }

  if (is_initialization)
    return;

  const gantt_container = document.getElementById('gantt_container');
  if (gantt_container)
    gantt.init(gantt_container);
}

function createFilterMaps(filters_array) {
  const activity_code_filters = Object.keys(filters_array).filter(
    name =>
      name.startsWith('__activity_code_') && Object.values(filters_array[name]).length,
  );
  const activity_code_map = activity_code_filters.reduce((acc, cur) => {
    acc[cur] = cur.replace('__activity_code_', '');
    return acc;
  }, {});

  return { activity_code_filters, activity_code_map };
}

function checkCriticalAndMilestone(filters_array, task) {
  return (
    (filters_array.critical && !task.is_critical)
    || (filters_array.milestone && task.type !== 'milestone')
  );
}

function checkTaskLevel(task_level) {
  return task_level > wbs_level.value;
}

function checkStatuses(filters_array, task) {
  if (filters_array.statuses?.length) {
    let allow = false;

    // Rule #1: If task has not started
    if (
      filters_array.statuses.includes('not-started')
            && task.progress === 0
    )
      allow = true;

    // Rule #2: If task has started
    if (
      filters_array.statuses.includes('started')
            && task.progress !== 0
            && task.progress !== 1
    )
      allow = true;

    // Rule #3: If task has finished
    if (filters_array.statuses.includes('finished') && task.progress === 1)
      allow = true;

    return !allow;
  }
  return false;
}

function checkProgress(filters_array, task) {
  return (
    filters_array.progress?.[0]
    && filters_array.progress?.[1]
    && !(
      filters_array.progress[0] / 100 <= task.progress
      && filters_array.progress[1] / 100 >= task.progress
    )
  );
}

function checkDateRange(filters_array, task) {
  return (
    filters_array.date_range?.[0]
    && filters_array.date_range?.[1]
    && !(
      new Date(filters_array.date_range[0]) <= task.start_date
      && new Date(filters_array.date_range[1]) >= task.end_date
    )
  );
}

function checkActiveDateRange(task) {
  return (
    active_date_range.value?.from
    && active_date_range.value?.to
    && (task.end_date
      <= new Date(active_date_range.value.from)
      || task.start_date
        >= new Date(active_date_range.value.to))
  );
}

function checkActivityCodeFilters(filters_array, task, activity_code_filters, activity_code_map) {
  if (task.type !== 'task' && activity_code_filters.length)
    return true;
  const activity_code_values = task.activity_code_values_map || {};
  for (const filter of activity_code_filters) {
    const value = activity_code_values[activity_code_map[filter]];
    if (!filters_array[filter].includes(value))
      return true;
  }
  return false;
}

function checkAttributes(filters_array, task, activity_code_filters, activity_code_map) {
  return (
    checkCriticalAndMilestone(filters_array, task)
    || checkTaskLevel(gantt.calculateTaskLevel(task))
    || checkStatuses(filters_array, task)
    || checkProgress(filters_array, task)
    || checkDateRange(filters_array, task)
    || checkActiveDateRange(task)
    || checkActivityCodeFilters(filters_array, task, activity_code_filters, activity_code_map)
  );
}

function filterTasks(filters_arr) {
  const filters_array = filters_arr || filters.value;
  if (!filters_array)
    return;
  state.filtered_task_ids = [];

  const { activity_code_filters, activity_code_map } = createFilterMaps(filters_array);

  gantt.eachTask((task) => {
    if (checkAttributes(filters_array, task, activity_code_filters, activity_code_map))
      return;
    state.filtered_task_ids.push(task.id);
    gantt.eachParent(
      parent => state.filtered_task_ids.push(parent.id),
      task.id,
    );
  });
}

function initializeGantt() {
  try {
    const gantt_container = document.getElementById('gantt_container');
    setLayout(true);
    if (active_view.value.data.group_by.length) {
      groupBy(active_view.value.data.group_by);
    }
    else {
      gantt.init(gantt_container);
      gantt.parse(active_schedule_data.value);
    };
  }
  catch (error) {
    logger.error('error initializing gantt', error);
  }
}

function setupGantt() {
  initializePlugins();
  setupGanttConfig();
  setupTemplates();
  setupGanttEvents();
  setupZoomLevels();
  setupGanttFunctions();
  setupColumns();
  initializeGantt();
}
</script>

<template>
  <div
    id="gantt_container"
    :class="[
      active_task ? '[&>:first-child]:rounded-t-xl' : '[&>:first-child]:rounded-xl',
      {
        'h-[calc(100vh-210px)]': !is_fullscreen && !active_task,
        'h-[calc(100vh-210px-40vh)]': !is_fullscreen && active_task,
        'h-[calc(100vh-80px)]': is_fullscreen && !active_task,
        'h-[calc(100vh-80px-40vh)]': is_fullscreen && active_task,
      },
    ]"
  />
</template>

<style lang="scss">
  @import "../styles/pm-gantt-skin.scss";
  .is-display-none {
    display: none;
  }
</style>
