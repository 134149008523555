<script setup>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { flatten, groupBy, isEqual } from 'lodash-es';
import { watch } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useGalleryView } from '~/terra/utils/gallery-view.composable.js';
import AttachmentDetails from '~/terra/molecules/gallery/attachment-details.vue';
import GalleryDateFilter from '~/terra/molecules/gallery/gallery-filters/gallery-date-filter.vue';
import GalleryTypeFilter from '~/terra/molecules/gallery/gallery-filters/gallery-type-filter.vue';
import { sleep } from '~/common/utils/common.utils';

const props = defineProps({
  store: {
    type: Object,
  },
  is_therm_viewer: {
    type: Boolean,
    default: false,
  },
});

dayjs.extend(isBetween);
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
const { updateAttachmentPoint, sortAttachments } = useGalleryView();

const state = reactive({
  is_loading: false,
  feature_attachments_map: {},
  all_attachments: [],
  filter: {},
});

const attachment_options = computed(() => {
  const { created_at, type } = state.filter;
  if (!created_at && !type)
    return {};
  const grouped_data = groupBy(state.all_attachments, attachment => attachment?.feature_uid);
  const feature_attachments_map = Object.entries(grouped_data).reduce((acc, [feature_uid, all_feature_attachments]) => {
    const project_uid = all_feature_attachments[0]?.project_uid;
    acc[feature_uid] = {
      feature_uid,
      project_uid,
      all_feature_attachments,
      attachments_count: all_feature_attachments.length,
    };
    return acc;
  }, {});
  return { attachments_list: Object.values(feature_attachments_map) };
});
const gallery_tools = computed(() => {
  return [
    {
      tooltip: $t('Gallery View'),
      icon: IconHawkGridOne,
      event: 'gallery-view',
      action: async () => {
        if (!props.store.gallery_view_state?.is_active) {
          state.is_loading = true;
          props.store.set_gallery_state('is_active', true);
          await updateAttachmentPoint(props.store, attachment_options.value);
          getData();
          state.is_loading = false;
        }
        else {
          await closeSidebar();
        }
      },
    },
  ];
});
const available_attachments_map = computed(() => {
  return state.all_attachments.reduce((acc, curr) => {
    acc[curr.uid] = true;
    return acc;
  }, {});
});
const is_cluster_selected = computed(() => !!props.store.gallery_view_state.filtered_attachment_features?.length);
const filtered_attachments = computed(() => {
  const { filtered_attachment_features } = props.store.gallery_view_state;
  return state.all_attachments.filter(attachment => !filtered_attachment_features.length || filtered_attachment_features.includes(attachment.feature_uid));
});

function filterAttachments(all_attachments) {
  let filtered_attachments = all_attachments;

  const { created_at, type } = state.filter;

  if (type?.value && type?.value !== 'all')
    filtered_attachments = all_attachments.filter(attachment =>
      (attachment.is_raw_image && type?.value === 'raw_images')
    || (!attachment.is_raw_image && type?.value !== 'raw_images'),
    );

  if (created_at && created_at?.option_value !== 'all_time') {
    const start_date = created_at.value[0];
    const end_date = created_at.value[1];

    return filtered_attachments.filter((attachment) => {
      if (!attachment?.created_at)
        return false;
      const created_at = dayjs(attachment?.created_at);
      if (start_date && end_date)
        return created_at.isBetween(start_date, end_date, null, '[]');
      else if (start_date)
        return created_at.isAfter(start_date);
      else if (end_date)
        return created_at.isBefore(end_date);
      else
        return true;
    });
  }
  return filtered_attachments;
}
function getData() {
  try {
    state.feature_attachments_map = props.store.active_attachments_list().reduce((acc, feature) => {
      const all_feature_attachments = feature.all_feature_attachments;
      acc[feature.feature_uid] = formatAttachments(all_feature_attachments, feature.feature_uid);
      return acc;
    }, {});
    const all_attachments = flatten(Object.values(state.feature_attachments_map));
    state.all_attachments = filterAttachments(all_attachments);
  }
  catch (error) {
    console.error(error);
  }
}

function formatAttachments(attachments, feature_uid) {
  return attachments.map((attachment) => {
    if (!attachment?.owner?.uid)
      attachment.owner = { uid: attachment.created_by };
    return attachment;
  });
}

function attachmentDownloadHandler(file) {
  window.open(file.url, '_blank');
}

async function closeSidebar() {
  state.filter = {};
  props.store.set_gallery_state('is_active', false);
  props.store.set_gallery_state('filtered_attachment_features', []);
  await updateAttachmentPoint(props.store, attachment_options.value);
}

async function updateFilter(filter) {
  state.is_loading = true;
  state.filter[filter.uid] = filter;
  getData();
  await updateAttachmentPoint(props.store, attachment_options.value);
  await sleep(200);
  state.is_loading = false;
}

function handleRemoveActiveCluster() {
  props.store.set_gallery_state('filtered_attachment_features', []);
  getData();
  updateAttachmentPoint(props.store, attachment_options.value);
}

watchEffect(() => {
  const { is_active } = props.store.gallery_view_state;
  if (is_active) {
    const app = document.getElementById('app');
    setTimeout(() => {
      app.removeAttribute('inert');
    }, 100);
  }
});

watch(() => props.store.active_attachments_list(), () => {
  if (props.store.gallery_view_state.is_active)
    getData();
}, { deep: true });

watch(() => Object.keys(props.store.filtered_features_hash), async (val, old_val) => {
  const { is_active, active_attachment } = props.store.gallery_view_state;
  if (is_active) {
    if (isEqual(val, old_val))
      return;
    state.is_loading = !active_attachment;
    getData();
    await updateAttachmentPoint(props.store, attachment_options.value);
    state.is_loading = false;
  }
}, { deep: true });

watch(() => props.store.gallery_view_state.active_attachment, (val) => {
  if (val)
    props.store.set_selected_features([props.store.features_hash[val.feature_uid]]);
});
</script>

<template>
  <TransitionRoot as="template" :show="store.gallery_view_state.is_active">
    <Dialog as="div" class="relative z-[999]">
      <div class="fixed inset-y-0 right-0 top-16 flex">
        <TransitionChild as="template" enter="transform transition ease-in-out duration-300 sm:duration-500" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
          <DialogPanel class="pointer-events-auto w-[400px]">
            <div v-if="state.is_loading" class="relative h-full flex flex-col bg-white shadow-xl flex-1 px-4 sm:px-6 pb-6">
              <hawk-loader />
            </div>
            <div v-else class="relative h-full flex flex-col bg-white shadow-xl flex-1 pt-6">
              <AttachmentDetails
                v-if="store.gallery_view_state.active_attachment"
                :store="store"
                :available_attachments_map="available_attachments_map"
                @activeAttachment="store.set_gallery_state('active_attachment', $event)"
                @close="store.set_gallery_state('active_attachment', null)"
                @closeSlider="store.set_gallery_state('active_attachment', null); closeSidebar()"
              >
                <template #feature_details>
                  <slot name="feature_details" />
                </template>
              </AttachmentDetails>
              <template v-else>
                <div class="w-full px-4 sm:px-6">
                  <div v-if="is_cluster_selected" class="flex items-center gap-2">
                    <div class="w-5 h-5 cursor-pointer" @click="handleRemoveActiveCluster">
                      <IconHawkArrowLeft class="w-5 h-5" />
                    </div>
                    <div class="text-lg font-semibold text-gray-900">
                      {{ $t('Back') }} ({{ filtered_attachments.length }})
                    </div>
                  </div>
                  <div v-else class="flex items-center justify-between min-w-[330px] max-w-[360px] bg-white">
                    <div class="text-lg font-semibold text-gray-900 flex items-center">
                      {{ $t('Gallery') }} ({{ filtered_attachments.length }})
                      <HawkFeaturedIcon
                        v-if="store.gallery_view_state.show_load_progress_attachments"
                        v-tippy="{ content: $t('Refresh to see new attachments'), placement: 'top' }"
                        theme="light-circle-outline" color="error" class="ml-2"
                      >
                        <IconHawkAlertTriangle />
                      </HawkFeaturedIcon>
                    </div>
                    <div>
                      <IconHawkX
                        class="w-6 h-6 cursor-pointer text-gray-600 hover:text-gray-900  outline-none"
                        tabindex="0"
                        @click="closeSidebar"
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <div v-if="!is_cluster_selected" class="flex items-center justify-end pr-5 gap-x-2">
                    <GalleryTypeFilter v-if="is_therm_viewer" :default_value="state.filter.type" @applyFilters="updateFilter($event)" />
                    <GalleryDateFilter :default_value="state.filter.created_at" @applyFilters="updateFilter($event)" />
                  </div>
                  <hawk-attachments-grid
                    v-if="filtered_attachments?.length"
                    :key="filtered_attachments?.join(',')"
                    class="m-4"
                    use_virtualization
                    :items="sortAttachments(filtered_attachments)"
                    :image_dimensions="[160, 160]"
                    :show_delete="false"
                    :scroller_classes="['h-[calc(100vh-166px)]']"
                    show_attachments_list_preview
                    @attachmentClicked="store.set_gallery_state('active_attachment', $event)"
                  />
                </div>
              </template>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <div>
    <div class="relative flex flex-col mr-2.5">
      <MapToolIcons
        style="position: relative"
        :tools="gallery_tools"
        :active_tool="store.gallery_view_state.is_active ? 'gallery-view' : ''"
        :horizontal_icons="true"
      />
    </div>
  </div>
</template>
