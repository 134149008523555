import { sortBy, uniq, uniqBy } from 'lodash-es';

export function useGalleryView() {
  const fetchClusterLeaves = async (store, cluster) => {
    const cluster_id = cluster.properties.cluster_id;

    return new Promise((resolve, reject) => {
      store.map.getSource('feature_attachment_points').getClusterLeaves(cluster_id, 1000, 0, (err, leaves) => {
        if (err)
          return reject(new Error('Error fetching cluster leaves:', err));

        resolve(leaves);
      });
    });
  };

  const handleLayerClickEvents = async (e, store, layer_id, click_event_layers) => {
    const features = store.map.queryRenderedFeatures(e.point, {
      layers: click_event_layers,
    });
    if (features.length > 0) {
      const feature = features[0];
      if (feature?.properties?.totalAttachmentsCount !== undefined) {
        try {
          const leaves = await fetchClusterLeaves(store, feature);
          store.gallery_view_state.filtered_attachment_features = uniq(leaves.map(f => f.feature_uid));
        }
        catch (error) {
          store.gallery_view_state.filtered_attachment_features = [];
          logger.error(error);
        }
      }
      else if (feature.layer.id === layer_id) {
        store.gallery_view_state.filtered_attachment_features = [feature.properties.feature_uid];
        logger.info('Feature :', feature.properties.feature_uid);
      }
      else {
        store.gallery_view_state.filtered_attachment_features = [];
      }
    }
  };
  const handleClickEvents = (store) => {
    const click_event_layers = ['attachment_clusters_point', 'attachment_unclusters_point'];
    click_event_layers.forEach((layer_id) => {
      store.map.on('click', layer_id, (e) => {
        handleLayerClickEvents(e, store, layer_id, click_event_layers);
      });
    });
    store.map.on('click', (e) => {
      const features = store.map.queryRenderedFeatures(e.point, {
        layers: click_event_layers,
      });
      if (features.length === 0)
        store.gallery_view_state.filtered_attachment_features = [];
    });
  };
  const handleStylingPoints = ({ map }) => {
    map.on('click', 'attachment_clusters_point', (e) => {
      const clusterId = e?.features?.[0]?.properties?.cluster_id;

      map.setPaintProperty('attachment_clusters_point', 'circle-color', '#344054');
      map.setPaintProperty('attachment_clusters_point', 'circle-radius', 20);

      map.setPaintProperty('attachment_clusters_point', 'circle-color', ['case',
        ['==', ['get', 'cluster_id'], clusterId], '#1570EF',
        '#344054',
      ]);
    });

    map.on('click', 'attachment_unclusters_point', (e) => {
      const point_id = e.features?.[0]?.properties?.feature_uid;
      map.setPaintProperty('attachment_unclusters_point', 'circle-color', ['case',
        ['==', ['get', 'feature_uid'], point_id], '#1570EF',
        '#344054',
      ]);
    });

    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point, { layers: ['attachment_clusters_point'] });
      if (features.length === 0)
        map.setPaintProperty('attachment_clusters_point', 'circle-color', '#344054');

      const unclustered_features = map.queryRenderedFeatures(e.point, { layers: ['attachment_unclusters_point'] });
      if (unclustered_features.length === 0)
        map.setPaintProperty('attachment_unclusters_point', 'circle-color', '#344054');
    });
  };
  const updateAttachmentPoint = async (store, options = {}) => {
    const feature_attachments = options.attachments_list || store.active_attachments_list();
    const show_attachments = store.gallery_view_state.is_active;
    const cluster_source = 'feature_attachment_points';
    const cluster_layers = ['attachment_clusters_point', 'attachment_clusters_count', 'attachment_unclusters_point', 'attachment_unclusters_count'];

    if (!store.map)
      return;

    // Remove source and layers
    cluster_layers.forEach((layer) => {
      if (store.map?.getLayer(layer))
        store.map.removeLayer(layer);
    });
    if (store.map?.getSource(cluster_source))
      store.map.removeSource(cluster_source);

    if (!show_attachments)
      return;

    const turf = (await import('@turf/turf'));

    const fc_attachment_points = {
      type: 'FeatureCollection',
      features: feature_attachments.reduce((acc, f) => {
        const { feature_uid } = f;
        const feature = store.features_hash?.[feature_uid];
        if (!feature || !f.attachments_count)
          return acc;
        const centroid = turf.centroid(feature);

        acc.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: centroid?.geometry?.coordinates,
          },
          feature_uid,
          properties: f,
        });
        return acc;
      }, []),
    };

    store.map.addSource(cluster_source, {
      type: 'geojson',
      data: fc_attachment_points,
      cluster: true,
      clusterMaxZoom: 22,
      clusterRadius: 30,
      clusterProperties: {
        totalAttachmentsCount: ['+', ['get', 'attachments_count']],
      },
    });

    // For displaying clustered data
    store.map.addLayer({
      id: 'attachment_clusters_point',
      type: 'circle',
      source: 'feature_attachment_points',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#344054',
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20, // Base radius for small clusters
          100, // Radius for medium clusters
          30, // Radius for large clusters
          750, // Radius for very large clusters
          40, // Maximum radius for the largest clusters
        ],
      },
    });
    store.map.addLayer({
      id: 'attachment_clusters_count',
      type: 'symbol',
      source: 'feature_attachment_points',
      layout: {
        'text-field': '{totalAttachmentsCount}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], // Use bold fonts
        'text-size': 12,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });

    // For displaying unclustered data
    store.map.addLayer({
      id: 'attachment_unclusters_point',
      type: 'circle',
      source: 'feature_attachment_points',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#344054',
        'circle-radius': 15,
      },
    });
    store.map.addLayer({
      id: 'attachment_unclusters_count',
      type: 'symbol',
      source: 'feature_attachment_points',
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': ['get', 'attachments_count'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#ffffff',
      },
    });

    handleClickEvents(store);
    handleStylingPoints(store);
  };

  const getAllFeatures = async (store) => {
    const all_features = store.map.querySourceFeatures('feature_attachment_points');

    const clusters = all_features.filter(feature => feature.properties.point_count !== undefined);

    const expanded_features = [];

    const promises = clusters.map(async (cluster) => {
      try {
        const leaves = await fetchClusterLeaves(store, cluster);
        expanded_features.push(...leaves);
      }
      catch (error) {
        logger.error(error);
      }
    });
    await Promise.all(promises);

    const non_clustered_points = all_features.filter(feature => feature.properties.point_count === undefined);
    expanded_features.push(...non_clustered_points);
    return uniqBy(expanded_features, f => f.properties.feature_uid);
  };
  const getNearByFeatures = async (store, feature_uid) => {
    const turf = (await import('@turf/turf'));
    let all_features = await getAllFeatures(store);
    if (!all_features.length) {
      await store.map.once('idle');
      all_features = await getAllFeatures(store);
    }
    const target_feature = all_features.find(f => f.properties.feature_uid === feature_uid);

    if (target_feature) {
      const coordinates = target_feature.geometry.coordinates;

      const features_with_distances = all_features
        .filter(feature => feature.properties.feature_uid !== feature_uid)
        .map((feature) => {
          const distance = turf.distance(
            turf.point(coordinates),
            turf.point(feature.geometry.coordinates),
            { units: 'meters' },
          );
          return { feature, distance };
        })
        .sort((a, b) => a.distance - b.distance);

      return features_with_distances;
    }
    else {
      logger.info('Target feature not found');
      return [];
    }
  };

  function sortAttachments(attachments) {
    return sortBy(attachments || [], (att) => {
      const date = att.created_at ? new Date(att.created_at).getTime() : Number.NEGATIVE_INFINITY;
      return -date;
    });
  }

  return {
    updateAttachmentPoint,
    getNearByFeatures,
    sortAttachments,
  };
}
