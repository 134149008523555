<script setup>
import { cloneDeep, isEqual } from 'lodash-es';

import { storeToRefs } from 'pinia';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';
import DocumentTable from '~/dms/components/documents/table/document-table.vue';
import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
  query: {
    type: Object,
  },
});

const dms_settings_store = useDMSSettingsStore();

const document_table = ref(null);
const forceUpdate = ref(0);
const prevent_watcher = ref(false);
const document_global_actions = useDocumentGlobalActions(props.id);
const document_store = useDocumentStore(props.id);
const dashboard_store = useDashboardStore();
const dashboard_dms_store = useDashboardDMSStore();
const { dms_configuration } = storeToRefs(dashboard_dms_store);
const state = reactive({ is_table_loading: false });

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  const print_dataset = document_store.documents.map((f) => {
    return {
      ...f,
      status: f.status?.name,
    };
  });
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: document_table?.value?.columns,
      activities: print_dataset,
      dashboard_index: props.data.i,
      dataset: print_dataset,

    },
  });
}

function set_custom_fields() {
  dms_settings_store.set_custom_fields({
    query: {
      allowed_in: 'documents',
    },
  });
}
async function getData() {
  state.is_table_loading = true;
  await document_global_actions.getDocuments({
    query: {
      asset: dashboard_store.widget_asset,
      files: true,
      children: true,
      folders: props.data.data.folders?.map(e => e.uid),
      scope: props.data.data.scope,
      ...props.query,

    },
    filters: {
      filters: props.data?.data?.filters?.filters,

    },
    skip_root: true,
  });
  state.is_table_loading = false;
}

if (props.id === 'preview') {
  watch(() => props.data, (new_val, old_val) => {
    if (!prevent_watcher.value && new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        getData();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

if (props.id !== 'preview') {
  watch(() => props.data.data, (new_val, old_val) => {
    if (!prevent_watcher.value && new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        set_custom_fields();
        getData();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

// watch(dms_configuration, (nv, ov) => {
//   if (nv !== ov) {
//     forceUpdate.value++;
//   }
// });

async function columnResized(_resized_column, columns_widths) {
  prevent_watcher.value = true;
  if (props.id === 'preview') {
    const cols = dms_configuration.value.columns.map(c => columns_widths[c.id] ? { ...c, ...columns_widths[c.id] } : { ...c });

    dashboard_dms_store.update_dms_configuration({
      ...dms_configuration.value,
      ...{ columns: cols },
    });

    dashboard_store.set_widget_configuration({
      ...dms_configuration.value,
    });
  }
  else {
    const config = cloneDeep(props.data);

    const selected_cols = config.data.columns || dashboard_dms_store.standard_columns;

    const cols = selected_cols.map(c => columns_widths[c.id] ? { ...c, ...columns_widths[c.id] } : { ...c });
    config.data.columns = cols;

    dashboard_store.set_widget_uid(config.uid);
    dashboard_store.set_widget_name(config.data.name);
    dashboard_store.update_widget(config.data, false);
  }
}

provide('display_filters_ref', '');
</script>

<template>
  <div>
    <div class="w-full h-full">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
      <div v-if="state.is_table_loading">
        <hawk-loader />
      </div>
      <div v-else class="container flex 2xl:max-w-[100vw]">
        <div id="table_wrapper" class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
          <DocumentTable
            :key="forceUpdate"
            ref="document_table"
            :documents="document_store.documents"
            :refresh_documents="getData"
            :is_table_loading="state.is_table_loading"
            :is_widget="true"
            :options="{
              dropdown_items_ids: ['archive', 'delete'],
              disable_context_menu: true,
              hide_selection_checkbox: true,
              disable_custom_view_configuration: true,
              disable_header_context_menu_config: true,
              enable_pagination: true,
              widget_columns: props.data.data.columns || dashboard_dms_store.standard_columns,
            }"
            :can-resize="dashboard_store.is_editing_dashboard"
            @table-instance-created="updatePrintMap()"
            @column-resized="columnResized"
          />
        </div>
      </div>
    </div>
  </div>
</template>
