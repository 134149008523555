<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  step_name: {
    type: String,
    default: '',
  },
  step_index: {
    type: Number,
    default: 0,
  },
  step_uid: {
    type: Object,
    default: null,
  },
  is_preview_mode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['copyToClipboard', 'paste']);
const route = useRoute();
const form_detail_store = inject('form_detail_store');
const step = ref(null);
const $t = inject('$t');
const $toast = inject('$toast');

function get_step_visibility() {
  if (props.is_preview_mode && !route.query.submission_history)
    return true;
  const completed_steps = form_detail_store?.form_detail?.completed_steps?.map(
    step => +step.index,
  );
  const allowed_steps = [
    ...(completed_steps || []),
    ...(route.query.submission_history
      ? []
      : [+form_detail_store?.form_detail?.status?.index || 1]),
  ];
  // Irrespective of whether the form is submitted or not, we should only display
  // the steps that are either completed or the ones in the current step. The deleted steps i.e
  // step.active=false is only checked while previewing the form but not in the submission view since
  // we should not consider whether a step is deleted or not for the completed ones and the current step
  // will never be set to a deleted step.
  return allowed_steps.includes(props.step_index);
}

const current_clipboard_obj = ref({});
onMounted(() => {
  if (+form_detail_store?.form_detail?.status?.index === props.step_index)
    step.value?.scrollIntoView(true);
});

const is_clicked = ref(true);
const step_submission = form_detail_store?.form_submissions?.step_submissions?.[props.step_index];

function copyToClipboard(el$) {
  try {
    emit('copyToClipboard');
    el$.target?._tippy?.setContent('copied');
    el$.target?._tippy.show();
    setTimeout(() => {
      el$.target?._tippy?.setContent('copy');
    }, 2000);
  }
  catch (e) {
    logger.log(e);
  }
}

const has_paste_permission = ref(false);
function copyFieldValuesToClipboard(el$) {
  navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
    if (result.state === 'denied' || !has_paste_permission.value) {
      $toast({
        text: `Clipboard access denied, please enable the same in the browser`,
        type: 'error',
      });
    }
    else {
      copyToClipboard(el$);
    }
  });
}

function updatePastePermission(result) {
  if (result.state === 'granted') {
    has_paste_permission.value = true;
    showPasteIcon();
  }
  else if (result.state === 'prompt') {
    navigator.clipboard.readText();
  }
  else {
    has_paste_permission.value = false;
  }
}

onBeforeMount(() => {
  navigator.permissions.query({ name: 'clipboard-read' }).then((result) => {
    updatePastePermission(result);
    result.onchange = () => {
      updatePastePermission(result);
    };
  });
});

function showPasteIcon() {
  if (!has_paste_permission.value || !document.hasFocus())
    return;
  current_clipboard_obj.value = {};
  try {
    navigator.clipboard.readText().then((text) => {
      try {
        const obj = JSON.parse(text);
        current_clipboard_obj.value = obj;
      }
      catch (e) {
        logger.log(e);
      }
    });
  }
  catch (e) {
    logger.log(e);
  }
}
</script>

<template>
  <div
    v-if="get_step_visibility()"
    ref="step"
    class="group/step"
    @mouseenter="showPasteIcon"
  >
    <div
      class="mb-4 bg-[#F2F4F7] p-3 rounded-md cursor-pointer flex justify-between items-center"
      @click="step_submission ? (is_clicked = !is_clicked) : null"
    >
      <div>
        <div>
          <h1 class="text-lg font-semibold">
            {{ props.step_name }}
          </h1>
        </div>
        <div v-if="step_submission" class="flex items-center">
          <div class="content-center text-[#667085] mr-2 text-sm">
            {{ $t("Submitted by") }}:
          </div>
          <div>
            <HawkMembers
              class="bg-gray-200 mr-1 !flex"
              :members="step_submission?.submitted_by"
              type="badge"
            />
          </div>
          <div class="text-sm font-normal">
            on {{ $date(step_submission?.submitted_at, "L_DATETIME_LONG") }}
          </div>
        </div>
      </div>
      <div class="min-h-10">
        <div v-if="step_submission" class="flex">
          <hawk-button
            type="plain"
            class="hidden group-hover/step:block"
          >
            <icon-hawk-copy-two v-tippy="'copy'" class="focus:outline-none" @click.stop="copyFieldValuesToClipboard" />
          </hawk-button>
          <hawk-button type="plain">
            <icon-hawk-minus-circle v-if="is_clicked" />
            <icon-hawk-plus-circle v-else />
          </hawk-button>
        </div>
        <hawk-button
          v-else-if="current_clipboard_obj.step_name === step_name"
          v-tippy="`Paste from ${current_clipboard_obj.form_name}`"
          type="plain"
          class="hidden group-hover/step:block"
          @click.stop="emit('paste')"
        >
          <icon-hawk-sticker-square />
        </hawk-button>
      </div>
    </div>
    <div v-if="is_clicked">
      <slot name="step_sections" :is_step_submitted="step_submission" />
    </div>
  </div>
</template>
