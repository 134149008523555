<script setup>
import { jwtDecode } from 'jwt-decode';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { clearAuthentication } from '~/common/utils/common.utils';
import { usePasswordRules } from '~/profile-settings/pages/password-rules.composable.js';

const {
  $services,
  $t,
  route,
  router,
  $toast,
} = useCommonImports();

const decoded_token = route?.query?.p && jwtDecode(route.query.p);
const {
  oneLowerAndUpper,
  oneNumberAndSymbol,
  shouldNotContainFirstName,
  shouldNotContainLastName,
  shouldNotContainUserName,
} = usePasswordRules(
  decoded_token?.email,
  decoded_token?.firstname,
  decoded_token?.lastname,
);

const form = ref({});
const state = reactive({
  is_loading: false,
  toggle_type: {
    password: false,
    password_confirmation: false,
  },
});

onMounted(() => {
  // This is to ensure whenever a user lands on sign-in, no matters if it's from redirection or direct, auth is always cleared.
  clearAuthentication();
  if (!route?.query?.p) {
    router.push({ name: 'sign-in' });
  }
});
async function handleResetPassword() {
  try {
    state.is_loading = true;
    state.error = null;
    await $services.auth.resetPassword({ data: { password: form.value.password }, query: route.query.p });
    state.is_loading = false;
    $toast({ type: 'success', title: 'Reset password successful!' });
    routeToSignIn();
  }
  catch (error) {
    $toast({
      type: 'error',
      title: 'Reset password failed!',
      text: 'Password should not include parts of your username, first name or last name.',
      timeout: 5000,
      has_close_button: true,
    });
    logger.log(error);
    state.is_loading = false;
  }
}

function routeToSignIn() {
  router.push({ name: 'sign-in' });
}
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-6">
        <HawkIconTaskmapperIcon />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        Reset your password
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-[0_2px_16px_8px_rgba(112,117,126,0.08)] sm:rounded-lg sm:px-10">
        <Vueform
          v-model="form" :display-errors="false"
          size="sm"
          :columns="{
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
          }"
          @submit="handleResetPassword"
        >
          <div class="col-span-12 grid gap-6">
            <TextElement
              name="password"
              label="Password"
              :rules="[
                'required',
                'confirmed',
                'min:12',
                oneLowerAndUpper,
                oneNumberAndSymbol,
                shouldNotContainFirstName,
                shouldNotContainLastName,
                shouldNotContainUserName,
              ]"
              class="mt-5"
              placeholder="Enter password"
              :input-type="state.toggle_type.password ? 'text' : 'password'"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="state.toggle_type.password = !state.toggle_type.password">
                  <IconHawkEye v-if="state.toggle_type.password" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
            <TextElement
              name="password_confirmation"
              label="Confirm Password"
              :rules="['required']"
              placeholder="Re enter password"
              :input-type="state.toggle_type.password_confirmation ? 'text' : 'password'"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="state.toggle_type.password_confirmation = !state.toggle_type.password_confirmation">
                  <IconHawkEye v-if="state.toggle_type.password_confirmation" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
            <ButtonElement
              name="submit" button-label="Reset Password"
              :submits="true" :loading="state.is_loading"
              button-class="w-full bg-blue-600 !py-[9px] !text-sm"
            />
          </div>
        </Vueform>
      </div>
      <div class="py-8 flex gap-1 justify-center text-gray-600 text-sm">
        {{ $t("Already have an account") }}?
        <div class="text-blue-600 hover:text-blue-500 font-semibold cursor-pointer" @click="routeToSignIn">
          {{ $t("Sign in") }}
        </div>
      </div>
    </div>
  </div>
</template>
