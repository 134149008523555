import { groupBy, keyBy, omit, orderBy, remove } from 'lodash-es';
import { defineStore } from 'pinia';
import { useAuthStore } from '~/auth/stores/auth.store';
import { getFlatData } from '~/system-model/utils/helper';

export const useSystemModelStore = defineStore('sm', {
  state: () => ({
    // General
    active_tab: 'SmComponentDetails',
    categories_map: {},
    // template list data
    templates_map: {},
    templates_components_map: {},
    // hierarchy
    active_component_hierarchy: [],
    active_instance_hierarchy: [],
    // active items
    active_component: null,
    active_instance: null,
    // details
    active_component_details: {},
    active_instance_details: {},
    // lists
    component_instances: [],
    // inventory
    inventory_items: [],
    sidebar_force_render: true,
    is_hierarchy_dirty: false,
  }),
  getters: {
    templates: (state) => {
      return Object.values(state.templates_map);
    },
    get_category_fields: state => (category_id) => {
      return category_id ? state.categories_map[category_id]?.fields : [];
    },
    get_template_details: state => uid => state.templates_map[uid],
    categories: state => Object.values(state.categories_map),
    fields(state) {
      let field_list = [];
      const fields = state.active_component_details?.fields || [];
      if (state.active_instance || state.active_tab === 'SmInstances') {
        field_list = orderBy(fields.filter(field => !field?.record_self_values), 'name');
      }
      else {
        field_list = [
          ...orderBy(this.get_category_fields(state.active_component_details?.category), 'name'),
          ...fields.filter(field => field?.record_self_values),
        ];
      }
      return field_list;
    },
    sidebar_hierarchy(state) {
      if (!state.active_instance)
        return state.active_component_hierarchy;
      return state.active_instance_hierarchy;
    },
    can_modify_resources() {
      const auth_store = useAuthStore();
      return auth_store.check_permission('modify_sm_resources', this?.$router?.currentRoute?.value?.params?.asset_id || null);
    },
  },
  actions: {
    /* --------------------------------General------------------------------------------ */
    async set_system_model_initial_data(payload) {
      await Promise.all([
        this.set_templates(payload),
        this.set_categories(),
      ]);
    },
    set_active_tab(payload) { // ACTIVE TAB for component details page
      this.active_tab = payload;
    },
    /* --------------------------------Categories------------------------------------------ */
    async create_category(payload) {
      const { data: { category } } = await this.$services.sm_categories.post({
        body: payload,
      });
      this.categories_map[category.uid] = category;
      return category;
    },
    async set_categories() {
      try {
        const { data } = await this.$services.sm_categories.getAll({
          query: {
            'include[]': 'fields.*',
            'sideloading': false,
          },
        });
        this.categories_map = keyBy(data.categories, 'uid');
      }
      catch (error) {
        logger.error(error);
      }
    },
    /* --------------------------------Templates------------------------------------------ */
    async set_templates(options) {
      try {
        const { data } = await this.$services.sm_templates.getAll({
          query: {
            'filter{asset}': options?.asset_id,
            'include[]': ['components.*', 'components.category'],
          },
        });
        this.templates_map = keyBy(data.templates, 'uid');
        this.templates_components_map = keyBy(data.components, 'uid');
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async create_template(payload) {
      try {
        const { data } = await this.$services.sm_templates.post({
          body: payload,
        });
        if (data?.template) {
          this.templates_map[data.template.uid] = data.template;
          return data.template;
        }
      }
      catch (err) {
        logger.error(err);
      }
    },
    async update_template(payload) {
      try {
        const { data } = await this.$services.sm_templates.patch({
          id: payload.uid,
          body: payload.body,
          query: {
            'include[]': 'components.*',
          },
        });
        if (data?.[0])
          this.templates_map[payload.uid].name = data[0].name;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async delete_template(payload) {
      await this.$services.sm_templates.delete({
        attribute: payload.uid,
      });
      this.templates_map = omit(this.templates_map, payload.uid);
    },
    async create_duplicate_template(payload) {
      try {
        await this.$services.sm_templates.post({
          attribute: `${payload.template_uid}/duplicate`,
          body: payload.body,
        });
      }
      catch (err) {
        // Need some message
        this.$toast({ text: 'Template duplication failed!', type: 'error' });
        logger.error(err);
      }
    },
    async set_as_current_version(payload) {
      await this.$services.sm_instances.get({
        attribute: `template/${payload.template_uid}/build`,
      });
    },
    /* --------------------------------Components------------------------------------------ */
    set_active_component(component) {
      this.active_component = component;
      this.active_instance = null;
    },
    // sidebar tree data
    async set_active_component_hierarchy(payload) {
      try {
        const { data } = await this.$services.sm_components.getAll({
          attribute: `template/${payload.template_id}/paths`,
          query: {
            'include[]': 'fields.*', // not needed
            'exclude[]': 'field_values',
            'invalidate': true,
          },
        });
        this.active_component_hierarchy = data.components;
        if (payload?.component)
          this.set_active_component(payload.component);
        else
          this.set_active_component(this.active_component_hierarchy[0]);
      }
      catch (err) {
        logger.error(err);
      }
    },
    async set_active_component_details(options, set_current = true) {
      try {
        const query = {
          'include[]': ['fieldvalues.field.*', 'fields.*', 'parents.*', 'category', 'element.*'],
          'sideloading': false,
        };
        const { data } = await this.$services.sm_components.get({
          id: options?.component_id,
          query,
        });
        if (set_current)
          this.active_component_details = data?.component || {};

        return data?.component;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async create_components(payload) {
      try {
        const { data } = await this.$services.sm_components.post({
          body: payload.body,
        });
        if (data?.component) {
          const template_uid = this.$router.currentRoute.value.params.template_id;
          const template = this.templates_map[template_uid];
          template.components.push(data.component.uid);
          this.templates_components_map[data.component.uid] = data.component;
        }

        return data;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async create_child_component(payload) {
      const { data } = await this.$services.sm_components.post({
        attribute: `${payload.parent_uid}/child/${payload.child_uid}`,
      });
      return data;
    },
    async update_component(payload) {
      try {
        await this.$services.sm_components.patch(payload);
      }
      catch (err) {
        logger.error(err);
      }
    },
    async delete_component(options) {
      if (options.type === 'delete') {
        await this.$services.sm_components.delete({
          attribute: options.component_id,
        });
        delete this.templates_components_map[options.component_id];
        this.active_component_details = [];
      }
      else {
        await this.$services.sm_components.delete({
          attribute: `${options.parent_id}/child/${options.component_id}/`,
        });
      }
    },
    async set_component_children(payload) {
      const { data } = await this.$services.sm_components.get({
        id: payload.parent_id,
        attribute: 'get-children',
      });
      return data;
    },
    async create_duplicate_component(payload) {
      try {
        await this.$services.sm_components.post({
          attribute: `${payload.component_uid}/duplicate`,
          body: payload.body,
        });
      }
      catch (err) {
        // Need some message
        this.$toast({ text: 'Component duplication failed!', type: 'error' });
        logger.error(err);
      }
    },
    /* --------------------------------Misc------------------------------------------ */
    async update_field_values(payload) {
      try {
        const { data: { fieldvalue } } = await this.$services.sm_fieldvalues.patch({
          body: payload,
        });
        return fieldvalue;
      }
      catch (err) {
        logger.error(err);
        this.$toast({
          title: 'Update failed',
          text: 'Something went wrong',
          type: 'error',
        });
      }
    },
    async create_field_value(payload) {
      try {
        const { data: { fieldvalue } } = await this.$services.sm_fieldvalues.post({
          body: payload,
        });
        return fieldvalue;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async create_field(payload) {
      try {
        const { data: { field } } = await this.$services.sm_fields.post({ body: payload.body });
        if (field) {
          if (payload.property === 'instance' || field?.record_self_values) {
            // update for detail page
            const fields = this.active_component_details?.fields ?? [];
            this.active_component_details.fields = [...fields, field];
          }
          else {
            const { category } = payload.body;
            const fields = this.categories_map[category]?.fields ?? [];
            this.categories_map[category].fields = [...fields, field];
          }
        }
        return field;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async update_field(payload) {
      const { data } = await this.$services.sm_fields.patch({
        id: payload.field_id,
        body: payload.body,
      });
      if (data?.[0]) {
        if (payload.property === 'component') {
          const fields = this.categories_map[this.active_component.category]?.fields;
          if (fields) {
            const field_index = fields.findIndex(f => f.uid === data[0].uid);
            if (field_index !== -1)
              fields[field_index].name = data[0].name;
          }
        }
        else if (payload.property === 'instance') {
          const fields = this.active_component_details?.fields || [];
          this.active_component_details.fields = fields.map((f) => {
            if (f.uid === data[0].uid)
              f.name = data[0].name;
            return f;
          });
        }
      }
    },
    async update_fields(payload) {
      try {
        await this.$services.sm_fields.patch({
          body: payload.body,
        });
      }
      catch (err) {
        logger.error(err);
      }
    },
    async delete_field(payload) {
      try {
        await this.$services.sm_fields.delete({
          attribute: payload.uid,
        });
        if (payload?.record_self_values) {
          const fields = (this.active_component_details?.fields ?? []).filter(field => field.uid !== payload.uid);
          this.active_component_details.fields = [...fields];
        }
      }
      catch (err) {
        logger.error(err);
      }
    },
    /* --------------------------------Instances------------------------------------------ */
    async set_active_instance(instance) {
      this.active_instance = instance;
    },
    async set_instances(payload) {
      const query = {
        'sort[asc]': ['name'],
        'include[]': ['fieldvalues.field.*', 'parents.component'],
        'sideloading': 'false',
        'filter{component.uid}': this.active_component.uid,
        'page_num': payload?.page_num || 1,
        'page_size': payload?.page_size || 40,
        'search': payload?.search || '',
      };
      const { data, headers } = await this.$services.sm_instances.getAll({ query });
      if (data?.instances)
        this.component_instances = data.instances;

      return headers['x-total-count'];
    },
    async create_instances(payload) {
      await this.$services.sm_instances.post({
        body: payload,
      });
    },
    async update_instances(payload) {
      try {
        await this.$services.sm_instances.patch({
          body: payload,
        });
      }
      catch (err) {
        logger.error(err);
      }
    },
    async import_instances(payload) {
      return await this.$services.sm_components.import_csv({
        component_id: this.active_component.uid,
        plan_id: payload.plan_id,
        query: {
          json: true,
        },
        body: {
          columns: payload.columns,
          data: payload.data,
        },
      });
    },
    async instance_aliases(payload) {
      return await this.$services.sm_instances.post_aliases({
        template: payload.template_id,
        query: {
          json: true,
        },
        body: payload.body,
      });
    },
    async set_active_instance_details(payload, set_to_active = false) {
      try {
        const { data } = await this.$services.sm_instances.get({
          id: payload?.instance_uid || this.active_instance?.uid,
          query: {
            'include[]': [
              'fieldvalues.*',
              'fieldvalues.field.*',
              'element.*',
            ],
            'sideloading': 'false',
          },
        });
        if (set_to_active)
          this.set_active_instance(data.instance);

        this.active_instance_details = data.instance;
        return data;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async set_active_instance_hierarchy(payload, set_to_store = true) {
      const { data } = await this.$services.sm_instances.get({
        id: payload?.instance_id || this.active_instance?.uid,
        attribute: 'paths',
        query: {
          ancestors: payload?.ancestors || true,
          descendants: payload?.descendants || false,
        },
      });
      if (set_to_store) {
        const flat_data = getFlatData(data);
        this.active_instance_hierarchy = flat_data;
      }
      return data;
    },
    async set_instance_children(payload) {
      try {
        const { data } = await this.$services.sm_instances.get({
          id: payload?.instance_id,
          attribute: 'get-children',
        });
        if (data.length) {
          const new_data = data.map(item => ({ ...item, parent: payload?.instance_id }));
          this.active_instance_hierarchy = [...this.active_instance_hierarchy, ...new_data];
        }
        return data;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async delete_instances(payload, is_bulk = true) {
      try {
        await this.$services.sm_instances.delete({
          body: payload,
        });
        if (is_bulk)
          this.component_instances = [];

        else
          remove(this.component_instances, f => f.uid === payload[0].uid);

        this.$toast({
          title: 'Successfully deleted instances',
          text: 'The instances have been deleted successfully.',
          type: 'success',
          position: 'bottom-right',
        });
      }
      catch (err) {
        logger.error(err);
        this.$toast({
          text: Array.isArray(err?.data) ? err?.data[0] : 'Something went wrong',
          type: 'error',
          position: 'bottom-right',
        });
      }
    },
    /* --------------------------------Connections------------------------------------------ */
    async set_and_update_connection(payload) {
      try {
        await this.$services.sm_components.post({
          attribute: `${payload.parent_uid}/add/layout`,
          body: {
            data: payload.body,
          },
        });
      }
      catch (err) {
        logger.error(err);
        this.$toast({
          title: 'Connection',
          text: 'This connection is not possible.',
          type: 'error',
        });
      }
    },
    async remove_connection(payload) {
      await this.$services.sm_components.delete({
        attribute: `${payload.parent_uid}/child/${payload.child_uid}/`,
      });
    },
    /* --------------------------------Inventory items------------------------------------------ */
    async set_inventory_items_list(payload) {
      try {
        const { data } = await this.$services.inventory_items.get_items_list_by_uids({
          body: payload.body,
          ...(payload?.query ? { query: payload.query } : {}),
        });
        return data;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async set_inventory_items(payload) {
      const { data } = await this.$services.inventory_items.getAll(payload);
      if (data?.items) {
        this.inventory_items = data?.items;
        return data?.items;
      }
    },
    async update_inventory_items(payload) {
      try {
        if (payload.type === 'component') {
          const { data } = await this.$services.sm_components.patch({
            id: payload.uid,
            body: payload.body,
          });
          if (data?.[0]?.materials)
            this.active_component_details.materials = data[0].materials;
        }
        else if (payload.type === 'instance') {
          const { data } = await this.$services.sm_instances.patch({
            id: payload.uid,
            body: payload.body,
          });
          if (data?.[0]?.materials)
            this.active_instance_details.materials = data[0].materials;
        }
      }
      catch (err) {
        logger.error(err);
      }
    },
    set_sidebar_force_render(payload) {
      this.sidebar_force_render = payload;
    },
    /* ----------------------------------- Terra----------------------------------- */
    async set_instance_by_field(payload) {
      try {
        const { data } = await this.$services.sm_instances.post({
          attribute: this.$router.currentRoute.value.name === 'terra-viewer' ? `asset/${this.$router.currentRoute.value.params.asset_id}` : `container/${payload.container_id}`,
          query: {
            'include[]': ['fieldvalues.field.*', 'component.fields.*', 'fieldvalues.*', 'component.fieldvalues.field.*', 'component.category.*', 'component.category.fields.*', 'element.*'],
            'sideloading': false,
          },
          body: {
            name: payload.instance_name,
          },
          toast: false,
        });
        return data?.instance;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async set_instances_by_fields(payload) {
      try {
        const { data } = await this.$services.sm_instances.post({
          attribute: this.$router.currentRoute.value.name === 'terra-viewer' ? `asset/${this.$router.currentRoute.value.params.asset_id}` : `container/${payload.container_id}`,
          query: {
            'include[]': ['fieldvalues.field.*', 'component.fields.*', 'fieldvalues.*', 'component.fieldvalues.field.*', 'component.category.*', 'component.category.fields.*', 'element.*'],
            'sideloading': false,
          },
          body: {
            instances: payload.instance_names,
          },
          toast: false,
        });

        return data?.instances;
      }
      catch (err) {
        logger.error(err);
      }
    },

    async get_locations(references) {
      try {
        const vectors = await this.get_reference_vectors(references);
        const locations = {};
        if (vectors) {
          const turf = (await import('@turf/turf'));
          references.forEach((reference) => {
            if (vectors[reference]) {
              locations[reference] = turf.centroid(vectors[reference]?.[0])?.geometry || null;
            }
          });
        }
        return locations;
      }
      catch (err) {
        logger.error(err);
      }
    },

    async get_reference_vectors(references) {
      try {
        const { data } = await this.$services.features.get_reference_vectors({
          body: { names: references, create_elements: false },
          asset_id: this.$router.currentRoute.value.params.asset_id,
        });
        return data?.features?.length ? groupBy(data?.features, 'properties.name') : null;
      }
      catch (error) {
        logger.error(error);
      }
    },
  },
});
