<script setup>
import { has, isArray } from 'lodash-es';
import HawkActivitiesTemplate from '~/common/components/organisms/hawk-activities/hawk-activities-template.vue';
import DocumentTransmittalActivities from '~/common/components/organisms/hawk-activities/document-activities/document-transmittal-activities.vue';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  activity: {
    type: Object,
    default: () => {},
  },
});
const task_store = useTasksStore();
const show_from = ref(true);
const show_to = ref(true);
const FOLDER_ACTIVITY_VERB_MAP = {
  CREATED: 'created the folder',
  DOWNLOADED: 'downloaded the folder',
  OWNER_CHANGED: 'transffered ownership',
  ARCHIVED: 'archived the folder',
  UNARCHIVED: 'unarchived the folder',
  UPDATED: 'renamed the folder',
  DELETED: 'deleted the folder',
  RESTORED: 'restored the folder',
  SHARED: 'shared with members',
  UNSHARED: 'removed the members',
  ACCESS_UPDATED: 'changed the access for the members',
  SUBFOLDER_ADDED: 'added a new folder',
  SUBFOLDER_DOWNLOADED: 'downloaded the folder',
  SUBFOLDER_DELETED: 'deleted a folder',
  SUBFOLDER_RESTORED: 'restored a folder',
  SUBFOLDER_ARCHIVED: 'archived a folder',
  SUBFOLDER_UNARCHIVED: 'unarchived a folder',
  SUBFOLDER_MOVED: 'moved the folder here',
  FILE_MOVED: 'moved the file here',
  FILE_UNARCHIVED: 'unarchived a file',
  FILE_ARCHIVED: 'archived a file',
  FILE_RESTORED: 'restored a file',
  FILE_DELETED: 'deleted a file',
  FILE_ADDED: 'added a new file',
  FILE_DOWNLOADED: 'file downloaded',
  PRIVACY_CHANGED: 'changed the privacy',
  INTEGRATION_ADDED: '',
  INTEGRATION_REMOVED: '',

};

const FILE_ACTIVITY_VERB_MAP = computed(() => {
  return {
    CREATED: 'created the file',
    OWNER_CHANGED: 'transferred ownership to',
    // UPDATED: 'renamed the file',
    SHARED: 'shared with members',
    UNSHARED: 'removed the members',
    VIEWED: 'viewed the file',
    VERSION_ADDED: 'added a new version',
    VERSION_CHANGED: 'changed the version',
    VERSION_DELETED: 'deleted the version',
    ACCESS_UPDATED: 'changed the access for the members',
    MOVED: 'moved the file here',
    UNARCHIVED: 'unarchived the file',
    ARCHIVED: 'archived the file',
    RESTORED: 'restored the file',
    DELETED: 'deleted the file',
    ADDED: 'added a new file',
    DOWNLOADED: 'downloaded the file',
    FILE_MOVED: 'moved the file here',
    TRANSMITTAL_STARTED: 'transmitted the file in the transmittal',
    TRANSMITTAL_CANCELLED: 'cancelled the file in the transmittal',
    TRANSMITTAL_COMPLETED: 'completed the file in the transmittal',
    TRANSMITTAL_SYNCED: 'synced the file from the transmittal',
    TRANSMITTAL_PAUSED: 'paused the file in the transmittal',
    TRANSMITTAL_RESUMED: 'resumed the file in the transmittal',
    FILE_DOWNLOADED: 'file downloaded',
    FILE_UNARCHIVED: 'unarchived a file',
    FILE_ARCHIVED: 'archived a file',
    FILE_RESTORED: 'restored a file',
    FILE_DELETED: 'deleted a file',
    FILE_ADDED: 'added a new file',
  };
},
);

const is_transmittal_document = computed(() => {
  return props.activity?.meta?.transmittal;
});

const is_folder = computed(() => {
  return props.activity.meta.folder || props.activity.meta.subfolder;
});
const is_file = computed(() => {
  return props.activity.meta.file;
});

const exclude_keys = computed(() => {
  const keys = ['ip_address', 'user_agent', 'folder'];
  if (props.activity.verb === 'OWNER_CHANGED')
    keys.push('file');
  return keys;
});

onMounted(() => {
  if (['VERSION_CHANGED', 'OWNER_CHANGED', 'PRIVACY_CHANGED'].includes(props.activity.verb))
    show_from.value = false;
  if (props.activity.verb === 'FILE_MOVED')
    show_to.value = false;
  if (is_file.value && props.activity.verb === 'UPDATED') {
    show_to.value = false;
    show_from.value = false;
  }
});
</script>

<template>
  <HawkActivitiesTemplate v-if="!has(activity.meta, 'private.to')" :activity="activity" class="text-sm" :exclude_keys="exclude_keys">
    <template #action>
      <span v-if="is_transmittal_document">
        <DocumentTransmittalActivities :activity="activity" />
      </span>
      <span v-else>
        {{ is_folder ? FOLDER_ACTIVITY_VERB_MAP[activity.verb] : FILE_ACTIVITY_VERB_MAP[activity.verb] }}
      </span>
    </template>
    <template v-if="show_from" #from="{ from }">
      <div class="flex">
        <span class="mx-[5px]">
          {{ $t('from') }}
        </span>

        <div v-if="from.key === 'owner'">
          <HawkMembers :members="from.value" type="badge" :max_badges_to_display="1" />
        </div>
        <div v-if="from.key === 'name'">
          {{ from.value }}
        </div>
        <div v-if="from.key === 'folder'">
          {{ from.value.name }}
        </div>
      </div>
    </template>
    <template #to="{ to }">
      <div v-if="show_to" class="flex">
        <span class="mx-[5px]">
          {{ $t('to') }}
        </span>
        <div v-if="to.key === 'owner'">
          <HawkMembers :members="to.value" type="badge" :max_badges_to_display="1" />
        </div>
        <div v-if="to.key === 'name'">
          {{ to.value }}
        </div>
        <div v-if="to.key === 'version'">
          {{ to.value.name }}
        </div>
      </div>
    </template>
    <template #meta="{ meta }">
      <div v-if="is_transmittal_document" />
      <div v-else class="mx-[5px]">
        <div v-if="activity.verb === 'UPDATED'">
          <div v-if="activity.meta?.name">
            <span>
              {{ $t('renamed the file') }}
            </span>
            {{ $t('from') }}
            {{ activity.meta?.name?.from }}
            {{ $t('to') }}
            {{ activity.meta?.name?.to }}
          </div>
          <div v-if="activity.meta?.number">
            <span>
              {{ $t('Updated the number') }}
            </span>
            {{ $t('from') }}
            {{ activity.meta?.number?.from }}
            {{ $t('to') }}
            {{ activity.meta?.number?.to }}
          </div>
          <div v-if="activity.meta?.category">
            <span class="mr-1">
              {{ $t('Updated the category') }}
            </span>
            <span
              v-if=" activity.meta?.category?.from?.name"
            >
              {{ $t('from') }}
              {{ activity.meta?.category?.from.name }}
            </span>
            <span>
              {{ $t('to') }}
              {{ activity.meta?.category?.to.name }}
            </span>
          </div>
          <div v-if="activity.meta?.status">
            <span class="mr-1">
              {{ $t('Updated the status') }}
            </span>
            <span
              v-if=" activity.meta?.status?.from?.name"
            >
              {{ $t('from') }}
              <span> {{ activity.meta?.status?.from.name }}</span>
            </span>
            <span class="ml-1">
              {{ $t('to') }}
              <span>
                {{ activity.meta?.status?.to.name }}
              </span>
            </span>
          </div>
          <div v-if="activity.meta?.tags">
            <span class="mr-1">
              {{ $t('Updated the tags') }}
            </span>
            <span
              v-if="activity.meta?.tags?.from?.length"
            >
              {{ $t('from') }}
              <HawkTagsName :tags="activity.meta.tags.from" />
            </span>
            <span>
              {{ $t('to') }}
              <HawkTagsName :tags="activity.meta.tags.to" />
            </span>
          </div>
          <div v-if="activity.meta?.custom_fields">
            <p class="mr-1 flex">
              {{ $t('Updated the custom fields') }}:
            </p>
            <div v-for="(cf, index) in activity.meta.custom_fields" :key="index" class="my-1">
              <div v-if="cf.field?.value?.from?.type === 'date'">
                {{ cf.field.name }}
                <span v-if="cf.field?.value?.from?.value" class="mr-1"> {{ $t('from') }}
                  {{ $date(new Date(cf.field?.value?.from?.value), 'DATETIME_MED') }}
                </span>

                <span v-if="cf.field?.value?.to.value" class="mr-0.5">
                  {{ $t('to') }}
                </span>
                {{ $date(new Date(cf.field?.value?.to?.value), 'DATETIME_MED') }}
              </div>
              <div v-else-if="cf.field?.value?.from?.type === 'members'">
                {{ cf.field.name }}
                <span v-if="cf.field?.value?.from?.value?.length" class="mr-1"> {{ $t('from') }}
                  <HawkMembers :members="cf.field?.value?.from.value" type="badge" />
                </span>

                <span v-if="cf.field?.value?.to.value" class="mr-0.5">
                  {{ $t('to') }}
                </span>
                <HawkMembers :members="cf.field?.value?.to.value" type="badge" />
              </div>

              <div v-else>
                {{ cf.field.name }}
                <span v-if="cf.field?.value?.from.value" class="mr-1"> {{ $t('from') }}</span>
                {{ isArray(cf.field?.value?.from.enriched) ? cf.field?.value?.from.enriched.join(' & ') : cf.field?.value?.from.enriched }}
                <span v-if="cf.field?.value?.to.value" class="mr-0.5">
                  {{ $t('to') }}
                </span>
                {{ isArray(cf.field?.value?.to.enriched) ? cf.field?.value?.to.enriched.join(' & ') : cf.field?.value?.to.enriched }},
              </div>
            </div>
          </div>
        </div>
        <div v-if="activity.verb === 'INTEGRATION_ADDED'">
          {{ $t("added") }} {{ activity.meta.integration.type }}  {{ $t("integration") }}
        </div>
        <div v-if="activity.verb === 'INTEGRATION_REMOVED'">
          {{ $t("removed") }}  {{ activity.meta.integration.type }} {{ $t("integration") }}
        </div>
      </div>
      <div v-if="activity.meta.members">
        <HawkMembers v-if="activity.meta.members?.updated" :members="activity.meta.members.updated.from" type="badge" :max_badges_to_display="1" />
        <HawkMembers v-else-if="activity.meta.members.added || activity.meta.members.removed" :members="activity.meta.members.added || activity.meta.members.removed" type="badge" :max_badges_to_display="1" />
      </div>
      <div v-else-if="meta.key === 'version' || meta.key === 'subfolder'">
        {{ meta.items.name }}
      </div>
      <div v-else-if="meta.key === 'folder'">
        {{ activity?.meta?.file?.name || activity?.meta?.folder?.name }}
      </div>
      <div v-else-if="activity.verb.startsWith('FILE_') ">
        {{ activity?.meta?.file?.name }}
      </div>
    </template>
  </HawkActivitiesTemplate>

  <div v-if="activity?.meta?.private" class="flex items-center flex-wrap">
    <span>
      {{ FOLDER_ACTIVITY_VERB_MAP[activity.verb] }}
    </span>
    <div class="flex">
      <span class="mx-[5px]"> to </span>
      <div>
        {{ activity?.meta?.private?.to ? 'private' : 'everyone' }}
      </div>
    </div>
  </div>
</template>
