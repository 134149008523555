<!-- eslint-disable unused-imports/no-unused-vars -->
<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { onMounted, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { load_js_css_file } from '~/common/utils/load-script.util';
import InventoryTransactionFormItem from '~/inventory/components/inventory-transaction-form/inventory-transaction-form-item.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store';

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  to_stock: {
    type: String,
    default: '',
  },
  from_stock: {
    type: String,
    default: '',
  },
  workflow: {
    type: Object,
    default: () => ({}),
  },
  disable_serial_numbers: {
    type: Boolean,
    default: false,
  },
  form_workflow_config: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(['update', 'disableCta', 'updateFormSubmissions', 'disableSave']);
const { $services, $t } = useCommonImports();
const state = reactive({
  adjustment_lines: [],
  is_adding_item: false,
  selected_adjustment_list_item: null,
  expanded_adjustment_list_item: {},
  is_validating: false,
  valid_stocks: {},
  invalid_stocks: {},
  item_form_submissions_map: {},
});
const inventory_store = useInventoryStore();
const exclude_items = computed(() => {
  return state.adjustment_lines.map(line => line?.item?.uid);
});

const adjustment_list_conditions = computed(() => {
  return props.from_stock && (props.workflow?.stock_operation !== 'no_op' ? props.to_stock : true);
});

const has_shipment_allocation = computed(() => {
  return state.adjustment_lines.some(line => line?.is_shipment_allocation);
});
const get_serial_numbers_quantity = computed(() => {
  return serial_numbers => serial_numbers?.[0]?.serial_numbers
    ? serial_numbers?.reduce((acc, curr) => acc + curr.serial_numbers?.length, 0)
    : serial_numbers?.length || 0;
});
const get_result_quantity = computed(() => {
  return (line) => {
    const quantity = Number(line?.quantity);
    return Number(
      (props.workflow.stock_operation === 'stock_in'
        ? getCurrentQuantity(line?.item) + quantity
        : getCurrentQuantity(line?.item) - quantity) || 0,
    );
  };
});
const form_workflow_config_updated = computed(() => {
  return {
    ...props.form_workflow_config,
    item_form_submissions_map: state.item_form_submissions_map,
  };
});
const item_form_status = computed(() => {
  return state.adjustment_lines.reduce((acc, line) => {
    acc[line.item.uid] = getItemFormStatus(line.item);
    return acc;
  }, {});
});
watch(
  () => [props.from_stock, props.to_stock],
  async () => {
    state.adjustment_lines = [];
    state.is_adding_item = false;
    emit('update', state.adjustment_lines);
  },
  { deep: true },
);
watch(
  () => props.value,
  async () => {
    state.adjustment_lines = props.value;
  },
  { deep: true, immediate: true },
);
function getCurrentQuantity(item) {
  if (props.to_stock === true)
    return item.physical_stock_quantity || 0;
  return Number(
    item?.warehouse_stock_quantity[
      props.workflow?.stock_operation === 'stock_in'
        ? props.to_stock
        : props.from_stock
    ]?.quantity || 0,
  );
}
function uomSymbol(item) {
  return inventory_store.uom_map[item?.uom]?.symbol || '';
}
function addAdjustmentLines(form, is_creating_form = false) {
  state.adjustment_lines.push({
    ...form,

    description: form.description,
    item: form.item,
    quantity: form.quantity,
  });
  state.expanded_adjustment_list_item[form.item.uid] = {
    value: false,
    type: 'edit',
  };
  emit('update', state.adjustment_lines);
  state.is_adding_item = is_creating_form;
}
function updateAdjustmentLine(form) {
  const index = state.adjustment_lines.findIndex(line => line.item.uid === form.item.uid);
  state.adjustment_lines[index] = { ...form, uid: state.adjustment_lines[index]?.uid };
  emit('update', state.adjustment_lines);
  state.expanded_adjustment_list_item[form.item.uid] = {
    value: false,
    type: 'edit',
  };
}
function updateFormSubmissionMap(e) {
  const value = state.item_form_submissions_map[e.uid];
  if (value) {
    state.item_form_submissions_map[e.uid] = {
      ...value,
      ...e.value,
    };
  }
  else {
    state.item_form_submissions_map[e.uid] = e.value;
  }
}
function getItemFormStatus(item) {
  const form_workflows = props.form_workflow_config?.available_form_workflows;
  const workflow_has_item = form_workflows.filter(workflow => workflow?.items?.includes(item.uid));
  return workflow_has_item.map((workflow) => {
    const is_required = workflow_has_item?.some?.(workflow => workflow.required) || false;
    const form_submissions = (state.item_form_submissions_map[item.uid]?.form_submissions || []).filter(submission => submission?.form_template_uid === workflow?.uid);

    if (!workflow.required)
      return { color: 'primary', tooltip: $t('Filling out this form is optional'), custom_class: 'border border-primary-600' };
    if (is_required && form_submissions.length === 0)
      return { color: 'warning', tooltip: $t('Filling out this form is mandatory to publish transaction'), custom_class: 'border border-warning-600' };
    if (workflow.required && form_submissions.length > 0) {
      const color = form_submissions[0]?.submission_status === 'submitted' ? 'success' : 'error';
      return { color, tooltip: color === 'success' ? $t('Form submitted') : $t('Filling out this form is mandatory to publish transaction'), custom_class: `border ${color === 'success' ? 'border-success-600' : 'border-error-600'}` };
    }

    return { color: 'primary', custom_class: 'border border-primary-600' };
  });
}
function deleteAdjustmentLine(line, index) {
  if (line.uid) {
    const delete_popup = useModal({
      component: HawkDeletePopup,
      attrs: {
        header: $t('Delete item'),
        content: `There is a form associated with this item. Are you sure you want to delete ${line.item?.name || ''}? This action cannot be undone.`,
        confirm: async () => {
          try {
            const response = await $services.inventory.delete({
              attribute: `adjustment-lines/${line.uid}`,
            });
            state.adjustment_lines.splice(index, 1);
            delete state.item_form_submissions_map[line.item.uid];
            delete_popup.close();
          }
          catch (error) {
            logger.error(error);
          }
        },
        onClose() {
          delete_popup.close();
        },
      },
    });
    delete_popup.open();
  }
  else {
    state.adjustment_lines.splice(index, 1);
  }
}
function handleEdit(line) {
  state.expanded_adjustment_list_item[line.item.uid] = { value: !state.expanded_adjustment_list_item[line.item.uid]?.value, type: 'edit' };
  emit('disableCta', state.expanded_adjustment_list_item[line.item.uid]?.value);
}
onMounted(async () => {
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@12.2.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@12.2.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
});

watch(() => (props.form_workflow_config?.form_submissions || []), (form_submissions, old) => {
  if (old)
    return;
  state.item_form_submissions_map = form_submissions.reduce((acc, curr) => {
    acc[curr.item_uid] = { form_submissions: [{ ...curr }], disable_save: curr.submission_status !== 'submitted' };
    return acc;
  }, {});
}, { immediate: true });

watch(() => state.item_form_submissions_map, (val) => {
  const form_submissions = Object.entries(val).reduce((acc, [key, value]) => {
    if (value?.form_submissions?.length) {
      if (!acc[key])
        acc[key] = [];
      acc[key].push(...(value?.form_submissions || []));
    }
    return acc;
  }, {});
  emit('updateFormSubmissions', form_submissions);
}, { deep: true, immediate: true });

watch(() => item_form_status.value, (val) => {
  const item_statuses = Object.values(val || {}).filter(status => status.length);
  const disable_save = item_statuses.some(value => value.filter(status => ['warning', 'error'].includes(status.color)).length);
  if (item_statuses.length)
    emit('disableSave', disable_save);
}, { deep: true, immediate: true });
</script>

<template>
  <div>
    <div class="font-semibold">
      {{ $t('Item details') }}
    </div>
    <div class="mt-3">
      <div v-if="state.adjustment_lines.length">
        <div v-for="(line, index) in state.adjustment_lines" :key="line.item.uid" class="hover:bg-gray-50 bgd-red-500" :class="{ 'border-b': index !== (state.adjustment_lines.length - 1) }">
          <div class="grid grid-cols-[2fr_3fr_1fr_auto] gap-4 items-center p-3 rounded-lg">
            <div class="col-span-1">
              <p class="font-semibold text-sm">
                {{ line.item.name }}
              </p>
              <p class="text-xs text-gray-500">
                #{{ line.item.number }}
              </p>
            </div>
            <div class="col-span-1">
              <p class="text-sm text-gray-500 break-all">
                {{ line.description }}
              </p>
            </div>
            <div class="col-span-1">
              <p class="font-medium text-sm">
                {{ line.quantity }} {{ uomSymbol(line.item) }}
              </p>
            </div>

            <div class="col-span-1 flex space-x-2 items-center">
              <template v-if="item_form_status[line.item.uid]?.length">
                <HawkFeaturedIcon v-for="(status, i) in item_form_status[line.item.uid]" :key="i" v-tippy="{ content: status?.tooltip, placement: 'top' }" theme="light-circle" class="!h-[30px] !w-[30px] cursor-pointer" :color="status?.color" :class="status?.custom_class">
                  <IconHawkFileTwo class="w-3 h-3" />
                </HawkFeaturedIcon>
              </template>

              <HawkButton icon type="text" @click="handleEdit(line)">
                <IconHawkEditTwo />
              </HawkButton>
              <HawkButton icon type="text" @click="deleteAdjustmentLine(line, index)">
                <IconHawkTrashThree />
              </HawkButton>
              <HawkButton v-if="line.item.is_serial_number" :loading="state.is_validating === line.item.uid" icon type="text" @click="state.expanded_adjustment_list_item[line.item.uid] = { value: !state.expanded_adjustment_list_item[line.item.uid]?.value, type: 'expand' };">
                <IconHawkChevronUp v-if="state.expanded_adjustment_list_item[line.item.uid]?.value && state.expanded_adjustment_list_item[line.item.uid]?.type === 'expand'" />
                <IconHawkChevronDown v-else />
              </HawkButton>
              <span v-else class="w-9" /> <!-- Placeholder for the third icon -->
            </div>
          </div>
          <InventoryTransactionFormItem
            v-if="state.expanded_adjustment_list_item[line.item.uid]?.value"
            :id="`item_table_${line.item.uid}`"
            class="p-4"
            :workflow="workflow"
            type="edit"
            :validate_data_on_mount="state.expanded_adjustment_list_item[line.item.uid]?.type === 'expand'"
            :to_stock="to_stock"
            :prefill_line_data="line"
            :exclude_items="exclude_items.filter(item => item !== line?.item?.uid)"
            :from_stock="from_stock"
            :disable_serial_numbers="disable_serial_numbers"
            :is_shipment_allocation="state.selected_adjustment_list_item?.is_shipment_allocation"
            :value="line"
            :has_fixed_quantity="state.selected_adjustment_list_item?.item?.is_serial_number && state.selected_adjustment_list_item?.is_shipment_allocation"
            :form_workflow_config="form_workflow_config_updated"
            @update-form-submissions="updateFormSubmissionMap($event)"
            @update-disable-save="updateFormSubmissionMap($event)"
            @save-item="updateAdjustmentLine"
            @close="state.expanded_adjustment_list_item[line.item.uid] = false, emit('disableCta', false)"
          />
        </div>
      </div>
      <InventoryTransactionFormItem
        v-if="state.is_adding_item"
        id="serial_number_table"
        class="bg-gray-100 p-4"
        :workflow="workflow"
        :to_stock="to_stock"
        :exclude_items="exclude_items.filter(item => item !== line?.item?.uid)"
        :from_stock="from_stock"
        :disable_serial_numbers="disable_serial_numbers"
        :is_shipment_allocation="state.selected_adjustment_list_item?.is_shipment_allocation"
        :value="state.selected_adjustment_list_item"
        :has_fixed_quantity="state.selected_adjustment_list_item?.item?.is_serial_number && state.selected_adjustment_list_item?.is_shipment_allocation"
        :form_workflow_config="form_workflow_config_updated"
        @save-item="addAdjustmentLines"
        @close="state.is_adding_item = false, emit('disableCta', false)"
        @update-form-submissions="updateFormSubmissionMap($event)"
        @update-disable-save="updateFormSubmissionMap($event)"
        @toggle-edit="handleEdit"
      />

      <HawkButton v-if="!state.is_adding_item" :disabled="!adjustment_list_conditions" type="text" color="primary" @click="state.is_adding_item = true, emit('disableCta', true)">
        <IconHawkPlus />
        {{ $t('Add Item') }}
      </HawkButton>
    </div>
  </div>
</template>
