<script setup>
import { groupBy, keyBy } from 'lodash-es';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import HawkFieldsSelector from '~/common/components/organisms/hawk-fields-selector/hawk-fields-selector.vue';

const props = defineProps({
  on_register_items: {
    type: Function,
    default: () => {},
  },
  warehouse_details: {
    type: Object,
    default: () => ({}),
  },
});
const { $t, $services, route } = useCommonImports();
const inventory_store = useInventoryStore();

const state = reactive({
  items: [],
  selected: [],
  is_loading_items: false,
  scope: 'asset',
});

const items_by_category = computed(() => {
  const grouped_items = groupBy(state.items, 'category');
  return Object.keys(grouped_items).map((category_uid) => {
    return {
      uid: category_uid,
      name: inventory_store.item_types_map[category_uid]?.name || 'No category',
      children: grouped_items[category_uid],
    };
  });
});

async function getItems() {
  try {
    state.is_loading_items = true;
    let payload = {};
    if (state.scope === 'asset')
      payload = { query: { asset: false } };

    const { data } = await $services.inventory_items.getAll(payload);
    state.items = data.items;
    state.is_loading_items = false;
  }
  catch (err) {
    state.is_loading_items = false;
  }
}
function setScope() {
  if (route.name === 'inventory-warehouse-details')
    state.scope = 'warehouse';
}
async function setSelectedItems() {
  const items_map = keyBy(state.items, 'uid');
  if (state.scope === 'warehouse')
    state.selected = (props.warehouse_details?.items || []).reduce((acc, item_uid) => {
      if (items_map[item_uid])
        acc.push(items_map[item_uid]);
      return acc;
    }, []);
  else
    state.selected = state.items.filter(item => item.assets?.includes(route.params.asset_id));
}

async function registerItems(updated_items) {
  const body = { items: updated_items.map(item => item.uid) };
  if (state.scope === 'asset')
    await $services.inventory_items.register_items_in_asset({
      asset_id: route.params.asset_id,
      body,
      toast: false,
    });
  else if (state.scope === 'warehouse')
    await $services.inventory_items.register_items_in_warehouse({
      warehouse_id: route.params.warehouse_id,
      body,
      toast: false,
    });
  props.on_register_items();
}

onMounted(async () => {
  setScope();
  await getItems();
  setSelectedItems();
});
</script>

<template>
  <HawkFieldsSelector
    :items="items_by_category"
    :selected_items="state.selected"
    :texts="{
      heading: $t('Register items'),
      left_heading: state.scope === 'asset' ? $t('Master item list') : $t('Asset item list'),
      right_heading: state.scope === 'asset' ? $t('Asset item list') : $t('Warehouse item list'),
    }"
    :is_loading_items="state.is_loading_items"
    :max_selected_items="Number.MAX_SAFE_INTEGER"
    :close_on_save="true"
    :update="registerItems"
    :hidden_features="['arrow-up', 'arrow-down']"
  />
</template>
