<script  setup>
import { keyBy } from 'lodash-es';
import { STATIC_EMAIL_VARIABLES } from '~/forms/constants';

const props = defineProps({
  name: {
    type: String,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
    default: 'Enter email address',
  },
  columns: {
    type: Object,
  },
  items: {
    type: Array,
  },
  get_teams: {
    type: Object,
  },
  get_email_by_slug: {
    type: Object,
  },
  rules: {
    type: Array,
  },
  name_truncate_length: {
    type: Number,
    default: 24,
  },
});
const emit = defineEmits(['change']);
const static_email_variable_map = keyBy(STATIC_EMAIL_VARIABLES, 'id');
const is_open = ref(false);
function formatData(e) {
  emit('change', e.map((tag) => {
    return {
      value: tag.value,
      type: tag.type || 'email',
    };
  }));
}
</script>

<template>
  <TagsElement
    :name="name"
    :label="label"
    :columns="columns"
    :search="true"
    :items="items"
    :can-clear="false"
    :create="true"
    :groups="true"
    :object="true"
    :append-new-option="true"
    :group-hide-empty="true"
    :group-select="false"
    :native="false"
    :placeholder="placeholder"
    value-prop="value"
    track-by="name"
    :rules="rules"
    @open="is_open = true"
    @close="is_open = false"
    @change="formatData"
  >
    <template #option="{ option }">
      <HawkMembers v-if="['member', 'team'].includes(option.type)" :members="option.value" size="xs" type="label" name_classes="text-gray-700 w-auto truncate inline-block font-medium" :name_truncate_length="name_truncate_length" />
      <HawkText v-else class="text-gray-700 w-auto truncate inline-block font-medium" :content="option.name" :length="name_truncate_length" />
    </template>
    <template #tag="{ option, handleTagRemove }">
      <div class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
        <HawkMembers v-if="option.type === 'team' || option.type === 'member'" :members="option.value" size="badge" type="label" :name_truncate_length="name_truncate_length" />
        <HawkText v-else-if="option.type === 'field'" class="text-gray-700 w-auto truncate inline-block font-medium" :content="get_email_by_slug[option.value].name" :length="name_truncate_length" />
        <HawkText v-else class="text-gray-700 w-auto truncate inline-block font-medium" :content="static_email_variable_map[option.value].name" :length="name_truncate_length" />
        <div class="cursor-pointer ml-1" @mousedown.prevent="handleTagRemove(option, $event)">
          <IconHawkXClose class="text-gray-400 w-4 h-4" />
        </div>
      </div>
    </template>
  </TagsElement>
</template>
