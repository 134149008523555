<script setup>
import { useModal } from 'vue-final-modal';
import { keyBy, omit, sortBy } from 'lodash-es';
import { sortData } from '~/common/utils/common.utils';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useInventoryBom } from '~/inventory/utils/inventory-bom-export.composable.js';
import InventoryBomAssociateInstances from '~/inventory/components/inventory-bom/inventory-bom-instances-form-modal.vue';
import InventoryBomItemDetailsModal from '~/inventory/components/inventory-bom/inventory-bom-item-details-modal.vue';
import InventoryItemFilters from '~/inventory/components/inventory-items/inventory-item-filters.vue';

const props = defineProps({
  is_list_view: { type: Boolean, default: true },
});

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
const inventory_store = useInventoryStore();
const { is_bom_exporting, setExporting, cancelBomExporting, exportBom } = useInventoryBom();

const state = reactive({
  search: '',
  is_editing: false,
  is_editing_ordered_qty: false,
  is_syncing: false,
  is_loading: false,
  is_updating: false,
  procurement_items_map: {},
  procurement_items_form: {},
  total_procurements_count: 0,
  filters: {},
});

const is_bom_page = computed(() => !route?.params?.warehouse_id);
const is_editing_table = computed(() => state.is_editing || state.is_editing_ordered_qty);
const instances = computed(() => {
  if (Object.values(state.procurement_items_map).length)
    return sortData(Object.values(Object.values(state.procurement_items_map)[0].instances), 'instance_name', ['asc']);
  return [];
});
const warehouse_details = computed(() => inventory_store.warehouses_map[route?.params?.warehouse_id]);
const columns = computed(() => {
  const order_qty_column = (is_bom_page.value
    ? [
        {
          header: 'Ordered',
          accessorKey: 'ordered_qty',
          id: 'ordered_qty',
          customClass: '!p-0',
          size: 100,
        },
      ]
    : []);
  const status_available_columns = (is_editing_table.value
    ? []
    : [
        ...sortBy(inventory_store.statuses.map(item => ({
          header: item.name,
          accessorKey: item.uid,
          id: item.uid,
          customClass: '!p-0',
          size: 100,
        })), ['header']),
        {
          header: 'Status',
          accessorKey: 'item_status',
          id: 'item_status',
          customClass: '!p-0',
        },
      ]);
  return [
    {
      header: ' ',
      accessorKey: 'index',
      id: 'index',
      size: 20,
    },
    {
      header: 'Item',
      accessorKey: 'name',
      id: 'name',
      enableSorting: false,
      customClass: '!py-0 !px-2',
      minSize: 450,
    },
    {
      header: 'Scope',
      accessorKey: 'scope',
      id: 'scope',
      customClass: '!p-0',
      size: 100,
    },
    ...order_qty_column,
    ...(instances.value?.length && state.is_editing
      ? instances.value.map((item, i) => {
        return {
          header: item.instance_name,
          accessorKey: item.instance_uid,
          id: item.instance_uid,
          customClass: '!p-0',
        };
      })
      : status_available_columns),
  ];
});
const procurement_items = computed(() => sortData(Object.values(state.procurement_items_map), 'name', ['asc']));

function getFormattedItem(item) {
  const status_map_data = {};
  const available = (item.warehouse_stock || []).reduce((acc, stock) => {
    stock.stock.forEach((data) => {
      if (!status_map_data[data.uid])
        status_map_data[data.uid] = data.qty;
      else
        status_map_data[data.uid] += data.qty;
    });
    return acc + stock.existing_stock;
  }, 0);
  const scope = item.scope || 0;
  let item_status = {};

  const status = Math.sign(item.bom_quantity - item.scope);
  if (status === -1)
    item_status = { text: `${Math.abs(item.bom_quantity - item.scope)} ${item.uom || ''} less`, status };
  else if (status === 1)
    item_status = { text: `${Math.abs(item.bom_quantity - item.scope)} ${item.uom || ''} excess`, status };
  else if (status === 0 && item.scope > 0 && item.bom_quantity > 0)
    item_status = { text: 'Exact material', status };
  else
    item_status = { text: '-', status };

  const warehouses_stock_uid_value_map = {};
  if (is_bom_page.value)
    item.warehouse_stock.forEach((warehouse) => {
      warehouses_stock_uid_value_map[warehouse.uid] = warehouse.existing_stock;
    });

  return {
    item_status,
    available_qty: available,
    scope,
    ...status_map_data,
    ...warehouses_stock_uid_value_map,
  };
}
async function getProcurementItems() {
  try {
    state.is_loading = true;

    const request = {
      id: route?.params?.warehouse_id,
      asset_id: route.params.asset_id,
      query: {
        asset: route.params.asset_id,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
        search: state.search,
        ...omit(state.filters, 'item_status'),
      },
    };
    let response;

    if (is_bom_page.value)
      response = await $services.inventory_warehouses.get_asset_procurement(request);
    else
      response = await $services.inventory_warehouses.get_procurement_v2(request);
    state.procurement_items_map = response.data.results.reduce((acc, item) => {
      const updated_item = {
        ...item,
        instances: keyBy(item.instances, 'instance_uid'),
        ...getFormattedItem(item),
      };
      const status_filter = state.filters?.item_status || [];
      const { status, text } = updated_item.item_status;
      if (!status_filter.length
      || (status_filter.includes('out_of_stock') && text === '-')
      || (status_filter.includes('low_stock') && status === -1)
      || (status_filter.includes('in_stock') && text !== '-' && status !== -1)
      )
        acc[item.uid] = updated_item;
      return acc;
    }, {});
    state.total_procurements_count = Number(response.headers['x-total-count']);
    state.is_loading = false;
  }
  catch (error) {
    logger.error(error);
    state.is_loading = false;
  }
}

function setEditing(reset = true) {
  state.is_loading = true;
  if (route?.params?.warehouse_id) {
    state.is_editing = true;
    if (reset)
      state.procurement_items_form = {};
    procurement_items.value.forEach((item) => {
      const is_form_item_reset = (instances.value.length && !state.procurement_items_form[item.uid]?.instances) || (!instances.value.length && !state.procurement_items_form[item.uid]?.scope);
      if (reset || is_form_item_reset)
        state.procurement_items_form[item.uid] = instances.value.length ? { instances: {} } : { scope: item.scope || '0' };
      instances.value.forEach((instance) => {
        if (reset || !state.procurement_items_form[item.uid].instances[instance.instance_uid])
          state.procurement_items_form[item.uid].instances[instance.instance_uid] = item?.instances[instance.instance_uid]?.qty || '0';
      });
    });
  }
  else {
    state.is_editing_ordered_qty = true;
    procurement_items.value.forEach((item) => {
      state.procurement_items_form[item.uid] = { ordered_qty: item.ordered_qty || '0' };
    });
  }
  setTimeout(() => {
    state.is_loading = false;
  }, 100);
}

async function removeEditing() {
  state.is_editing = false;
  state.is_editing_ordered_qty = false;
  state.is_loading = true;
  setTimeout(() => {
    state.is_loading = false;
  }, 100);
}

function totalQuantity(item) {
  if (instances.value.length)
    return instances.value.reduce((acc, curr) => acc + (+state.procurement_items_form[item.uid]?.instances?.[curr.instance_uid] || 0), 0);
  return state.procurement_items_form[item.uid].scope;
}

function preventKeydown(e) {
  if (e.keyCode === 189 || e.keyCode === 187 || e.keyCode === 69 || e.keyCode === 38 || e.keyCode === 40 || e.key === '.')
    e.preventDefault();
}

const { open: openBomItemDetailsModal, close: closeBomItemDetailsModal, patchOptions: patchBomItemDetailsOptions } = useModal({
  component: InventoryBomItemDetailsModal,
});
function itemDetailsHandler(procurement_item) {
  if (is_bom_page.value)
    return;
  patchBomItemDetailsOptions(
    {
      attrs: {
        item: procurement_item,
        warehouse_id: route?.params?.warehouse_id,
        onClose() {
          closeBomItemDetailsModal();
        },
        on_save: async (instances_form) => {
          try {
            const payload = [{
              uid: procurement_item.uid,
              instances: [...Object.values(procurement_item.instances || {})].map((instance) => {
                instance.qty = instances_form[instance.instance_uid] || 0;
                return instance;
              }),
            }];
            await $services.inventory_warehouses.set_procurement_v2({
              id: route?.params?.warehouse_id,
              asset_id: route.params.asset_id,
              query: {
                asset: route.params.asset_id,
              },
              body: payload,
            });
            await getProcurementItems();
          }
          catch ({ data: error }) {
            const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
            $toast({
              title: title || 'Something went wrong',
              text: message || 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  if (!state.is_editing)
    openBomItemDetailsModal();
}

const { open: openAssociateInstancesModal, close: closeAssociateInstancesModal, patchOptions: patchAssociateInstancesOptions } = useModal({
  component: InventoryBomAssociateInstances,
});
function openAssociateInstancesHandler() {
  patchAssociateInstancesOptions({
    attrs: {
      warehouse_id: route?.params?.warehouse_id,
      instances: instances.value,
      on_save: async () => {
        await getProcurementItems();
        setEditing(false);
      },
      onClose() {
        closeAssociateInstancesModal();
      },
    },
  });
  openAssociateInstancesModal();
}

async function getSystemModalInstances(instances) {
  const { data } = await $services.sm_instances.generate_instances({
    body: {
      instances,
    },
    query: {
      asset: route.params.asset_id,
      descendants: true,
    },
  });
  return data;
}

async function syncChanges() {
  try {
    state.is_syncing = true;
    const instance_uids = instances.value.map(instance => instance.instance_uid);
    const data = await getSystemModalInstances(instance_uids);
    data.forEach((instance) => {
      Object.keys(instance.items || {}).forEach((item_uid) => {
        state.procurement_items_form[item_uid].instances[instance.uid] = instance.items[item_uid];
      });
    });
    state.is_syncing = false;
  }
  catch ({ data: error }) {
    const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
    $toast({
      title: title || 'Something went wrong',
      text: message || 'Please try again',
      type: 'error',
    });
    state.is_syncing = false;
  }
}

async function updateProcurementItems() {
  state.is_updating = true;
  try {
    const payload = {};
    procurement_items.value.forEach((item) => {
      if (is_bom_page.value) {
        payload[item.uid] = { uid: item.uid };
        payload[item.uid].ordered_qty = state.procurement_items_form[item.uid]?.ordered_qty || '0';
      }
      else {
        payload[item.uid] = item;
        payload[item.uid].scope = totalQuantity(item);
        if (instances.value.length)
          payload[item.uid].instances = instances.value.reduce((acc, instance) => {
            const qty = state.procurement_items_form[item.uid].instances[instance.instance_uid] || 0;
            acc.push({ ...instance, qty });
            return acc;
          }, []);
        else
          payload[item.uid].instances = [];
      }
    });
    if (is_bom_page.value)
      await $services.inventory_warehouses.set_procurement_ordered_qty_v2({
        asset_id: route.params.asset_id,
        query: {
          asset: route.params.asset_id,
        },
        body: Object.values(payload),
      });

    else
      await $services.inventory_warehouses.set_procurement_v2({
        id: route?.params?.warehouse_id,
        asset_id: route.params.asset_id,
        query: {
          asset: route.params.asset_id,
        },
        body: Object.values(payload),
      });
    $toast({
      title: $t('BOM saved'),
      text: $t('Changes to bom saved successfully'),
      type: 'success',
    });
    await getProcurementItems();
  }
  catch ({ data: error }) {
    const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
    $toast({
      title: title || 'Something went wrong',
      text: message || 'Please try again',
      type: 'error',
    });
    logger.error(error);
  }
  removeEditing();
  state.is_updating = false;
}

function pasteFields(event, index, instance_index = -1) {
  try {
    const string = event.clipboardData.getData('text');

    const decimal_regex = /\b\d+\.\d+\b/g;
    const decimal_string = string.replace(decimal_regex, val => Math.floor(Number.parseFloat(val)));

    const regex = /(?:\r\n|\r|\n)|[\t,]/;
    const rows = decimal_string.split(/\r?\n/).map((row) => {
      if (row.trim())
        return row.trim().split(regex).map(val => val.trim());
      return [];
    }).filter(val => !!val.length);

    if (!rows.length)
      return;

    const items = procurement_items.value.slice(index, index + rows.length);
    items.forEach((item, i) => {
      if (is_bom_page.value) {
        state.procurement_items_form[item.uid].ordered_qty = rows[i][0];
      }

      else
        if (instance_index === -1) {
          state.procurement_items_form[item.uid].scope = rows[i][0];
        }
        else {
          const instances_list = instances.value.slice(instance_index, instance_index + rows[i].length);
          instances_list.forEach((instance, j) => {
            state.procurement_items_form[item.uid].instances[instance.instance_uid] = rows?.[i]?.[j] || '0';
          });
        }
    });

    setTimeout(() => {
      const item_uid = procurement_items.value[index].uid;
      if (is_bom_page.value) {
        state.procurement_items_form[item.uid].ordered_qty = rows[0][0] || '0';
      }
      else
        if (instance_index === -1) {
          state.procurement_items_form[item_uid].scope = rows[0][0] || '0';
        }
        else {
          const instance_uid = instances.value[instance_index].instance_uid;
          state.procurement_items_form[item_uid].instances[instance_uid] = rows[0][0] || '0';
        }
    });
  }
  catch (error) {
    logger.error(error);
  }
}

async function onFilterApply(filter) {
  state.filters = filter;
  await getProcurementItems();
}

async function onExport() {
  await exportBom(procurement_items.value);
}

onMounted(async () => {
  if (route.name === 'inventory-bom' && !auth_store.check_permission('view_bom', route.params.asset_id))
    router.push({
      name: 'inventory-permission-denied',
    });
  else
    await getProcurementItems();
});
</script>

<template>
  <div>
    <HawkExportToast
      v-if="is_bom_exporting"
      :submit="onExport"
      progress_text="Exporting BOM report"
      completed_text="BOM report exported"
      @cancel="cancelBomExporting"
      @close="() => setExporting(false)"
    />
    <HawkPageHeader v-if="is_bom_page">
      <template #title>
        {{ $t('Bill of Materials') }}
      </template>
    </HawkPageHeader>
    <div :class="is_bom_page ? 'px-4 w-[calc(100vw-80px)]' : 'w-[calc(100vw-120px)]'">
      <div class="flex items-center justify-between">
        <InventoryItemFilters :include_filters="['category', 'item_status']" :class="{ invisible: state.is_editing }" @apply="onFilterApply($event)" />
        <div class="flex items-center justify-end gap-3">
          <div v-if="(state.is_editing && warehouse_details?.permissions?.modify_bom) || state.is_editing_ordered_qty" class="flex items-center gap-3">
            <template v-if="state.is_editing">
              <HawkButton type="link" @click="openAssociateInstancesHandler">
                {{ instances.length ? $t('Update blocks') : $t('Setup blocks') }}
              </HawkButton>
              <HawkButton v-if="instances.length" v-tippy="{ content: $t('Sync with designs'), placement: 'top' }" icon type="text" :loading="state.is_syncing" @click="syncChanges()">
                <IconHawkRefreshCcwFive />
              </HawkButton>
            </template>

            <HawkButton type="outlined" @click="removeEditing">
              {{ $t('Cancel') }}
            </HawkButton>
            <HawkButton :loading="state.is_updating" @click="updateProcurementItems()">
              {{ $t('Save') }}
            </HawkButton>
          </div>
          <div v-else class="flex gap-3">
            <HawkButton
              v-if="(route.params.warehouse_id && warehouse_details?.permissions?.modify_bom && route?.params?.asset_id) || (is_bom_page)"
              v-tippy="{ content: is_bom_page ? $t('Add Ordered quantity') : $t('Add Scope'), placement: 'top' }"
              icon type="text" @click="setEditing"
            >
              <IconHawkPencilOne />
            </HawkButton>
            <HawkButton v-tippy="{ content: $t('Export BOM report'), placement: 'top' }" icon type="text" @click="setExporting(true)">
              <IconHawkDownloadTwo />
            </HawkButton>
          </div>
          <HawkSearchInput
            v-if="!is_editing_table"
            v-model="state.search"
            :placeholder="$t('Search')"
            :debounce_time="700"
            @update:modelValue="getProcurementItems()"
          />
        </div>
      </div>

      <div class="mt-5">
        <HawkLoader v-if="state.is_loading" />
        <template v-else-if="!procurement_items?.length">
          <HawkIllustrations v-if="state.search?.length || Object.keys(state.filters || {}).length" type="no-results" />
          <HawkIllustrations v-else type="no-data" for="inventory-bom" />
        </template>
        <div
          v-else-if="procurement_items?.length"
          class="bom-table w-auto rounded-[8px] border-x border-gray-100"
        >
          <TableWrapperVue container_class="">
            <HawkTable
              :data="procurement_items"
              :columns="columns"
              cell_height="40px"
              :show_menu_header="false"
              :is_pagination_enabled="false"
              is_gapless
              disable_resize
              @rowClicked="itemDetailsHandler($event)"
            >
              <template #index="{ data }">
                <div class="grid min-h-12 w-full place-content-center !px-2 text-gray-600" :class="{ 'opacity-50': is_editing_table }">
                  {{ data.row.index + 1 }}
                </div>
              </template>
              <template #name="{ data }">
                <div class="flex flex-col px-2 py-1 font-medium" :class="{ 'opacity-50': is_editing_table }">
                  <div class="mb-1 text-xs text-gray-600">
                    #{{ data.row.original.number }}
                  </div>
                  <div class="text-sm text-gray-900">
                    <HawkText :content="data.getValue()" :length="56" />
                  </div>
                </div>
              </template>
              <template #scope="{ data }">
                <div v-if="!state.is_editing || instances.length">
                  <span :class="{ 'opacity-50': is_editing_table }">
                    {{ state.is_editing ? totalQuantity(data.row.original) : data.getValue() }}
                    {{ data.row.original?.uom }}
                  </span>
                </div>
                <div v-else class="w-full" @click.stop>
                  <input
                    v-model="state.procurement_items_form[data.row.original.uid].scope"
                    type="number"
                    name=""
                    class="min-h-12 w-full bg-blue-50 px-2 text-sm outline-none"
                    @keydown="e => preventKeydown(e)"
                    @paste="e => pasteFields(e, data.row.index)"
                  >
                </div>
              </template>
              <template
                v-for="(instance, i) in instances"
                #[instance.instance_uid]="{ data }"
                :key="instance.instance_uid"
              >
                <div v-if="state.is_editing && (instance.instance_uid in (state.procurement_items_form[data.row.original.uid].instances || {}))" class="w-full" @click.stop>
                  <input
                    v-model="state.procurement_items_form[data.row.original.uid].instances[instance.instance_uid]"
                    type="number"
                    name=""
                    class="min-h-12 w-full bg-blue-50 px-2 text-sm outline-none"
                    @keydown="e => preventKeydown(e)"
                    @paste="e => pasteFields(e, data.row.index, i)"
                  >
                </div>
              </template>
              <template #item_status="{ data }">
                <span class="font-medium" :class="[{ 'text-red-700': data.getValue()?.status === -1 }, { 'opacity-50': state.is_editing }]">
                  {{ data.getValue()?.text || '-' }}
                </span>
              </template>
              <template #ordered_qty="{ data: { row: { original: { scope, ordered_qty, uid }, index } } }">
                <div v-if="!state.is_editing_ordered_qty" class="flex items-center gap-x-2">
                  <span>
                    {{ ordered_qty }}
                  </span>
                  <span v-if="ordered_qty < scope" v-tippy="{ content: `${scope - ordered_qty} ${$t('less than scope')}`, placement: 'top' }">
                    <IconHawkMinus class="h-4 w-4 rounded text-red-700 hover:bg-red-50" />
                  </span>
                  <span v-if="ordered_qty > scope" v-tippy="{ content: `${ordered_qty - scope} ${$t('more than scope')}`, placement: 'top' }">
                    <IconHawkPlus class="h-4 w-4 rounded text-warning-600 hover:bg-warning-50" />
                  </span>
                </div>
                <div v-else class="w-full" @click.stop>
                  <input
                    v-model="state.procurement_items_form[uid].ordered_qty"
                    type="number"
                    name=""
                    class="min-h-12 w-full bg-blue-50 px-2 text-sm outline-none"
                    @keydown="e => preventKeydown(e)"
                    @paste="e => pasteFields(e, index)"
                  >
                </div>
              </template>
            </HawkTable>
          </TableWrapperVue>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.bom-table {
  tr td:first-child{
    @apply px-0 w-8 bg-gray-50;
  }
  td {
    padding: 0px !important;
  }
}
</style>
