<script setup>
import { watch } from 'vue';
import { cloneDeep, isEqual } from 'lodash-es';
import { useModal } from 'vue-final-modal';

import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';

import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';
import DashboardFormListModal from '~/dashboard/components/dashboard-form-list-modal.vue';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const dashboard_forms_store = useDashboardFormsStore();

const $services = inject('$services');
const $t = inject('$t');
const graph_data = ref(null);
const loading = ref(false);
const payload = ref(null);
const form_error = ref(false);

const { parseRulesDateData } = useFamConstants();

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  let type;
  switch (props.data?.data?.type) {
    case 'asset_breakdown':
      type = 'stackedbar2d';
      break;

    case 'assignee_breakdown':
      type = 'stackedcolumn2d';
      break;
    case 'vertical_graph':
      type = props.data.data.cumulative ? 'stackedcolumn2dlinedy' : 'stackedcolumn2d';
      break;
    case 'horizontal_bar':
      type = 'stackedbar2d';
      break;

    case 'donut':
    case 'status_breakdown':
      type = 'doughnut2d';
      break;
    case 'step_history':
      type = 'stackedbar2d';
      break;
    case 'line':
      type = !payload.value?.group?.key ? 'line' : 'msline';
      break;
    case 'area':
      type = !payload.value?.group?.key ? 'area2d' : 'msarea';
      break;
  }
  if (chart_config.value?.chart_display_mode === 'Unstacked' && type === 'stackedcolumn2d')
    return 'mscolumn2d';
  if (chart_config.value?.chart_display_mode === 'Unstacked' && type === 'stackedbar2d')
    return 'msbar2d';
  return type;
});

const forms_list_modal = useModal({
  component: DashboardFormListModal,
});

function x_axis_name() {
  if (chart_config.value?.x_label)
    return chart_config.value.x_label;
  else
    if (payload.value?.x?.label)
      return payload.value?.x?.label;
  if (props.data.data.type === 'asset_breakdown')
    return 'Asset';
  if (props.data.data.type === 'assignee_breakdown')
    return 'Assignee';
  if (props.data.data.type === 'status_breakdown')
    return 'Status';
  if (props.data.data.type === 'step_history')
    return 'Steps';
  else return 'No. of forms';
}
function y_axis_name() {
  if (chart_config.value?.y_label) {
    return chart_config.value.y_label;
  }
  else {
    if (payload.value?.y?.label)
      return payload.value?.y?.label;
    if (['status_breakdown', 'assignee_breakdown', 'asset_breakdown', 'step_history'].includes(props.data.data.type))
      return 'No. of forms';
    else return '';
  }
}

const group_key = computed(() => {
  if (props.data?.data?.type === 'status_breakdown' || props.data?.data?.type === 'donut')
    return null;
  if (props.data?.data?.type === 'asset_breakdown' || props.data?.data?.type === 'assignee_breakdown')
    return 'status';
  else if (payload.value?.group?.label)
    return payload.value?.group?.label;
  else return props.data?.data?.breakdown?.key;
});

const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    ...graph_data.value,
    chart: {
      xAxisName: x_axis_name(),
      yAxisName: y_axis_name(),
      subCaption: chart_config.value?.description
      || props?.data?.data?.subCaption || '',
      ...graph_data?.value?.chart,
    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.type,
  },
  events: {
    dataPlotClick: (e) => {
      window.getForms = (query) => {
        const count = e?.data.value || 0;
        const popup_query = JSON.parse(atob(query));

        forms_list_modal.patchOptions({
          attrs: {
            id: props.id,
            name: `${props.data?.data?.name} `,
            count,
            payload: payload.value,
            popup_query,
            onClose() {
              forms_list_modal.close();
            },
          },
        });
        forms_list_modal.open();
      };
    },
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_forms_store.parse_forms_form_to_server_format(props.data.data);
  const forms_payload = cloneDeep(payload.value);

  if (forms_payload?.filters?.advanced_filter)
    forms_payload.filters.advanced_filter = [forms_payload.filters.advanced_filter, ...cloneDeep(dashboard_forms_store.forms_v2_filters)];
  else
    forms_payload.filters.advanced_filter = cloneDeep(dashboard_forms_store.forms_v2_filters);

  forms_payload.filters.advanced_filter = forms_payload.filters.advanced_filter.map((filter) => {
    filter.rules = parseRulesDateData(filter.rules);
    return filter;
  });

  try {
    const { data } = await $services.forms.get_graph({ body: forms_payload });
    graph_data.value = data;
    form_error.value = null;
    loading.value = false;
  }
  catch (err) {
    form_error.value = err?.response?.status === 400 ? $t('Template is not published') : $t('Template not found');
    loading.value = false;
  }
}

if (props.id === 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if ((!isEqual(new_val, old_val)))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });

if (props.id !== 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>

    <hawk-loader v-if="loading" />
    <GraphComponent
      v-else-if="!form_error && graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config"
      :group_key="group_key"
    />
    <div
      v-if="!loading && form_error"
      class="h-[calc(100%-80px)] w-full flex items-center justify-center"
    >
      <div class="text-sm">
        {{ form_error }}
      </div>
    </div>
  </div>
</template>
