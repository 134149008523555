import { includes, isEqual, isNil } from 'lodash-es';
import { Engine, Rule } from 'json-rules-engine';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);
export function useJsonRuleEngine() {
  const engine = new Engine([], { allowUndefinedFacts: true });

  const getFormattedDate = (value, format = 'YYYY-MM-DDTHH:mm:ss.SSSZ') => {
    const is_only_time_obj = !(isNil(value.hours) && isNil(value.minutes));

    let dayjs_value = value;
    if (is_only_time_obj)
      dayjs_value = `${value.hours}:${value.minutes}`;
    if (value instanceof Date)
      dayjs_value = dayjs(value);

    const formats = [
      'YYYY-MM-DDTHH:mm:ss.SSSZ',
      'DD-MM-YYYY HH:mm',
      'DD-MM-YYYY',
      'YYYY-MM-DD HH:mm',
      'YYYY-MM-DD',
      'HH:mm',
      'H:m',
      // Add more formats as needed
    ];

    for (const input_format of formats) {
      const parsedDate = dayjs(dayjs_value, input_format);
      if (parsedDate.isValid())
        return input_format.includes('HH:mm') ? parsedDate : parsedDate.format(format);
    }
  };

  function earlierThanOperator(factValue, jsonValue) {
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    const factDate = getFormattedDate(factValue);
    const jsonDate = getFormattedDate(jsonValue);

    return dayjs(factDate).isBefore(jsonDate);
  };

  function laterThanOperator(factValue, jsonValue) {
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    const factDate = getFormattedDate(factValue);
    const jsonDate = getFormattedDate(jsonValue);

    return dayjs(factDate).isAfter(jsonDate);
  }

  function betweenOperator(factValue, jsonValue) {
    if (isNil(factValue) || isNil(jsonValue))
      return false;
    const is_date = Number.isNaN(Number(factValue)) || (factValue instanceof Date);
    if (is_date) {
      const factDate = getFormattedDate(factValue);
      const jsonDateStart = getFormattedDate(jsonValue[0]);
      const jsonDateEnd = getFormattedDate(jsonValue[1]);

      return dayjs(factDate).isBetween(jsonDateStart, jsonDateEnd, null, '[[');
    }
    else {
      const num_val = Number(factValue);
      const min_num = Number(jsonValue[0]);
      const max_num = Number(jsonValue[1]);

      return num_val >= min_num && num_val <= max_num;
    }
  }

  function notBetweenOperator(factValue, jsonValue) {
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    const factDate = getFormattedDate(factValue);
    const jsonDateStart = getFormattedDate(jsonValue[0]);
    const jsonDateEnd = getFormattedDate(jsonValue[1]);

    return !dayjs(factDate).isBetween(jsonDateStart, jsonDateEnd, null, '[[');
  }

  function stringContainsOperator(factValue, jsonValue, is_case_sensitive) {
    if (factValue && jsonValue)
      return is_case_sensitive ? factValue.includes(jsonValue) : factValue.toLowerCase().includes(jsonValue.toLowerCase());
    return false;
  }

  function doesNotContainOperator(factValue, jsonValue, is_case_sensitive) {
    if (Array.isArray(factValue) && !factValue.length)
      return false;
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    return is_case_sensitive ? !factValue?.includes(jsonValue) : !(factValue.toLowerCase().includes(jsonValue.toLowerCase()));
  }

  function startsWithOperator(factValue, jsonValue, is_case_sensitive) {
    if (factValue && jsonValue)
      return is_case_sensitive ? factValue.startsWith(jsonValue) : factValue.toLowerCase().startsWith(jsonValue.toLowerCase());
    return false;
  }

  function endsWithOperator(factValue, jsonValue, is_case_sensitive) {
    if (factValue && jsonValue)
      return is_case_sensitive ? factValue.endsWith(jsonValue) : factValue.toLowerCase().endsWith(jsonValue.toLowerCase());
    return false;
  }

  function numberEqualsOperator(factValue, jsonValue) {
    if (factValue === '' || isNil(factValue) || isNil(jsonValue))
      return false;

    return Number(factValue) === Number(jsonValue);
  }

  function numberNotEqualsOperator(factValue, jsonValue) {
    if (factValue === '' || isNil(factValue) || isNil(jsonValue))
      return false;

    return Number(factValue) !== Number(jsonValue);
  }

  function arrayEqualsOperator(factValue, jsonValue) {
    if (Array.isArray(factValue) && !factValue.length)
      return false;
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    if (Array.isArray(factValue)) {
      const compareVal = Array.isArray(jsonValue) ? jsonValue : [jsonValue];

      return factValue.some((val) => {
        const isExists = compareVal.find(cmp_val => cmp_val === val);
        return Boolean(isExists);
      });
    }

    return includes(jsonValue, factValue);
  }

  function arrayNotEqualsOperator(factValue, jsonValue) {
    if (Array.isArray(factValue) && !factValue.length)
      return false;
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    if (Array.isArray(factValue)) {
      const compareVal = Array.isArray(jsonValue) ? jsonValue : [jsonValue];

      return factValue.every((val) => {
        const isExists = compareVal.find(cmp_val => cmp_val === val);
        return !(isExists);
      });
    }

    return !includes(jsonValue, factValue);
  }

  function arrayInOperator(factValue, jsonValue) {
    if (factValue && jsonValue)
      return factValue.some(item => jsonValue.includes(item));
    else return false;
  }

  function arrayNotInOperator(factValue, jsonValue) {
    if (factValue?.length && jsonValue)
      return factValue.every(item => !jsonValue.includes(item));
    else return false;
  }

  function equalOperator(factValue, jsonValue) {
    if (Array.isArray(factValue) && !factValue.length)
      return false;
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    if (Array.isArray(factValue))
      return isEqual(factValue, Array.isArray(jsonValue) ? jsonValue : [jsonValue]);

    return factValue === jsonValue;
  }

  function notEqualOperator(factValue, jsonValue) {
    if (Array.isArray(factValue) && !factValue.length)
      return false;
    if (isNil(factValue) || isNil(jsonValue))
      return false;

    if (Array.isArray(factValue))
      return !isEqual(factValue, Array.isArray(jsonValue) ? jsonValue : [jsonValue]);

    return factValue !== jsonValue;
  }

  function loadJsonEngine(options = {}) {
    const { is_case_sensitive = false } = options;

    engine.addOperator('equal', equalOperator);
    engine.addOperator('isEqualTo', equalOperator);
    engine.addOperator('notEqual', notEqualOperator);
    engine.addOperator('earlierThan', earlierThanOperator);
    engine.addOperator('laterThan', laterThanOperator);
    engine.addOperator('between', betweenOperator);
    engine.addOperator('isBetween', betweenOperator);
    engine.addOperator('notBetween', notBetweenOperator);
    engine.addOperator('stringContains', (factValue, jsonValue) => stringContainsOperator(factValue, jsonValue, is_case_sensitive));
    engine.addOperator('contains', (factValue, jsonValue) => stringContainsOperator(factValue, jsonValue, is_case_sensitive));
    engine.addOperator('stringDoesNotContains', (factValue, jsonValue) => doesNotContainOperator(factValue, jsonValue, is_case_sensitive));
    engine.addOperator('doesNotContain', (factValue, jsonValue) => doesNotContainOperator(factValue, jsonValue, is_case_sensitive));
    engine.addOperator('startsWith', (factValue, jsonValue) => startsWithOperator(factValue, jsonValue, is_case_sensitive));
    engine.addOperator('endsWith', (factValue, jsonValue) => endsWithOperator(factValue, jsonValue, is_case_sensitive));
    engine.addOperator('numberEquals', numberEqualsOperator);
    engine.addOperator('numberNotEquals', numberNotEqualsOperator);
    engine.addOperator('arrayEquals', arrayEqualsOperator);
    engine.addOperator('isAnyOf', arrayEqualsOperator);
    engine.addOperator('arrayNotEquals', arrayNotEqualsOperator);
    engine.addOperator('arrayIn', arrayInOperator);
    engine.addOperator('containsAnyOf', arrayInOperator);
    engine.addOperator('arrayNotIn', arrayNotInOperator);

    return engine;
  }
  async function runJsonEngine(rules = {}, facts_hash = {}, data = [], events = null) {
    const json_rules = new Rule({
      conditions: rules,
      event: events ?? {
        type: 'filters',
        params: {
          message: 'Filter passed',
        },
      },
    });
    const filtered_data = [];
    for (const item of data) {
      engine.addRule(json_rules);
      const { results } = await engine.run(facts_hash[item.uid]);
      engine.removeRule(json_rules);
      if (results.length)
        filtered_data.push(item);
    }
    return filtered_data;
  }

  return {
    loadJsonEngine,
    stringContainsOperator,
    startsWithOperator,
    endsWithOperator,
    numberEqualsOperator,
    numberNotEqualsOperator,
    equalOperator,
    notEqualOperator,
    arrayEqualsOperator,
    arrayNotEqualsOperator,
    doesNotContainOperator,
    arrayInOperator,
    arrayNotInOperator,
    runJsonEngine,
  };
}
