<script setup>
import { MenuItem } from '@headlessui/vue';
import { $date } from '~/common/utils/date.util';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  active_range: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['filterApplied']);

const { ACCOUNT_SETTING_DATE_FILTERS, getFormattedDate } = useFamConstants();

const form_data = ref(null);

const show_dropdown = ref(false);

const date_range = ref(null);
const custom_date_range = ref(null);

const active_date_range = computed(() => {
  if (date_range.value?.value === 'custom')
    return custom_date_range.value;
  else if (date_range.value?.get_value)
    return date_range.value.get_value();
  return [];
});

function handleDateRangeSelection(e) {
  custom_date_range.value = e.value === 'custom' ? form_data.value.date_range : null;
  date_range.value = e;

  const payload = { value: e.value, active_range: active_date_range.value };
  emit('filterApplied', payload);
}

onMounted(() => {
  if (props.active_range?.value) {
    const date_range = ACCOUNT_SETTING_DATE_FILTERS.find(filter => filter.value === props.active_range.value);
    handleDateRangeSelection(date_range);
  }
});
</script>

<template>
  <hawk-menu
    position="bottom"
    additional_trigger_classes="!ring-0 w-full"
    @open="show_dropdown = true"
    @close="show_dropdown = false"
  >
    <template #trigger>
      <div class="grid grid-cols-12 border border-gray-300 rounded-lg w-[350px] text-sm">
        <div class="col-span-2 py-2">
          {{ date_range?.prefix }}
        </div>
        <div class="col-span-9 border-l border-gray-300 py-2">
          {{ $date(active_date_range[0], 'MMM DD, hh:mm A') }} - {{ $date(active_date_range[1], 'MMM DD, hh:mm A') }}
        </div>
        <div class="col-span-1 py-2">
          <IconHawkChevronDown
            class="transition-transform"
            :class="{ 'rotate-180 !visible': show_dropdown }"
          />
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <MenuItem
        v-for="date_filter in ACCOUNT_SETTING_DATE_FILTERS"
        v-slot="{ active }"
        :key="date_filter.value"
        as="div"
        class="px-2 w-[350px]"
      >
        <hawk-menu
          v-if="date_filter.value === 'custom'"
          additional_dropdown_classes="right-full !top-0 mr-1 !mt-0 !bottom-auto"
          additional_trigger_classes="!ring-0 w-full"
          position="bottom-left"
        >
          <template #trigger>
            <div class="p-3 rounded-md text-sm text-gray-700 font-medium hover:bg-gray-50">
              <div class="grid grid-cols-12">
                <div class="col-span-2 text-left">
                  {{ date_filter.prefix }}
                </div>
                <div>
                  {{ date_filter.label }}
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <div class="p-3 w-[390px]" @click.stop>
              <Vueform v-model="form_data" sync size="sm">
                <div class="col col-span-12">
                  <div class="font-medium text-sm mb-2">
                    {{ $t('Date Range') }}
                  </div>
                  <DateTimeElement
                    name="date_range"
                    class="w-full"
                    :options="{
                      placeholder: 'Select date range',
                      clearable: false,
                      enableTimePicker: true,
                      range: true,
                      teleport: false,
                      format: 'MMM dd, hh:mm aa',
                    }"
                  />
                </div>
              </Vueform>
            </div>
          </template>
          <template #footer="{ close: closeDateRangePicker }">
            <Vueform size="sm" class="p-2">
              <div class="flex justify-end w-full col-span-full">
                <ButtonElement
                  name="cancel"
                  :secondary="true"
                  @click="close()"
                >
                  {{ $t('Cancel') }}
                </ButtonElement>
                <ButtonElement
                  name="apply"
                  class="ml-3"
                  :disabled="!form_data?.date_range?.length"
                  @click="handleDateRangeSelection(date_filter);closeDateRangePicker();close();"
                >
                  {{ $t('Apply') }}
                </ButtonElement>
              </div>
            </Vueform>
          </template>
        </hawk-menu>
        <div
          v-else
          :class="{ 'bg-gray-50': active, 'bg-gray-100': date_filter.value === date_range?.value }"
          class="flex items-center justify-between whitespace-nowrap cursor-pointer p-3 rounded-md text-sm text-gray-700 font-medium"
          @click="handleDateRangeSelection(date_filter)"
        >
          <div class="grid grid-cols-12 text-sm font-medium">
            <div class="col-span-2">
              {{ date_filter.prefix }}
            </div>
            <div class="whitespace-nowrap">
              {{ date_filter.label }}
            </div>
          </div>
        </div>
      </MenuItem>
    </template>
  </hawk-menu>
</template>
