<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { cloneDeep, isEqual } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import NumberChartComponent from '~/dashboard/components/widgets/common-widgets/number-chart-component.vue';
import DashboardFormListModal from '~/dashboard/components/dashboard-form-list-modal.vue';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
});
const route = useRoute();
const dashboard_store = useDashboardStore();
const dashboard_forms_store = useDashboardFormsStore();

const { parseRulesDateData } = useFamConstants();

const $services = inject('$services');
const $t = inject('$t');

const payload = ref(null);
const response_data = ref(null);
const is_loading = ref(false);
const form_error = ref(null);

const {
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);

const forms_list_modal = useModal({
  component: DashboardFormListModal,
});

async function getIndices() {
  is_loading.value = true;
  payload.value = dashboard_forms_store.parse_forms_form_to_server_format(props.data.data);

  const forms_payload = cloneDeep(payload.value);

  const filters = forms_payload.filters.advanced_filter && Object.keys(forms_payload.filters.advanced_filter).length ? [forms_payload.filters.advanced_filter, ...cloneDeep(forms_v2_filters.value) || []] : cloneDeep(forms_v2_filters.value);

  forms_payload.filters.asset_uid = route.params.asset_id || null;

  forms_payload.filters = { ...forms_payload.filters, advanced_filter: filters };

  forms_payload.filters.advanced_filter = forms_payload.filters.advanced_filter.map((filter) => {
    filter.rules = parseRulesDateData(filter.rules);
    return filter;
  });
  try {
    const { data } = await $services.forms.get_graph({ body: forms_payload });
    response_data.value = data;
    is_loading.value = false;
    form_error.value = null;
  }
  catch (err) {
    form_error.value = err?.response?.status === 400 ? $t('Template is not published') : $t('Template not found');
    is_loading.value = false;
  }
}

const number_chart_data = computed(() => ({
  timerange: {
    interval: props.data.data.timerange_interval,
    range: props.data.data.timerange_range,
    type: props.data.data.timerange_type,
  },
  displayPreviousValue: props.data.data.display_previous_value,
  ...response_data.value,
  dashboard_index: props.data.i,
  prettify_values: props.data.data.prettify_values,
  inverse_trend: props.data.data.inverse_trend,
}));

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    renderType: 'number-chart',
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      ...number_chart_data.value,
    },
  });
}

function openDrilldownPopup(link) {
  if (!link)
    return;

  const popup_query = JSON.parse(atob(link));

  forms_list_modal.patchOptions({
    attrs: {
      id: props.id,
      name: props.data?.data?.name || 'Untitled',
      payload: payload.value,
      popup_query,
      onClose() {
        forms_list_modal.close();
      },
    },
  });

  forms_list_modal.open();
}

watch(() => props.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    await getIndices();
    if (props.id !== 'preview')
      updatePrintMap();
  }
}, { deep: true });

onMounted(() => {
  setTimeout(async () => {
    await getIndices();
    if (props.id !== 'preview')
      updatePrintMap();
  }, 100);
});
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <HawkLoader v-if="is_loading" container_class="m-1" />
    <div
      v-else-if="!is_loading && form_error"
      class="h-[calc(100%-80px)] w-full flex items-center justify-center"
    >
      <div class="text-sm">
        {{ form_error }}
      </div>
    </div>
    <NumberChartComponent
      v-else
      :data="number_chart_data"
      @open-drilldown="openDrilldownPopup($event)"
    />
  </div>
</template>
