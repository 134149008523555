<script setup>
// --------------------------------- Imports -------------------------------- //
import { onMounted } from 'vue';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  quick_filters: {
    type: Array,
    default: () => (['current_user_access_filters', 'templates', 'priority', 'category', 'assignees']),
  },
});
// ---------------------------------- Emits --------------------------------- //
const emits = defineEmits(['search', 'apply']);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const form_templates_store = useFormTemplatesStore();
const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const date_filter_operators = [
  'isEqualTo',
  'isNotEqualTo',
  'isAfter',
  'isBefore',
  'between',
  'isNotBetween',
  'blankNotBlank',
];

const current_user_access_filters = [
  {
    label: $t('All forms'),
    value: null,
  },
  {
    label: $t('Assigned to me'),
    value: 'assigned',
  },
  {
    label: $t('Created by me'),
    value: 'owner',
  },
  {
    label: $t('Shared with me'),
    value: 'shared',
  },
];

// ------------------------ Variables - Local - refs ------------------------ //
const search = ref('');
const existing_filters = ref([]);
const selected_filter_option = ref(current_user_access_filters[0]);
const template_dropdown_options = ref([]);

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const display_filters = computed(() =>
  [
    {
      uid: 'templates',
      name: $t('Templates'),
      data_type: 'multi_select',
      is_field: false,
      option_type: 'templates',
      operators: [
        'isAnyOf',
        'isNot',
      ],
      options: template_dropdown_options.value,
      type: 'dropdown',
    },
    {
      uid: 'due_date',
      data_type: 'date',
      type: 'date',
      name: $t('Due date'),
      option_type: null,
      is_static: true,
      options: [],
      operators: date_filter_operators,
    },
    {
      uid: 'approval_due_date',
      name: $t('Due date'),
      data_type: 'date',
      is_static: true,
      option_type: null,
      operators: date_filter_operators,
      options: [],
      type: 'date',
    },
    {
      uid: 'reviewed_date',
      name: $t('Reviewed on'),
      data_type: 'date',
      option_type: null,
      operators: date_filter_operators,
      is_static: true,
      type: 'date',
      options: [],
    },
    {
      uid: 'priority',
      name: $t('Priority'),
      data_type: 'single_select',
      is_field: false,
      option_type: null,
      operators: [
        'isAnyOf',
        'isNot',
        'blankNotBlank',
      ],
      options: [
        {
          uid: null,
          name: $t('Not set'),
        },
        {
          uid: 'low',
          name: $t('Low'),
        },
        {
          uid: 'medium',
          name: $t('Medium'),
        },
        {
          uid: 'high',
          name: $t('High'),
        },
        {
          uid: 'critical',
          name: $t('Critical'),
        },
      ],
      type: 'dropdown',
    },
    {
      uid: 'category',
      name: $t('Category'),
      data_type: 'single_select',
      is_field: false,
      option_type: 'categories',
      operators: [
        'isAnyOf',
        'isNot',
        'blankNotBlank',
      ],
      options: [],
      type: 'dropdown',
    },
    {
      uid: 'assignees',
      name: $t('Assignees'),
      data_type: 'multi_select',
      is_field: false,
      option_type: 'members',
      operators: [
        'containsAnyOf',
        'containsAllOf',
        'doesNotContain',
        'equalsExactly',
        'blankNotBlank',
        'count',
      ],
      options: [],
      type: 'members',
    },
  ]);
// -------------------------------- Functions ------------------------------- //

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  if (props.quick_filters.includes('templates')) {
    const { data } = await $services.forms.getAll({
      attribute: 'templates/list',
      query: {
        status: 'published',
      },
    });
    template_dropdown_options.value = data.templates;
  }
});
</script>

<template>
  <div class="mb-4 flex justify-between">
    <div class="flex flex-wrap items-center gap-2">
      <HawkMenu
        v-if="quick_filters.includes('current_user_access_filters')" :items="current_user_access_filters"
        @select="(val) => { selected_filter_option = val; emits('apply', { user_filter: val, filters: existing_filters }) }"
      >
        <template #trigger>
          <HawkButton type="outlined" size="xs">
            <span class="font-semibold">{{ selected_filter_option.label }}</span>
            <icon-hawk-chevron-down class="text-gray-600" />
          </HawkButton>
        </template>
      </HawkMenu>
      <HawkDisplayFilters
        :display_filters="display_filters.filter(display_filter => quick_filters.includes(display_filter.uid))"
        @apply="(filters) => { existing_filters = filters; emits('apply', { user_filter: selected_filter_option, filters }) }"
      />
    </div>
    <HawkSearchInput
      v-model="search" :placeholder="$t('Search')" class="flex-shrink-0"
      @update:modelValue="emits('search', $event)"
    />
  </div>
</template>
