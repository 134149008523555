<script setup>
import HawkAssetName from '~/common/components/atoms/hawk-asset-name.vue';
import InventoryVendorName from '~/inventory/components/inventory-reports/inventory-vendor-name.vue';
import InventoryWarehouseName from '~/inventory/components/inventory-reports/inventory-warehouse-name.vue';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  truncate: {
    type: Boolean,
    default: false,
  },
  active_classes: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <HawkAssetName v-if="type === 'asset'" :uid="props.uid" :truncate="truncate" :active_classes="active_classes" />
  <InventoryWarehouseName v-if="type === 'warehouse'" :uid="props.uid" :truncate="truncate" :active_classes="active_classes" />
  <InventoryVendorName v-if="type === 'vendor'" :uid="props.uid" :truncate="truncate" :active_classes="active_classes" />
</template>
