<script setup>
import { ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';
import SettingModal from '~/forms/components/settings/form-settings-modal.vue';
import DocumentGenerateTrigger from '~/forms/components/form-workflows/sidebar/document-generate-block/document-generate-trigger.vue';
import FormTemplateIcon from '~/forms/atoms/form-template-icon.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, auth_store, route, router } = useCommonImports();

const form_template_detail_store = useFormTemplateDetailStore();
const forms$ = ref(null);
const formData = ref(null);
const state = reactive({
  document: {},
  columns: { lg: { container: 12, wrapper: 12, label: 12 } },
});
const selected_icon_id = ref(null);
const template_icon = computed({
  // getter
  get() {
    return selected_icon_id.value;
  },
  // setter
  set(newValue) {
    selected_icon_id.value = newValue;
  },
});
template_icon.value = form_template_detail_store?.form_template_detail?.icon;

async function onSave() {
  const data = {
    ...formData.value,
    export_report_template: {
      ...(formData.value.export_report_template || {}),
      document: state.document,
    },
    icon: selected_icon_id.value,
  };
  const updated_properties = [];
  const template = form_template_detail_store.form_template_detail;
  if (template.name !== formData.value.name)
    updated_properties.push('Name');
  if (template.description !== formData.value.description)
    updated_properties.push('Description');
  if (template.category !== formData.value.category)
    updated_properties.push('Category');

  await form_template_detail_store.update_form_details({ body: data });

  updated_properties.forEach((property) => {
    form_template_detail_store.form_template_track_events(`${property} updated`);
  });
}

function saveIcon(icon, callback) {
  template_icon.value = icon;
  callback();
}

const { open, close } = useModal({
  component: SettingModal,
  attrs: {
    onClose() {
      close();
    },
    save($event) {
      saveIcon($event, close);
    },
    icon: template_icon,
  },
});

function setCreatedCategory(e) {
  forms$.value.elements$.category.load(e.uid);
}
function onUpdate() {
  const export_report_template_el$ = forms$.value?.form$?.el$('export_report_template');
  const document_el$ = export_report_template_el$?.children$?.document;
  const { data, formatData } = document_el$;
  state.document = formatData('document', data.document).document;
}
function trimName(name, value) {
  formData.value[name] = value?.trim();
  return ({ [name]: value?.trim() });
}

function initTemplateDetails() {
  const { category, description, days_required, icon, export_report_template, name, plural_name, singular_name } = form_template_detail_store?.form_template_detail ?? {};
  const general_data = {
    category,
    description,
    days_required,
    name,
    plural_name,
    singular_name,
    export_report_template: export_report_template || { document: null, schema: null },
  };
  formData.value = general_data;
  state.document = general_data.export_report_template.document;
  template_icon.value = icon;
};
initTemplateDetails();
</script>

<template>
  <div>
    <div class="text-left pb-6">
      <h2 class="mt-6 text-lg font-semibold tracking-tight">
        {{ $t('General') }}
      </h2>
      <span class="mt-6 text-gray-600 text-xs"> {{ $t('General settings') }}</span>
    </div>
    <Vueform
      ref="forms$"
      v-model="formData"
      size="sm" :float-placeholders="false"
      sync
      :display-errors="false" :endpoint="onSave"
      @change="onUpdate"
      @updated="onUpdate"
    >
      <div class="col-span-12 lg:col-span-6 md:col-span-12 sm:col-span-12">
        <StaticElement class="mb-4" label="Template Icon" :columns="{ lg: { container: 12, wrapper: 12, label: 6 } }">
          <div class="flex gap-4 items-center">
            <span class="flex items-center gap-2 border border-gray-300 rounded-md p-2 cursor-pointer" @click="open">
              <div v-if="!selected_icon_id" class="h-10 w-10 bg-gray-200 rounded-md" />
              <FormTemplateIcon
                v-else
                :icon_id="selected_icon_id"
                class="h-10 w-10 p-1"
              />
              <div class="text-sm text-gray-700 font-semibold">{{ $t('Choose Icon') }}</div>
            </span>
            <HawkButton v-if="template_icon !== null" type="link" color="error" @click="template_icon = null">
              {{ $t('Remove') }}
            </HawkButton>
          </div>
        </StaticElement>
        <TextElement
          name="name"
          :label="$t('Template name')"
          class="mb-4"
          :focused="false"
          :placeholder="`${$t('Enter the template name')}`"
          :format-data="trimName"
        />
        <TextElement
          name="singular_name"
          :label="$t('Singular name')"
          class="mb-4"
          :focused="false"
          :placeholder="`${$t('Enter the singular name')}`"
          description="E.g. Purchase order"
          :format-data="trimName"
          :rules="['max:24']"
        />
        <TextElement
          name="plural_name"
          :label="$t('Plural name')"
          class="mb-4"
          :focused="false"
          :placeholder="`${$t('Enter the plural name')}`"
          description="E.g. Purchase orders"
          :format-data="trimName"
          :rules="['max:24']"
        />
        <hawk-category-input
          class="mb-4"
          :options="{
            name: 'category',
            label: $t('Category'),
            placeholder: $t('Select Category'),
            create: true,
          }"
          :multi="false"
          :setter_function="setCreatedCategory"
        />
        <TextareaElement
          class="mb-4"
          :label="$t('Description')"
          name="description" placeholder="Enter description"
        />
        <TextElement
          class="mb-6"
          :rules="[
            'numeric',
            'min:1',
            'nullable',
          ]"
          :attrs="{ min: '1' }"
          :info="$t('The due date will be automatically set from the date of creation')"
          name="days_required"
          :label="$t('Number of days to finish the form')"
          input-type="number"
          :placeholder="`${$t('Enter number of days')}`"
          :addons="{ after: 'days' }"
        />

        <div class="mb-4">
          <div class="text-lg font-semibold">
            {{ $t('Report configuration') }}
          </div>
          <div class="text-gray-600 text-xs">
            {{ $t('Export form data as a PDF using a custom layout/structure.') }}
          </div>
        </div>

        <ObjectElement name="export_report_template" :columns="state.columns">
          <DocumentGenerateTrigger
            is_settings
            :form_data="{
              ...(forms$?.data?.export_report_template || {}),
              document: state.document,
            }"
          />
        </ObjectElement>
      </div>
      <div class="col-span-12 sticky bottom-0 bg-white">
        <StaticElement :columns="state.columns">
          <hr style="border-color: #d1d5db; margin-top: 8px; padding-bottom: 8px;">
          <div class="flex justify-end text-sm font-medium mt-1 bt-2">
            <ButtonElement
              :columns="state.columns"
              name="cancel" :button-label="$t('Cancel')" class="mr-3" :secondary="true"
              @click="router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-detail', params: { template_uid: route.params.template_uid } })"
            />
            <ButtonElement
              :columns="state.columns" name="save" :button-label="$t('Save')" :submits="true"
            />
          </div>
        </StaticElement>
      </div>
    </Vueform>
  </div>
</template>
