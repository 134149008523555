<script setup>
import Cookies from 'universal-cookie';
import HawkMenu from '~/common/components/molecules/hawk-menu.vue';
import { useI18nStore } from '~/common/stores/i18n.store';

const $t = inject('$t');

const cookies = new Cookies();
const { set_language } = useI18nStore();

const languages = [
  { label: 'EN - English', uid: 'en', on_click: () => { set_language('en'); } },
  { label: 'JA - 日本語', uid: 'ja', on_click: () => { set_language('ja'); } },
  { label: 'ES - Español', uid: 'es', on_click: () => { set_language('es'); } },
  { label: 'PT - português', uid: 'pt', on_click: () => { set_language('pt'); } },
  { label: 'DE - German', uid: 'de', on_click: () => { set_language('de'); } },
];
</script>

<template>
  <HawkMenu :items="languages" additional_trigger_classes="!ring-0">
    <template #trigger>
      <hawk-button v-tippy="{ content: $t('Switch language'), placement: 'bottom' }" icon type="text" size="md" class="flex hover:bg-gray-900">
        <span
          class="text-sm uppercase text-white"
        >
          {{ cookies.get('lang') || 'en' }}
        </span>
      </hawk-button>
    </template>
  </HawkMenu>
</template>
