<script setup>
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useDashboardInventoryStore } from '~/dashboard/store/dashboard-inventory.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import DashboardInventoryFields from '~/dashboard/components/inventory-fields/dashboard-inventory-fields.vue';

const $services = inject('$services');

const dashboard_inventory_store = useDashboardInventoryStore();
const dashboard_store = useDashboardStore();

const {
  inventory_schema_1,
  inventory_schema_2,
  inventory_configuration,
  inventory_widget_type,
  inventory_track_stock_uid,
  track_stock_field_options,
} = storeToRefs(dashboard_inventory_store);

const {
  update_inventory_configuration,
  set_data,
  get_items_with_serial_number_tracking_enabled,
} = dashboard_inventory_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;

const form$_1 = ref(null);
const form$_2 = ref(null);

const fields = ref([]);
const transaction_date = ref(null);
const is_loading = ref(false);

const default_fields = computed(() => {
  if (inventory_widget_type.value === 'material_tracking')
    return [
      { label: 'Name', name: 'name', uid: 'name', status: 'checked', level: 1, type: 'item' },
      { label: 'Number', name: 'number', uid: 'number', status: 'checked', level: 1, type: 'item' },
    ];
  return [];
});

function updateInventoryConfiguration(data, key = null) {
  let copy = {
    ...inventory_configuration.value,
    ...(fields.value.length && { fields: fields.value }),
    ...(inventory_configuration.value.properties && { properties: inventory_configuration.value.properties }),
  };
  if (!key) {
    copy = {
      ...form$_1.value.data,
      ...form$_2.value.data,
      ...(fields.value.length && { fields: fields.value }),
      ...(inventory_configuration.value.properties && { properties: inventory_configuration.value.properties }),
      columns_widths: inventory_configuration.value.columns_widths,
    };
    if (!copy.track_stock_quantities) {
      delete copy.track_stock_uid;
      delete copy.track_stock_timerange_type;
      delete copy.track_stock_timerange_interval;
    }
  }
  else if (key === 'fields') {
    fields.value = data;
    copy[key] = data;
  }
  update_inventory_configuration(copy);

  validateForm();
}

async function validateForm() {
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$_1.value.validate();
  form$_2.value.validate();
  const is_valid = !form$_1.value.hasErrors && !form$_2.value.hasErrors;
  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...inventory_configuration.value,
    });
}

watch(widget_asset, (new_val, old_val) => {
  if (!isEqual(new_val, old_val))
    updateInventoryConfiguration(widget_asset.value, 'asset_id');
});

watch(inventory_widget_type, (_newVal, _oldVal) => {
  fields.value = default_fields.value;
});

watch(inventory_track_stock_uid, async (_newVal, _oldVal) => {
  form$_2.value.el$('track_stock_field')?.update(inventory_configuration.value.track_stock_field || track_stock_field_options.value[0]?.value || null);
});

onMounted(async () => {
  is_loading.value = true;
  await get_items_with_serial_number_tracking_enabled();
  set_data();
  validateForm();
  fields.value = inventory_configuration.value?.fields || default_fields.value;
  is_loading.value = false;
});

onBeforeUnmount(() => {
  dashboard_inventory_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <hawk-loader v-if="is_loading" />
  <div v-show="!is_loading">
    <Vueform
      ref="form$_1"
      size="sm"
      :schema="inventory_schema_1"
      :presets="['supress_errors']"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :should_validate_on_mount="false"
      :display-errors="false"
      class="my-2"
      @change="updateInventoryConfiguration($event)"
    />
    <DashboardInventoryFields
      v-if="inventory_configuration.type === 'material_tracking'"
      @change="updateInventoryConfiguration($event, 'fields')"
    />
    <Vueform
      ref="form$_2"
      size="sm"
      :schema="inventory_schema_2"
      :presets="['supress_errors']"
      :columns="{
        default: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
        sm: {
          label: 4,
        },
        md: {
          label: 4,
        },
        lg: {
          label: 4,
        },
      }"
      :should_validate_on_mount="false"
      :display-errors="false"
      class="mb-6"
      @change="updateInventoryConfiguration($event)"
    />
  </div>
</template>
