import { compact, isArray, isObject, orderBy, slice } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { getUserFullName } from '~/common/utils/common.utils.js';

export function useMembers() {
  function getUserDetails(members = [], max_badges_to_display = 5) {
    const common_store = useCommonStore();
    const auth_store = useAuthStore();
    let members_uids = [];
    let compact_items = [];
    if (isArray(members))
      compact_items = members.map(m => isObject(m) ? m.uid : m);
    else
      compact_items = isObject(members) ? [members.uid] : [members];

    members_uids = compact(compact_items);
    const members_sorted_by_type
      = orderBy(
        members_uids.map(uid =>
          ({ uid, user_type: common_store.is_type_team(uid) ? 'team' : 'user' }),
        ),
        item => item.user_type === 'user' ? 0 : 1,
      );
    const members_to_display = computed(() => slice(members_sorted_by_type, 0, max_badges_to_display));

    const remaining_members_to_display = computed(() => slice(members_sorted_by_type, max_badges_to_display, members_uids?.length));

    const members_details = members_sorted_by_type.map((member) => {
      const is_member_a_logged_in_user = auth_store?.logged_in_user_details?.user_id === member.uid;
      const is_member_a_internal_user = !!(common_store?.internal_users_uids_map?.[member.uid]);
      const is_logged_user_a_internal_user = !!auth_store?.logged_in_user_details?.user_role;
      const is_member_a_bot = member.uid === 'sensehawk';
      const organization_member_details = member.user_type === 'team'
        ? common_store.get_team(member.uid)
        : common_store.get_user(member.uid);
      const internal_member_details = !member.user_type === 'user'
        ? common_store?.internal_users_map?.[member.uid]
        : {};
      const logged_in_user_details = auth_store?.logged_in_user_details;
      if (organization_member_details) {
        return {
          uid: member.uid,
          name: getUserFullName(organization_member_details),
          email: organization_member_details?.email,
          display_picture: organization_member_details?.display_picture,
          type: 'organization',
          is_team: member.user_type === 'team',
        };
      }

      if (is_member_a_logged_in_user) {
        return {
          uid: member.uid,
          name: getUserFullName(logged_in_user_details),
          email: logged_in_user_details?.email,
          display_picture: logged_in_user_details?.display_picture,
          type: 'me',
          is_team: false,
        };
      }

      if (is_logged_user_a_internal_user && internal_member_details?.uid) {
        return {
          uid: member.uid,
          name: getUserFullName(internal_member_details),
          email: internal_member_details?.email,
          display_picture: internal_member_details?.display_picture,
          type: 'internal',
          is_team: false,
        };
      }

      if (
        (is_logged_user_a_internal_user && is_member_a_internal_user && !internal_member_details)
        || (!is_logged_user_a_internal_user && is_member_a_internal_user)
      ) {
        return {
          uid: member.uid,
          name: 'Sensehawk',
          display_picture: null,
          type: 'sensehawk',
          is_team: false,
        };
      }

      if (is_member_a_bot) {
        return {
          uid: member.uid,
          name: 'Sensehawk Bot',
          display_picture: null,
          type: 'sensehawk',
          is_online: false,
          is_team: false,
        };
      }

      return {
        uid: member.uid,
        name: 'Unknown',
        display_picture: null,
        type: 'unknown',
        is_team: false,
      };
    });
    return { members_details, members_to_display, remaining_members_to_display };
  }
  return { getUserDetails };
}
