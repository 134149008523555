<script setup>
import { useElementSize } from '@vueuse/core';
import { isEqual } from 'lodash-es';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import TasksView from '~/tasks/pages/tasks/tasks-view.vue';
import { useTasksStore } from '~/tasks/store/tasks.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  is_editing: {
    type: Boolean,
    default: false,
  },
});

const dashboard_store = useDashboardStore();
const task_store = useTasksStore(`dashboard-tasks-${props.id}`);
const task_widget_header = ref(null);
const prevent_watcher = ref(false);
const { height } = useElementSize(task_widget_header);

const column_config = computed(() => {
  const widths_map = props.data?.data?.columns_widths || {};

  const column_keys = ['name', 'priority', 'assignees', 'due_date', 'status'];

  return column_keys.reduce((res, key) => {
    res[key] = {};

    if (key === 'name')
      res.name.header = 'Task';

    if (widths_map[key])
      res[key].size = widths_map[key];

    return res;
  }, {});
});

const forceUpdate = ref(0);

function getHeight() {
  if ((props.id === 'preview') || dashboard_store.is_mobile_view)
    return ((props.data.h || 22) * 20) - 90;

  else
    return (props?.data?.h * 24.25) - (height.value + 20);
}

function calculate_total() {
  if (props?.data?.data?.sub_tasks) {
    return task_store.total_task_count - task_store.tasks_with_children().length;
  }
  else {
    return task_store.total_task_count - task_store.tasks().length;
  }
}
function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: undefined,
    renderAt: `chart-container-${props?.id}`,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data?.data?.name,
    renderType: 'tasks-list',
    dimensions: {
      x: props?.data?.x,
      y: props?.data?.y,
    },
    dataSource: {
      dataset: props?.data?.data?.sub_tasks ? task_store.tasks_with_children() : task_store.tasks(),
      total: calculate_total(),
      dashboard_index: props.data.i,
    },
  });
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

const task_list_options = computed(() => {
  return {
    disable_subtask_create: true,
    hide_subtasks: true,
    hide_breadcrumbs: true,
    include_subtasks: props.data.data.sub_tasks,
    query: {
      ...(
        Object.keys(props?.data?.data?.filters?.filters || {}).length
          ? props?.data?.data?.filters?.filters
          : {}
      ),
      sub_tasks: props.data.data.sub_tasks,
      // scope: props.data.data.scope ? 'user' : 'organization',
      breadcrumbs: true,
      set_pusher: false,
      asset_uid: props.data.data.asset_id || null,
    },
  };
});

watch(() => props.data.data, (new_props_data, old_props_data) => {
  if (!old_props_data || (!isEqual(new_props_data, old_props_data))) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    task_store.$reset();
    forceUpdate.value++;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });

const tasks_count = computed(() => task_store.total_task_count);

watch(tasks_count, (new_val, old_val) => {
  if (new_val !== old_val && props?.id !== 'preview')
    updatePrintMap();
}, { immediate: true });
</script>

<template>
  <div>
    <div ref="task_widget_header">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
    </div>
    <TasksView
      :id="props.id"
      :key="forceUpdate"
      :style="`height:${getHeight()}px`"
      class="pl-2 pt-2 "
      :store_key="`dashboard-tasks-${props.id}`"
      :is_widget="true"
      :is_compact_view="true"
      :table_options="{
        column_config,
        hide_context_menu: false,
        additional_table_classes: '',
        table_height: getHeight(),
        no_table_wrapper: true,
        disable_resize: !dashboard_store.is_editing_dashboard,
        name_header: 'Task',
      }"
      :options="task_list_options"
      @column-resized="columnResized"
    >
      <template #top-panel>
        <div />
      </template>
      <template v-if="props.data.data.sub_tasks" #task_name="widget_data">
        <div>
          <div v-if="widget_data?.task?.element?.breadcrumbs?.length" class="flex items-center mb-1">
            <hawk-breadcrumbs
              :items="widget_data.task.element.breadcrumbs.map(b => ({ uid: b.uid, label: b.name }))"
              :show_active_color="false"
            />
            <IconHawkChevronRight aria-hidden="true" class="w-[16px] h-[16px] text-gray-300" />
            <HawkText class="font-medium text-gray-500 text-sm" :content="$t('tasks')" />
          </div>
          <div class="text-sm font-medium text-gray-900 max-w-[350px] truncate">
            {{ widget_data.task.element.name }}
          </div>
        </div>
      </template>
    </TasksView>
  </div>
</template>
