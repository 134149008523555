<script setup>
import { ref } from 'vue';
import ThermDefectsList from '../components/therm-defects-list.vue';
import ThermProjects from '../components/therm-projects.vue';
import { useThermStore } from '../store/therm.store';
import { useThermTaskFilters } from '~/therm/composables/therm-filters';

const search = ref('');
const therm_store = useThermStore();
const selected_defects = ref([]);

const tableData = computed(() => {
  const result = [];
  therm_store.features.forEach((project) => {
    search.value.length ? (project.properties?.string_number?.includes(search.value) && result.push(project.properties)) : result.push(project.properties);
  });
  return result;
});

function handleSelectedDefects(defects) {
  selected_defects.value = defects;
}
</script>

<template>
  <div class="w-[calc(100vw-250px)] px-4 border-solid border-gray-300 border-t border-l overflow-x-hidden">
    <ThermBulkActions v-if="selected_defects.length" class="absolute left-6 top-11" :defect_table_instance="therm_store.defect_table_instance" :defects="selected_defects" />
    <ThermProjects v-else class="top-4" />

    <div v-if="selected_defects.length === 0" class="filter-search absolute top-4 right-5 flex gap-[14px]">
      <HawkSearchInput v-model:search="search" placeholder="Search" @update:modelValue="search = $event" />
      <HawkButton type="outlined" class="text-gray-500 font-regular text-sm" @click="useThermTaskFilters">
        <IconHawkFilterLines />
        {{ $t('Filters') }}
      </HawkButton>
    </div>
    <ThermDefectsList :table_data="tableData" @selectedDefects="handleSelectedDefects" />
  </div>
</template>
