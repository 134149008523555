<script setup>
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { isArray } from 'lodash-es';
import dayjs from 'dayjs';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardFilters from '~/dashboard/components/filters/dashboard-filters.vue';
import { getDateInterval, isDateIntervalOperator } from '~/dashboard/components/filters/composables/filters-config';
import FamTabFormRules from '~/forms-as-module/components/fam-tabs-list/fam-tab-form-rules.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';

const emit = defineEmits(['update']);

const auth_store = useAuthStore();
const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();
const form_template_detail_store = useFormTemplateDetailStore();

const {
  widget_configuration,
} = storeToRefs(dashboard_store);

const {
  forms_widget_type,
  filter_fields,
  active_form_id,
  active_template_id,
  is_workflow_template,
} = storeToRefs(dashboard_forms_store);

const initial_state = ref([]);
const initial_state_set = ref(false);
const form_details = ref({ filters: [] });
const rules_state = reactive({
  is_invalid: false,
  get_filters_payload: null,
});
// dynamic filters are the ones that have fields from the active form
const forms_filter_type = computed(() => {
  if (forms_widget_type.value === 'forms_list')
    return 'forms_list';
  if (['forms_list_with_tabs', 'single_form_view', 'activity_history'].includes(forms_widget_type.value))
    return null;
  return 'dynamic';
});

const template_columns = computed(() => Object.values(form_template_detail_store.field_filters_map));

const custom_fields = computed(() => {
  if (auth_store.check_split('forms_v2'))
    return filter_fields.value || [];

  const fields = filter_fields.value || [];
  const filters = [
    ['Form name', 'text', 'formName'],
    ['Status', 'status', 'status'],
    ['Assignees', 'member', 'assignees'],
    ['Due date', 'date_time', 'dueDate'],
    ['Created by', 'member', 'createdBy'],
    ['Created on', 'date_time', 'createdAt'],
    ['Submitted by', 'member', 'submittedBy'],
    ['Submitted on', 'date_time', 'submittedAt'],
    ['Category', 'categories', 'category'],
  ].map(f => ({
    label: f[0],
    type: f[1],
    value: f[2],
  }));
  return [
    ...filters,
    ...fields,
  ];
});

const form_list_filters = [
  'submission_status',
  'assignees',
  'due_date',
  'category',
  'parent_form_uid',
  'created_by',
  'created_at',
  'last_submitted_by',
  'last_submitted_on',
];

const logic = ref({
  data: 'and',
});

function formatFormListDateFilter(f = {}) {
  const filter = {};

  const operator_map = {
    is: '_',
    earlier: '_end',
    later: '_start',
  };

  if (f.operator === 'between' && isArray(f.value)) {
    if (f.value?.[0])
      filter[`${f.filter_type}_start`] = dayjs.tz(f.value[0]).toISOString();
    if (f.value?.[1])
      filter[`${f.filter_type}_end`] = dayjs.tz(f.value[1]).toISOString();
  }

  if (operator_map[f.operator] && !isArray(f.value) && f.value) {
    // for is operator, we don't need any sufix
    const date_operator = operator_map[f.operator] === '_' ? '' : operator_map[f.operator];

    filter[`${f.filter_type}${date_operator}`] = dayjs.tz(f.value).toISOString();
  }

  if (isDateIntervalOperator(f.operator)) {
    const [first_day_date, last_day_date] = getDateInterval(f.operator);

    filter[`${f.filter_type}_start`] = first_day_date.toISOString();
    filter[`${f.filter_type}_end`] = last_day_date.toISOString();
  }

  return filter;
}

function formatFormsListFilters(data) {
  let filters = {};
  if (!data?.length)
    return {};

  for (let i = 0; i < data?.length; i++) {
    const f = data[i];

    if (!f)
      return;

    // date operators
    if (['due_date', 'created_at', 'submitted_at', 'last_submitted_on'].includes(f.filter_type))
      filters = {
        ...filters,
        ...formatFormListDateFilter(f),
      };

    // boolean operators
    else if (f.operator === 'is')
      filters[f.filter_type] = f.value;

    else if (f.operator === 'is_not')
      filters[`${f.filter_type}_not`] = f.value;
  }

  return filters;
}

function updateFormsListFilters(data) {
  if (data?.find(f => !f))
    return {};

  const filters = formatFormsListFilters(data);
  const report_filters = data.map(f => ({
    fact: f?.filter_type || null,
    operator: f?.operator || null,
    value: f?.value || null,
  }));

  emit('update', {
    filters,
    report_filters,
  });
}

const dynamic_filters_cache = ref([]);

function updateDynamicFilters(data) {
  if (!data?.length)
    emit('update', {
      rules: [],
      logic: {
        type: logic.value.data,
      },
    });

  if (data?.find(f => !f))
    return {};

  const filters = data.map((f) => {
    let value = null;

    if (f?.value || typeof f?.value === 'boolean')
      value = f.value;

    const is_relative_date = isDateIntervalOperator(f?.operator);

    return {
      fact: f?.filter_type || null,
      ...(is_relative_date
        ? {
            value: getDateInterval(f.operator),
            date_operator: f.operator,
            operator: 'between',
          }
        : {
            operator: f?.operator || null,
            value,
          }),
    };
  });

  dynamic_filters_cache.value = [...filters];

  emit('update', {
    rules: filters,
    logic: {
      type: logic.value.data,
    },
  });
}

function updateDynamicFiltersLogic() {
  if (!rules_state.is_invalid)
    emit('update', {
      rules: rules_state.get_filters_payload.filters() || [],
      logic: {
        type: logic.value.data,
      },
    });
}

function setInitialState() {
  logger.log('setting initial state');
  const filters = widget_configuration.value?.filters || {};

  if (['forms_list', 'forms_list_with_tabs'].includes(widget_configuration.value?.type) && Object.values(filters).length) {
    initial_state.value = Object.values(filters.report_filters).map(f => ({
      filter_type: f.fact,
      operator: f.operator,
      value: f.value,
    }));
  }

  else if (widget_configuration.value?.type && Object.values(filters).length) {
    initial_state.value = filters?.rules?.map(f => ({
      filter_type: f.fact,
      operator: f.date_operator ? f.date_operator : f.operator,
      value: f.date_operator ? null : f.value,
    })) || [];

    logic.value.data = filters.logic.type || 'or';

    form_details.value.filters = filters?.rules?.map(f => ({
      ...f,
      field: f.field,
      operator: f.date_operator ? f.date_operator : f.operator,
      value: f.date_operator ? null : f.value,
    })) || [];
  }

  setTimeout(() => {
    initial_state_set.value = true;
  }, 300);
}

function updateRules(rules) {
  form_details.value.filters = rules;
  if (!rules_state.is_invalid)
    emit('update', {
      rules: rules_state.get_filters_payload.filters() || [],
      logic: {
        type: logic.value.data,
      },
    });
}

watch(forms_filter_type, (new_val, old_val) => {
  if (!initial_state_set.value)
    return;

  emit('update', {});
  initial_state.value = [];
});

watch(active_form_id, (new_val, old_val) => {
  emit('update', {});

  if (!old_val && new_val)
    setInitialState();
  else
    initial_state.value = [];
});
watch(active_template_id, (new_val, old_val) => {
  emit('update', {});

  if (!old_val && new_val)
    setInitialState();
  else
    initial_state.value = [];
});
</script>

<template>
  <div v-if="forms_filter_type" class="text-sm text-blue-600 font-medium my-4 cursor-pointer">
    {{ $t('Advanced filters') }}
  </div>
  <template v-if="forms_filter_type === 'dynamic' && initial_state_set">
    <Vueform
      v-model="logic"
      sync
      size="sm"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
      }"
      @change="updateDynamicFiltersLogic"
    >
      <RadiogroupElement
        label="Conditions"
        name="data"
        :items="[
          {
            value: 'and',
            label: 'Match all filters (AND)',
          },
          {
            value: 'or',
            label: 'Match any filter (OR)',
          },
        ]"
      />
    </Vueform>
    <Vueform
      v-if="form_template_detail_store.field_filters_tree.length && auth_store.check_split('forms_v2')" v-model="form_details"
      sync
      size="sm"
    >
      <div class="col-span-12 mt-4">
        <FamTabFormRules
          name="filters"
          :rules="form_details.filters"
          :is_required="false"
          :fields="template_columns"
          @updateRules="updateRules"
          @invalid="($event) => (rules_state.is_invalid = $event)"
          @getFilterPayload="rules_state.get_filters_payload = $event"
        />
      </div>
    </Vueform>
    <DashboardFilters
      v-else
      :key="active_form_id + custom_fields.length"
      :custom_fields="custom_fields"
      :initial_state="initial_state"
      service="forms"
      :is_workflow="is_workflow_template"
      class="mb-4"
      @update="updateDynamicFilters($event)"
      @mounted="setInitialState"
    />
  </template>
  <DashboardFilters
    v-else-if="initial_state_set && forms_filter_type === 'forms_list'"
    :initial_state="initial_state"
    service="forms_list"
    :filters="form_list_filters"
    class="mb-4"
    @update="updateFormsListFilters($event)"
    @mounted="setInitialState"
  />
</template>

<style lang="scss">
.two_columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
</style>
