<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';

import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import useEmitter from '~/common/composables/useEmitter';
import ThermTaskForm from '~/tasks/components/molecule/task-form/therm-task-form.vue';
import TasksBulkActionsRow from '~/tasks/components/molecule/tasks-bulk-actions-row.vue';
import TaskActions from '~/tasks/components/organisms/tasks-actions.vue';
import { useTasksStore } from '~/tasks/store/tasks.store';
import DefectsBulkUpdate from '~/therm/components/therm-defects-bulk-update.vue';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import { useOnGenerateExcelClicked } from '~/therm/composables/therm-export-csv';
import { useThermStore } from '../store/therm.store';

const props = defineProps({
  defects: {
    type: Array,
    default: () => [],
  },
  defect_table_instance: {
    type: Object,
    default: () => {},
  },
});

const emitter = useEmitter();
const route = useRoute();
const tasks_store = useTasksStore('therm_tasks_store');
const therm_store = useThermStore();
const { createThermTasks } = useThermHelperComposable();
const open_task_popup = ref(false);
const selected_defects = ref(props.defects);
const $toast = inject('$toast');
const $t = inject('$t');

const selected_defects_tasks_uids = computed(() => {
  return selected_defects.value.map(defect => therm_store.tasks_hash[defect.uid]).filter(id => id !== undefined).map(task => task.uid);
});
const bulk_update_status = computed(() => {
  let is_disabled = false;
  let tooltip = $t('You don\'t have permissions to perform this action.');
  if (therm_store.use_tasks) {
    const tasks = selected_defects_tasks_uids.value.map(uid => tasks_store.tasks_map[uid]);
    is_disabled = (selected_defects.value.length !== selected_defects_tasks_uids.value.length);
    if (!is_disabled)
      is_disabled = tasks.some(task => !task.can_modify);
    else
      tooltip = $t('Select only tasks to bulk update');
  }
  else {
    is_disabled = !selected_defects.value.map(defect => therm_store.features_hash[defect.uid]).every(f => f.modify_properties);
  }
  return { disabled: is_disabled, tooltip: is_disabled ? tooltip : '' };
});

watch(() => props.defects, () => {
  selected_defects.value = props.defects;
});
function bulkUpdateHandler() {
  if (therm_store.use_tasks) {
    const { open: openBulkActionsPopup, close: closeBulkActionsPopup } = useModal({
      component: TaskActions,
      attrs: {
        tasks: selected_defects_tasks_uids.value.map(uid => tasks_store.tasks_map[uid]),
        asset_id: route.params.asset_id,
        task_store: tasks_store,
        type: 'therm',
        async onClose() {
          closeBulkActionsPopup();
          await therm_store.fetch_therm_tasks();
        },
      },
    });
    openBulkActionsPopup();
  }
  else {
    const { open: openBulkActionsPopup, close: closeBulkActionsPopup } = useModal({
      component: DefectsBulkUpdate,
      attrs: {
        defects: selected_defects.value.map(defect => therm_store.features_hash[defect.uid]),
        onClose() {
          closeBulkActionsPopup();
        },
      },
    });
    openBulkActionsPopup();
  }
}
function createTasks() {
  const defects_ids = selected_defects.value.map(defect => defect.uid);
  const originalDefects = therm_store.features.filter(feature => defects_ids.includes(feature.properties?.uid));
  if (therm_store.use_tasks)
    createThermTasks(originalDefects);

  else
    toggleThermTaskForm(true);
}

function tasksExportHandler(export_all) {
  useOnGenerateExcelClicked(export_all ? [] : selected_defects.value.map(defect => therm_store.features_hash[defect.uid]), route, () => true);
}

async function bulkArchiveHandler() {
  try {
    await tasks_store.archive_tasks(selected_defects_tasks_uids.value);
    tasks_store.task_track_events('Archived', { mode: 'Bulk', count: selected_defects_tasks_uids.value.length }, selected_defects_tasks_uids.value[0]);
    $toast({
      text: 'Tasks archived!',
      type: 'success',
    });
  }
  catch (err) {
    logger.error(err);
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

async function taskBulkDeleteHandler() {
  patchOptions(
    {
      attrs: {
        header: $t('Task'),
        content: 'Are you sure you want to delete selected tasks?',
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await tasks_store.remove_tasks(selected_defects_tasks_uids.value);
            closeDeletePopup();
          }
          catch (err) {
            logger.err(err);
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

const bulk_update_options = computed(() => {
  return [
    { uid: 0, label: $t('Create task'), on_click: () => createTasks() },
    { uid: 1, label: $t('Bulk Update'), on_click: bulkUpdateHandler, ...bulk_update_status.value },
    { uid: 2, label: $t('Export Excel'), on_click: () => tasksExportHandler() },
    { uid: 4, label: $t('Export PDF'), on_click: () => emitter.emit('therm_pdf_export_action', selected_defects.value.map(defect => therm_store.features_hash[defect.uid])) },
    { uid: 4, label: $t('Archive'), on_click: bulkArchiveHandler },
    { uid: 5, label: $t('Delete'), on_click: taskBulkDeleteHandler },
  ];
});

function clearDefectsSelection() {
  props.defect_table_instance.clearSelect();
}
function toggleThermTaskForm(is_open) {
  if (is_open)
    therm_store.selected_features = selected_defects.value.map(defect => therm_store.features_hash[defect.uid]);
  else
    therm_store.selected_features = [];
  open_task_popup.value = is_open;
}
provide('task_store', tasks_store);
</script>

<template>
  <div>
    <TasksBulkActionsRow
      :selected_tasks="defects"
      :bulk_actions_config="{
        prepend: true,
        include: [],
        additional_options: bulk_update_options,
      }"
      @clear-selections="clearDefectsSelection"
    />
    <ThermTaskForm v-if="open_task_popup" :key="open_task_popup" @close="toggleThermTaskForm(false)" />
  </div>
</template>
