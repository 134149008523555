import { keyBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { useCommonStore } from '~/common/stores/common.store.js';
import { ERROR_STATUS } from '~/inventory/utils/inventory-error-code.js';

export const useInventoryStore = defineStore('inventory', {
  state: () => ({
    items_map: {},
    total_items_count: 0,
    active_item_uid: '',
    active_transaction_uid: '',
    vendors_map: {},
    filters: {},
    warehouses_map: {},
    workflows_map: {},
    uom_map: {},
    item_types_map: {},
    warehouse_types_map: {},
    statuses_map: {},
    custom_fields_map: {},
    adjustments_map: {},
    total_adjustments_count: 0,
    history_details: null,
  }),
  getters: {
    items: (state) => {
      return Object.values(state.items_map);
    },
    warehouses: (state) => {
      return Object.values(state.warehouses_map);
    },
    workflows: (state) => {
      return Object.values(state.workflows_map);
    },
    adjustments: (state) => {
      return Object.values(state.adjustments_map);
    },
    vendors: (state) => {
      return Object.values(state.vendors_map);
    },
    item_types: (state) => {
      return Object.values(state.item_types_map);
    },
    warehouse_types: (state) => {
      return Object.values(state.warehouse_types_map);
    },
    assets: () => {
      const common_store = useCommonStore();
      return Object.values(common_store.assets_map);
    },
    uoms: (state) => {
      return Object.values(state.uom_map);
    },
    statuses: (state) => {
      return Object.values(state.statuses_map);
    },
    get_active_item_details: (state) => {
      return state.items_map[state?.active_item_uid];
    },
    get_active_warehouse_details(state) {
      return uid => state.warehouses_map?.[uid];
    },
    get_transaction_details: (state) => {
      return state.adjustments_map[state?.active_transaction_uid];
    },
    get_item_type(state) { return uid => state.item_types_map?.[uid]; },
    get_warehouse_type(state) { return uid => state.warehouse_types_map?.[uid]; },
    get_uom(state) { return uid => state.uom_map?.[uid]; },
    // get_item_details(state) { return uid => state.items_map[uid]; },
    get_location_details: (state) => {
      return ({ uid, type }) => {
        const common_store = useCommonStore();
        if (type === 'warehouse')
          return state.warehouses_map[uid] || null;

        if (type === 'vendor')
          return state.vendors_map[uid] || null;

        if (type === 'asset')
          return common_store.$state.assets_map[uid] || null;
      };
    },
    custom_fields: (state) => {
      return Object.values(state.custom_fields_map);
    },
    get_custom_field: (state) => {
      return (uid, is_active = false) => {
        const field = state.custom_fields_map[uid];
        if (is_active)
          return field?.active ? field : null;
        else
          return field;
      };
    },
    get_custom_fields: (state) => {
      return ({ uid, attached_to }, is_active = false) => {
        return state.custom_fields.reduce((result, curr) => {
          const item_meta_index = curr.attached_to.findIndex((meta) => {
            return uid ? uid === meta.uid : attached_to === meta?.type;
          });
          if (item_meta_index >= 0) {
            const item_meta = {
              ...curr.attached_to[item_meta_index],
              attached_to_index: item_meta_index,
            };
            if (is_active) {
              if (curr?.active && item_meta?.active)
                return [...result, { ...curr, item_meta }];
              else
                return result;
            }
            else {
              return [...result, { ...curr, item_meta }];
            }
          }
          return result;
        }, []);
      };
    },
    get_filtered_custom_fields: (state) => {
      return ({ uid, attached_to }) => {
        return state.custom_fields
          .filter((field) => {
            return !field.attached_to.find((type) => {
              const is_active = uid ? uid === type.uid : attached_to === type.type;
              return is_active ? type?.active : false;
            });
          })
          .filter(cf => cf?.active);
      };
    },
    get_error_status: () => {
      return code => ERROR_STATUS(code) || {};
    },
  },
  actions: {
    async initialize_inventory() {
      await this.$services.inventory_workflows.initialize_inventory({});
    },
    async set_inventory_initial_data(options) {
      this.adjustments_map = {};
      return await Promise.all([
        this.set_unified_resources(options),
      ]);
    },
    async set_unified_resources(options) {
      const { data } = await this.$services.inventory_unified_resources.get_unified_resources({
        query: options.query,
        body: {
          'vendors': true,
          'item-statuses': true,
          'measurement-units': true,
          'categories': true,
          'custom-fields': true,
          'warehouses': true,
          'item-workflows': true,
          'warehouse_type': true,
        },
        toast: false,
        signal: this.signal,
      });
      this.vendors_map = keyBy(data.vendors, 'uid');
      this.statuses_map = keyBy(data['item-statuses'], 'uid');
      this.uom_map = keyBy(data['measurement-units'], 'uid');
      this.item_types_map = keyBy(data.categories, 'uid');
      this.custom_fields_map = keyBy(data['custom-fields'], 'uid');
      this.warehouses_map = keyBy(data.warehouses, 'uid');
      this.workflows_map = keyBy(data['item-workflows'], 'uid');
      this.warehouse_types_map = keyBy(data.warehouse_type, 'uid');
    },
    async set_items(options) {
      try {
        const { data, headers } = await this.$services.inventory_items.getAll({
          query: {
            ...options.query,
            ...this.items_filters,
          },
          toast: false,
          signal: this.signal,
        });
        if (options?.query?.page === 1 && data?.items?.length)
          this.active_item_uid = data.items[0]?.uid;

        this.total_items_count = headers['x-total-count'];
        const inventory_items = keyBy(data.items, 'uid');
        if (options?.append)
          this.items_map = { ...this.items_map, ...inventory_items };
        else
          this.items_map = inventory_items;
        return data.items;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async create_inventory_items(payload) {
      try {
        const data = await this.$services.inventory_items.post({
          body: { items: payload.items },
        }, true);
        return data;
      }
      catch (error) {
        return error;
      }
    },
    async update_inventory_item(options) {
      const data = await this.$services.inventory_items.patch({
        id: options.id,
        body: options.body,
      });
      return data;
    },
    async set_inventory_item_inactive(options) {
      const { data } = await this.$services.inventory_items.patch({
        id: options.id,
        body: { is_active: options.body.is_active || false },
      });
      if (data?.item?.uid)
        this.items_map[data.item.uid] = data.item;
      return data;
    },
    async set_warehouses(options) {
      const { data } = await this.$services.inventory_warehouses.getAll({
        ...options,
        toast: false,
      });
      this.warehouses_map = keyBy(data.warehouses, 'uid');
    },
    async create_warehouse(options) {
      const { data } = await this.$services.inventory_warehouses.post({
        body: options.body,
      });
      this.warehouses_map = { [data.warehouse.uid]: data.warehouse, ...this.warehouses_map };
      return data;
    },

    async update_warehouse(options) {
      const { data } = await this.$services.inventory_warehouses.patch({
        id: options.id,
        body: options.body,
      });
      if (data?.warehouse?.uid)
        this.warehouses_map[data.warehouse.uid] = data.warehouse;
      return data;
    },

    async set_uom(options) {
      const { data } = await this.$services.inventory_uom.getAll({
        ...options,
        toast: false,
      });
      this.uom_map = keyBy(data.measurement_units, 'uid');
    },
    async create_uom(options) {
      const response = await this.$services.inventory_uom.post({
        body: { measurement_units: options.payload },
      });

      const measurement_unit = [
        ...response.data.measurement_units,
        ...Object.values(this.uom_map),
      ];

      this.uom_map = keyBy(measurement_unit, 'uid');
      return response.data.measurement_units.map(uom => uom.uid);
    },
    async set_statuses(options) {
      const { data } = await this.$services.inventory_item_statuses.getAll({
        ...options,
        toast: false,
      });
      this.statuses_map = keyBy(data.item_statuses, 'uid');
    },
    async set_workflows(options) {
      const { data } = await this.$services.inventory_workflows.getAll({
        ...options,
        toast: false,
      });
      this.workflows_map = keyBy(data.item_work_flows, 'uid');
    },
    async set_item_types(options) {
      const { data } = await this.$services.inventory_item_types.getAll({
        ...options,
        toast: false,
      });
      this.item_types_map = keyBy(data.categories, 'uid');
    },
    async create_item_type(options) {
      const response = await this.$services.inventory_item_types.post({
        body: { categories: options.payload },
      });

      const item_types = [
        ...response.data.categories,
        ...Object.values(this.item_types_map),
      ];

      this.item_types_map = keyBy(item_types, 'uid');
      return response.data.categories.map(uom => uom.uid);
    },
    async update_item_type(options) {
      const response = await this.$services.inventory_item_types.update_item_type({
        id: options.uid,
        body: { category: options.payload },
      });

      this.item_types_map[options.uid] = response.data.category;
      return response.data.item_type;
    },
    async delete_item_type(options) {
      await this.$services.inventory_item_types.delete_item_type({
        id: options.uid,
      });
      delete this.item_types_map[options.uid];
      return true;
    },
    async create_stock_status(options) {
      const response = await this.$services.inventory_stock_statuses.post({
        body: { item_status: options.payload },
      });

      const stock_statuses = [
        response.data.item_status,
        ...Object.values(this.statuses_map),
      ];

      this.statuses_map = keyBy(stock_statuses, 'uid');
      return response.data.item_status;
    },
    async update_stock_status(options) {
      const response = await this.$services.inventory_stock_statuses.update_stock_status({
        id: options.uid,
        body: { item_status: options.payload },
      });

      this.statuses_map[options.uid] = response.data.item_status;
      return response.data.item_status;
    },
    async delete_stock_status(options) {
      await this.$services.inventory_stock_statuses.delete_stock_status({
        id: options.uid,
      });
      delete this.statuses_map[options.uid];
      return true;
    },
    async set_warehouse_types(options) {
      const { data } = await this.$services.inventory_warehouse_types.getAll({
        ...options,
        toast: false,
      });
      this.warehouse_types_map = keyBy(data.warehouse_types, 'uid');
    },
    async create_warehouse_type(options) {
      const response = await this.$services.inventory_warehouse_types.post({
        body: { warehouse_type: options.payload },
      });

      const warehouse_types = [
        response.data.warehouse_type,
        ...Object.values(this.warehouse_types_map),
      ];

      this.warehouse_types_map = keyBy(warehouse_types, 'uid');
      return warehouse_types.map(type => type.uid);
    },
    async update_warehouse_type(options) {
      const response = await this.$services.inventory_warehouse_types.update_warehouse_type({
        id: options.uid,
        body: { warehouse_type: options.payload },
      });

      this.warehouse_types_map[options.uid] = response.data.warehouse_type;
      return response.data.warehouse_type;
    },
    async delete_warehouse_type(options) {
      await this.$services.inventory_warehouse_types.delete_warehouse_type({
        id: options.uid,
      });
      delete this.warehouse_types_map[options.uid];
      return true;
    },
    async set_vendors(options) {
      const { data } = await this.$services.inventory_vendors.getAll({
        ...options,
        toast: false,
      });
      this.vendors_map = keyBy(data.vendors, 'uid');
    },
    async create_vendor(options) {
      const { data } = await this.$services.inventory_vendors.post({
        ...options,
      });
      if (data) {
        this.vendors_map[data.vendor.uid] = data.vendor;
        return data.vendor;
      }
    },
    async update_vendor(options) {
      const { data } = await this.$services.inventory_vendors.update_vendor({
        id: options.uid,
        body: { vendor: options.payload },
      });
      if (data) {
        this.vendors_map[options.uid] = data.vendor;
        return data.vendor;
      }
    },
    async delete_vendor(options) {
      await this.$services.inventory_vendors.delete_vendor({
        id: options.uid,
      });
      delete this.vendors_map[options.uid];
      return true;
    },
    async set_custom_fields(options) {
      const { data } = await this.$services.inventory_custom_fields.getAll({
        ...options,
        toast: false,
      });
      this.custom_fields_map = keyBy(data.custom_fields, 'uid');
    },
    add_custom_field(payload) {
      this.custom_fields_map[payload.uid] = payload.value;
    },
    async delete_custom_field(options) {
      await this.$services.inventory_custom_fields.delete({
        id: options.id,
      });
      this.custom_fields_map[options.id].active = false;
    },
    async update_custom_field(options) {
      const res = await this.$services.inventory_custom_fields.patch({
        id: options.id,
        body: options.body,
      });
      this.custom_fields_map[options.id] = res.data?.custom_field;
      this.custom_fields_map = { ...this.custom_fields_map };
      return res.data?.custom_field;
    },
    async set_history_details(options) {
      const res = await this.$services.inventory_adjustments.get({
        id: options.id,
        query: {
          'include[]': ['adjustment_lines.item.*', 'shipment_allocation.shipment'],
          ...options.query,
        },
        toast: false,
      });
      this.history_details = res.data?.adjustment;
      this.history_details.adjustment_lines_map = res.data?.adjustment ? keyBy(res.data.adjustment?.adjustment_lines, 'item') : {};
      this.history_details.items_map = res.data ? keyBy(res.data.items, 'uid') : {};
      this.history_details.shipment = res.data?.shipment_allocations?.[0]?.shipment;
    },
    async set_adjustments(options) {
      try {
        this.pagination_limit = options.query.limit;
        const { data, headers } = await this.$services.inventory_adjustments.getAll({
          query: {
            ...options.query,
            ...this.adjustments_filters,
          },
          params: options?.params,
          toast: false,
        });

        if (options?.query?.page === 1 && data?.adjustments?.length)
          this.active_transaction_uid = options.active_transaction_uid || data.adjustments[0]?.uid;
        this.total_adjustments_count = headers['x-total-count'];
        const adjustments = data.adjustments;
        const inventory_adjustments = keyBy(adjustments, 'uid');
        if (options.append)
          this.adjustments_map = { ...this.adjustments_map, ...inventory_adjustments };
        else
          this.adjustments_map = inventory_adjustments;
        return adjustments;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async create_adjustment(options) {
      const response = await this.$services.inventory_adjustments.post(options);
      return response.data.adjustment;
    },
    async update_adjustment(options) {
      await this.$services.inventory_adjustments.patch(options);
    },
    async update_workflow(options) {
      const response = await this.$services.inventory_workflows.update_workflow({
        id: options.uid,
        body: { item_work_flow: options.item_workflow },
      });
      this.workflows_map[options.uid] = response.data.item_work_flow;
      return response.data.item_work_flow;
    },
    async syncApiForInspectionForms(form, options = {}) {
      try {
        const response = await this.$services.inventory.post({
          attribute: 'form-submissions/intg_201',
          body: (options.payload && Array.isArray(options.payload) ? options.payload : null) ?? [
            {
              event: options.event,
              asset: form.target_element?.asset,
              adjustment: form.properties.integration?.transaction?.uid,
              item: form.properties.integration?.item?.uid,
              form_template_uid: form?.template?.uid || form?.template,
              form: {
                uid: form.uid,
                status: options.status,
              },
            },
          ],
        });
        return response;
      }
      catch (err) {
        logger.error(err);
      }
    },
  },
});
