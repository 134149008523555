<script setup>
// stores
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  file: {
    type: String,
    required: true,
  },
  position: {
    type: String,
    required: false,
    default: 'bottom-left',
  },
  is_current: {
    type: Boolean,
    required: true,
  },
});

const $t = inject('$t');
const $toast = inject('$toast');

const document_store = useDocumentStore();

const menu_items = computed(() => {
  const items = [
    {
      id: 'download',
      label: `${$t('Download')}`,
      on_click: downloadVersion,
    },
    {
      id: 'active',
      label: `${$t('Mark as Active')}`,
      on_click: markActive,
    },
    {
      id: 'delete',
      label: `${$t('Delete')}`,
      on_click: itemDelete,
    },
  ];

  if (props.is_current)
    return items.filter(item => item.id === 'download');

  else
    return items;
});

async function markActive() {
  try {
    await document_store.crud_documents({
      request: {
        body: {
          files: {
            update: [
              {
                uid: props.file,
                default_version: props.item.uid,
              },
            ],
          },
        },
      },
    });
    $toast({
      title: $t('Active Version changed'),
      text: $t('Your changes have been saved and the selected version made active'),
      type: 'success',
      position: 'bottom-right',
    });
  }
  catch (error) {
    $toast({
      title: 'Error',
      text: error?.data?.message || $t('Something went wrong'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

async function itemDelete() {
  try {
    await document_store.crud_documents({
      request: {
        body: {
          files: {
            update: [
              {
                uid: props.file,
                delete_version: props.item.uid,
              },
            ],
            remove: [
            ],
          },
        },
      },
    });
    $toast({
      title: $t('Version Deleted'),
      text: $t('Version successfully deleted'),
      type: 'success',
      position: 'bottom-right',
    });
  }
  catch (e) {
    $toast({
      title: 'Error',
      text: e?.data?.message || $t('Something went wrong'),
      type: 'error',
      position: 'bottom-right',
    });
  }
}

async function downloadVersion() {
  const response = await document_store.get_download_url({
    body: {
      files: [{
        uid: props.file,
        version_uid: props.item.uid,
      }],
    },
    attribute: 'url',
  });
  const url = response.data.urls[0].url;
  window.open(url, '_blank');
}
</script>

<template>
  <hawk-menu
    :items="menu_items"
    additional_trigger_classes="!ring-0"
    :position="position"
  >
    <template #trigger>
      <slot name="trigger" />
    </template>
  </hawk-menu>
</template>
