<script setup>
const props = defineProps({
  formData: {
    type: Object,
  },
  payloadData: {
    type: Object,
  },
  dateDropdownOptions: {
    type: Array,
  },
});

defineEmits('validateNumberInput');

const $t = inject('$t');

const when_dropdown_options = [
  { value: 'after', label: $t('After') },
  { value: 'before', label: $t('Before') },
];

const date_dropdown_options = props.dateDropdownOptions || [
  { value: 'dueDate', label: $t('Due date') },
  { value: 'startDate', label: $t('Start date') },
];

const suffix_map = {
  7: $t('weeks'),
  30: $t('months'),
};
</script>

<template>
  <div v-if="!formData?.reminders?.is_once">
    <div class="text-sm mb-2">
      {{ $t("Remind") }}
    </div>
    <div class="flex flex-col mb-5 ">
      <div class="flex">
        <SelectElement
          class="w-40"
          default="1"
          placeholder="Select"
          :rules="['required']"
          size="sm"
          :can-deselect="false"
          :can-clear="false"
          name="frequency"
          :native="false"
          :items="[
            { label: $t('Every day'), value: 1 },
            { label: $t('Every week'), value: 7 },
            { label: $t('Every month'), value: 30 },
            { label: $t('Custom'), value: 0 },
          ]"
        />
        <div class="mx-2 pt-2">
          {{ formData?.reminders?.frequency ? $t('for') : $t('every') }}
        </div>
        <TextElement
          v-if="formData?.reminders?.frequency"
          class="w-24"
          name="count"
          input-type="number"
          :default="1"
          :rules="['required', 'integer', 'min:1']"
          autocomplete="off"
          @input="
            $event =>
              $emit('validateNumberInput', {
                name: 'count',
                e: $event,
                key: 'reminders',
              })
          "
        >
          <template #addon-after>
            {{ suffix_map[+formData?.reminders?.frequency] || $t("days") }}
          </template>
        </TextElement>
        <div v-else class="flex">
          <TextElement
            class="w-24"
            name="interval"
            input-type="number"
            :default="payloadData?.reminders?.interval || 1"
            :rules="['required', 'integer', 'min:1']"
            autocomplete="off"
            @input="
              $event =>
                $emit('validateNumberInput', {
                  name: 'interval',
                  e: $event,
                  key: 'reminders',
                })
            "
          >
            <template #addon-after>
              {{ suffix_map[+formData?.reminders?.frequency] || $t("days") }}
            </template>
          </TextElement>
          <div v-if="!formData?.reminders?.frequency" class="mx-2 pt-2">
            {{ $t('for') }}
          </div>
          <TextElement
            v-if="!formData?.reminders?.frequency"
            name="count"
            input-type="number"
            class="w-24"
            :default="1"
            :rules="['required', 'integer', 'min:1']"
            autocomplete="off"
            @input="
              $event =>
                $emit('validateNumberInput', {
                  name: 'count',
                  e: $event,
                  key: 'reminders',
                })
            "
          >
            <template #addon-after>
              {{ $t("times") }}
            </template>
          </TextElement>
        </div>
      </div>
      <div class="text-sm my-2">
        {{ $t("Trigger") }}
      </div>
      <div class="flex">
        <SelectElement
          class="w-24 mr-2"
          default="before"
          placeholder="Select"
          :rules="['required']"
          size="sm"
          :can-deselect="false"
          :can-clear="false"
          name="when"
          :native="false"
          :items="when_dropdown_options"
        />
        <SelectElement
          class="w-28"
          :rules="['required']"
          :placeholder="$t('Due date')"
          size="sm"
          default="due_date"
          :can-deselect="false"
          :can-clear="false"
          :disabled="date_dropdown_options.length === 1"
          :close-on-select="true"
          name="from"
          :native="false"
          :items="date_dropdown_options"
        />
      </div>
    </div>
  </div>
  <div v-else class="flex">
    <TextElement
      class="w-24"
      name="interval"
      input-type="number"
      :default="payloadData?.reminders?.interval || 1"
      :rules="['required', 'integer', 'min:1']"
      autocomplete="off"
      @input="
        $event =>
          $emit('validateNumberInput', {
            name: 'interval',
            e: $event,
            key: 'reminders',
          })
      "
    >
      <template #addon-after>
        {{ $t("days") }}
      </template>
    </TextElement>
    <SelectElement
      class="w-24 mx-2"
      default="before"
      placeholder="Select"
      :rules="['required']"
      size="sm"
      :can-clear="false"
      name="when"
      :native="false"
      :items="when_dropdown_options"
    />
    <SelectElement
      class="w-28"
      :rules="['required']"
      :placeholder="$t('Due date')"
      size="sm"
      :default="date_dropdown_options[0].value"
      :can-deselect="false"
      :can-clear="false"
      :disabled="date_dropdown_options.length === 1"
      :close-on-select="true"
      name="from"
      :native="false"
      :items="date_dropdown_options"
    />
  </div>
</template>
