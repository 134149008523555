<script setup>
import { isEmpty } from 'lodash-es';
import { provide } from 'vue';
import FamPageHeader from '~/forms-as-module/components/fam-page-header.vue';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFamViewStore } from '~/forms-as-module/store/fam-view.store.js';
import FormActivityHistoryPopout from '~/dashboard/components/widgets/form-widgets/form-activity-history-popout.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { auth_store, route, router } = useCommonImports();
const fam_view_store = useFamViewStore();
const form_template_detail_store = useFormTemplateDetailStore();
provide('form_template_detail_store', form_template_detail_store);

const state = reactive({
  open_popout: false,
  is_loading: false,

});
async function set_initial_data() {
  try {
    state.is_loading = true;
    await form_template_detail_store.set_form_template({
      id: route.params.template_uid,
    });
    await form_template_detail_store.get_field_filter_list({
      id: route.params.template_uid,
    });
    await fam_view_store.setCustomViews();
    logger.log(form_template_detail_store.is_template_flow);

    if (route.name === 'fam')
      if (!form_template_detail_store.is_template_flow)
        router.replace({ name: 'fam-list', params: { ...route.params } });
      else
        router.replace({ name: 'fam-overview', params: { ...route.params } });

    setTimeout(() => {
      state.is_loading = false;
    }, 10);
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ set_initial_data ~ error:', error);
  }
}

watch(() => route.params.template_uid, async () => {
  await set_initial_data();
  setTimeout(() => {
    document.title = `${auth_store.current_organization?.name} - ${form_template_detail_store?.form_template_detail?.name}`;
  }, 100);
}, { immediate: true });
</script>

<template>
  <hawk-loader v-if="state.is_loading" />
  <HawkIllustrations v-else-if="isEmpty(form_template_detail_store.form_template_detail)" type="no-data" />
  <template v-else-if="!isEmpty(form_template_detail_store.form_template_detail)">
    <FamPageHeader v-if="!form_template_detail_store.is_list_collapsed" class="border-b" @viewActivity="state.open_popout = !state.open_popout" />
    <RouterView />
    <FormActivityHistoryPopout :id="route.params.template_uid" :open="state.open_popout" @closed="state.open_popout = false" />
  </template>
</template>
