<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useFormTemplatesStore } from '~/forms/store/form-templates.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  form_data: {
    type: Object,
    default: null,
  },
  is_inventory: {
    type: Boolean,
    default: false,
  },
  disabled_templates: {
    type: Array,
    default: () => ([]),
  },
});

const emit = defineEmits(['save', 'close', 'toggleEdit']);

const route = useRoute();
const common_store = useCommonStore();
const auth_store = useAuthStore();
const form_store = useFormsStore('terra_form_store');
const form_template_store = useFormTemplatesStore('form-all-templates');
const inventory_store = useInventoryStore();

const form = ref(null);
const forms$ = ref(null);

const template_list = computed(() => {
  if (route.params.template_uid)
    return [...form_store.form_template, ...[form_template_store?.form_templates_map[route.params.template_uid]]];
  else
    return [...form_store.form_template].sort((form1, form2) => form1?.name?.localeCompare(form2.name));
});

const has_template_access = computed(() => {
  return route.params.template_uid ? template_list.value.find(template => template?.uid === route.params.template_uid) : true;
});

const form_template_asset_list = computed(() => {
  const assetMap = template_list.value.filter(form => form)
    .reduce((final, form) => {
      if (!final[form.target_element.asset])
        final[form.target_element.asset] = [];
      final[form.target_element.asset].push(form);
      return final;
    }, {});
  const asset_template_list = Object.keys(assetMap)
    .map((asset_id) => {
      return {
        name: common_store?.get_asset(asset_id)?.name,
        children: assetMap[asset_id],
      };
    });
  const template_asset_list = asset_template_list
    .map((asset) => {
      return {
        asset_name: asset.name,
        label: asset.name || auth_store.current_organization?.name,
        items: asset.children.filter(form => form.name).map((form) => {
          return { name: form.name, uid: form.uid };
        }),
      };
    })
    .sort((a, b) => a.asset_name?.localeCompare?.(b.asset_name));
  return template_asset_list;
});

async function form_template() {
  const query = {
    isChild: false,
    public: false,
    status: 'published',
  };
  await form_store.get_templates({ query });
}

function formatLoadedData(data) {
  const load_data = {};
  const user_id = auth_store?.logged_in_user_details?.user_id;

  Object.entries(data).forEach((entry) => {
    const [key, value] = entry;
    if (key === 'assignees') {
      const assignees_option = value?.[0] === user_id ? 'Assign to the user making progress update' : 'Custom';
      load_data.assignees_group = {
        [key]: value,
        assignees_option,
      };
    }
    else if (key === 'template') {
      load_data[key] = template_list.value.find(val => value === val.uid);
    }
    else {
      load_data[key] = value;
    }
  });
  return load_data;
}
async function getTemplates() {
  if (!form_store.form_template.length)
    await form_template();
  return form_template_asset_list.value.map(asset => ({
    ...asset,
    items: (asset.items || []).filter(template => !props.disabled_templates?.includes(template.uid)),
  }));
}
function handleMounted() {
  if (props.form_data) {
    form.value = formatLoadedData(props.form_data.config);
    forms$.value?.load?.(form.value, true);
  }
}

function saveAndUpdate() {
  const payload = {};
  const form_data = form.value;
  const user_id = auth_store?.logged_in_user_details?.user_id;
  Object.keys(form_data).forEach((key) => {
    if (key === 'assignees_group') {
      if (form_data[key].assignees_option === 'Assign to the user making progress update')
        payload.assignees = [user_id];
      else if (form_data[key].assignees_option === 'Custom' && form_data[key].assignees.length)
        payload.assignees = form_data[key].assignees.map(assignee => assignee?.uid || assignee);
    }
    else if (key === 'template') {
      payload.name = form_data[key].name;
      payload[key] = form_data[key].uid;
    }
    else if (key !== 'undefined' && form_data[key] && `${form_data[key]}`.length) {
      payload[key] = form_data[key];
    }
  });
  emit('save', payload);
}
</script>

<template>
  <hawk-modal-container :options="modal_options">
    <Vueform
      ref="forms$"
      v-model="form"
      sync size="sm" :display-errors="false"
      :presets="['']" :float-placeholders="false" class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="saveAndUpdate" :columns="{
        default: { container: 9 },
        sm: { container: 9, label: 2 },
        md: { container: 9, label: 4, wrapper: 11 },
      }"
      @mounted="handleMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("Select a template") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="!pb-0">
          <SelectElement
            class="mb-4"
            name="template"
            rules="required"
            :label="$t('template')"
            :placeholder="has_template_access ? $t('Choose Template') : '-'"
            track-by="name"
            label-prop="name"
            value-prop="uid"
            :info="has_template_access ? null : 'Access to this template is not available'"
            :items="getTemplates"
            clear-on-search="true"
            close-on-select="true"
            can-deselect="true"
            :groups="true"
            object
            hide-selected="false"
            search="true"
          >
            <template #option="{ option }">
              {{ option.name }}
            </template>
            <template #tag="{ option }">
              {{ option.name }}
            </template>
            <template #after>
              <div class="text-sm mt-4">
                {{ $t('Note') }}: {{ $t('Make sure necessary permissions are granted for the users to create forms.') }}
              </div>
            </template>
          </SelectElement>
          <ObjectElement
            class="mb-4"
            name="assignees_group"
            :label="$t('Assignees')"
          >
            <RadiogroupElement
              name="assignees_option"
              :items="[
                'Assign to the user making progress update',
                'Custom',
              ]"
              default="Assign to the user making progress update"
              @change="form.assignees_group.assignees = []"
            />
            <hawk-assignee-input
              format_load
              :options="{
                name: 'assignees',
                conditions: [
                  ['assignees_group.assignees_option', 'Custom'],
                ],
                has_teams: true,
                placeholder: $t('Select Assignees'),
                columns: {
                  default: { container: 12, label: 12, wrapper: 12 },
                  sm: { container: 12, label: 12, wrapper: 12 },
                  md: { container: 12, label: 12, wrapper: 12 },
                },
              }"
              :multi="true"
            />
          </ObjectElement>
          <TextElement
            name="duration"
            input-type="number"
            class="mb-5"
            :default="1"
            :rules="[
              'required',
              'integer',
              'min:1',
            ]"
            autocomplete="off"
            :label="$t('Duration')"
          >
            <template #addon-after>
              {{ $t('Days') }}
            </template>
          </TextElement>
          <TagsElement
            v-if="is_inventory"
            class="mb-4"
            name="items"
            :label="$t('Items')"
            :placeholder="$t('Select item')"
            track-by="name"
            label-prop="name"
            value-prop="uid"
            :items="inventory_store.items.filter(item => item.is_active)"
            :search="true"
            :close-on-select="false"
          />
          <ToggleElement
            class="mb-4"
            name="required"
            :default="true"
            :label="$t('Required')"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button
                class="mr-4 font-bold"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement v-if="has_template_access" button-class="w-full bg-blue-600" name="submit" :submits="true" data-dd-action-name="form-create">
                {{ form_data ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
