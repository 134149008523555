<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { nanoid } from '~/common/utils/common.utils';
import { useFormsStore } from '~/forms/store/forms.store';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import WorkflowTemplateDetails from '~/terra/components/workflow/workflow-form-details.vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  form_submissions: {
    type: Array,
    default: () => ([]),
  },
  transaction_details: {
    type: Object,
    default: () => ({}),
  },
  available_form_workflows: {
    type: Array,
    default: () => ([]),
  },
  item: {
    type: Object,
    default: () => ({}),
  },
  handle_draft: {
    type: Function,
    default: () => {},
  },
});

const emits = defineEmits(['update', 'disableSave', 'formCreated']);

const { route, $toast } = useCommonImports();

const { getFormPayload } = useTerraHelperComposable();

const store_key = 'inventory_form_store';
const form_store = useFormsStore(store_key);
const inventory_store = useInventoryStore();

const state = reactive({
  forms_map: {},
});

const form_workflows = computed(() => {
  return (props.available_form_workflows || []).map(field_details => getFormattedFieldDetails(field_details));
});

async function createForm(form_details) {
  let transaction_id = route.params.transaction_id;
  try {
    if (!transaction_id)
      transaction_id = await props.handle_draft(true, true);
    if (!transaction_id) {
      $toast({
        title: 'Form creation failed!',
        text: 'Fill the required transaction fields',
        type: 'error',
      });
      return;
    }
    const form_payload = getFormPayload(form_details);
    const payload = [{
      uid: nanoid(),
      ...form_payload,
      assignees: form_details.config.assignees,
      name: props.transaction_details?.number,
      properties: {
        integration: {
          type: 'INTG_201',
          transaction: {
            uid: transaction_id,
          },
          item: {
            uid: props.item?.uid || null,
          },
        },
      },
      target_element: {
        active: true,
        asset: route.params.asset_id,
        uid: props.item?.uid || transaction_id,
        type: props.item?.uid ? 'adjustmentline' : 'adjustment',
        stage: 'INVENTORY',
      },

    }];
    const data = await form_store.create_form({
      body: { forms: { add: payload } },
    }, true);
    const new_form = data?.forms?.added?.[0];
    if (new_form) {
      state.forms_map[form_details.uid] = {
        uid: new_form.uid,
        submission_status: new_form.status.submission_status,
        item_uid: props.item?.uid || null,
        form_template_uid: form_details.uid,
      };
      await inventory_store.syncApiForInspectionForms(new_form, { event: 'FORMS_CREATED', status: 'open' });
      emits('formCreated');
    }
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: 'Form creation failed!',
      type: 'error',
    });
  }
}

function getFormattedFieldDetails(field_details) {
  const current_form = state.forms_map[field_details.uid];

  const form_status = {
    current: 0,
    value: 0,
    current_form,
  };
  if (current_form?.submission_status === 'submitted')
    form_status.current = 1;

  return {
    ...field_details,
    ...form_status,
    config: {
      template: field_details.uid,
      assignees: field_details.assignees,
      duration: field_details.duration,
      required: field_details.required,
      items: field_details.items,
    },
  };
}

function init() {
  state.forms_map = props.form_submissions.reduce((acc, curr) => {
    acc[curr.form_template_uid] = {
      uid: curr.form_uid,
      submission_status: curr.submission_status,
      item_uid: curr.item_uid,
      form_template_uid: curr.form_template_uid,
    };
    return acc;
  }, {});
}

onMounted(() => {
  init();
});

watch(() => form_store.forms_map, (forms_map) => {
  Object.entries(state.forms_map).forEach(([key, value]) => {
    if (!value)
      return;
    if (forms_map[value.uid]) {
      state.forms_map[key] = {
        uid: forms_map[value.uid].uid,
        submission_status: forms_map[value.uid]?.status?.submission_status,
        item_uid: forms_map[value.uid]?.item_uid || null,
        form_template_uid: key,
      };
    }
    else {
      delete state.forms_map[key];
    }
  });
}, { deep: true, immediate: !props.item?.uid });

watch(() => state.forms_map, () => {
  emits('update', Object.entries(state.forms_map || {}).reduce((acc, [key, form]) => {
    if (form) {
      acc.push({
        item_uid: props.item?.uid || null,
        form_template_uid: key,
        form_uid: form.uid,
        submission_status: form.submission_status,
      });
    }
    return acc;
  }, []));

  emits('disableSave', !form_workflows.value.every((form) => {
    if (!form.required)
      return true;
    return state.forms_map[form.uid]?.submission_status === 'submitted';
  }));
}, { deep: true, immediate: !props.item?.uid });
</script>

<template>
  <div class="max-w-[600px]">
    <div v-if="!props.item?.uid" class="font-semibold mb-4">
      {{ $t('Forms') }}
    </div>
    <div class="flex flex-col gap-2">
      <WorkflowTemplateDetails
        v-for="(form, index) in form_workflows"
        :key="index"
        class="mb-4"
        :form_store_options="{
          store_key,
          track_event_view: 'InventoryWorkflow',
        }"
        :field_details="getFormattedFieldDetails(form)"
        :create_form="createForm"
      />
    </div>
  </div>
</template>
