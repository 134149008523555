<script setup>
import { computed } from 'vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  uid: {
    type: String,
    default: '',
  },
  truncate: {
    type: Boolean,
    default: false,
  },
  active_classes: {
    type: String,
    default: '',
  },
});
const store = useInventoryStore();

const warehouse = computed(() => {
  return store.get_location_details({
    uid: props?.uid,
    type: 'warehouse',
  });
});

const content = computed(() => {
  return warehouse?.value?.name || '-';
});
</script>

<template>
  <div
    v-tippy="{ content: (truncate && content.length > 15) && content, placement: 'right' }"
    class="text-gray-600 text-sm"
    :class="active_classes"
  >
    {{ truncate ? $filters.truncate(content, 15) : content }}
  </div>
</template>
