<script setup>
import { useModal } from 'vue-final-modal';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isString, pick } from 'lodash-es';
import { useThermStore } from '~/therm/store/therm.store';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { parseStringifiedObjectsInArray } from '~/common/utils/common.utils';

const props = defineProps({
  marker_location: {
    type: Object,
    default: () => null,
  },
});
const emit = defineEmits(['close', 'refreshSymbols']);
const $services = inject('$services');
const router = useRouter();
const route = useRoute();
const therm_store = useThermStore();
const tasks_store = useTasksStore('therm_tasks_store');
const template_store = useTasksStore('template');
const auth_store = useAuthStore();
const parsed_selected_features = computed(() => {
  return parseStringifiedObjectsInArray(therm_store.selected_features);
});
const get_target_element = computed(() => {
  if (therm_store.selected_features.length === 1)
    return parsed_selected_features.value[0]?.properties.element;

  return therm_store.projects_details_map[therm_store.last_selected_project?.uid]?.element;
});
const get_project_uid = computed(() => {
  if (parsed_selected_features.value.length === 1)
    return parsed_selected_features.value[0].properties.projectUid;

  return therm_store.last_selected_project?.uid;
});
const get_filtered_defects_for_tasks_creation = computed(() => {
  return parsed_selected_features.value.filter(
    item => !therm_store.tasks_hash[item.properties.uid],
  );
});
const { open: openTaskForm, close: closeTaskForm } = useModal({
  component: TaskForm,
  attrs: {
    task_type: 'task',
    prefix_feature_name: !!parsed_selected_features.value.length,
    onClose() {
      closeTaskForm();
      emit('close');
    },
    on_cancel() {
      emit('close');
    },
    async on_submit(data) {
      try {
        if (therm_store.use_tasks) {
          if (get_filtered_defects_for_tasks_creation.value.length) {
            const payload = get_filtered_defects_for_tasks_creation.value.map(item => ({
              ...item,
            }));

            await therm_store.create_therm_tasks({
              ...(data?.template_uid && {
                action: 'loadTemplate',
                include: {
                  attachments: true,
                  checklists: {
                    assignee: true,
                    dueDate: true,
                    attachments: true,
                    resolved: true,
                  },
                  tags: true,
                  bookmark: true,
                  archive: false,
                  watchers: true,
                  users: true,
                  labels: true,
                  assignee: true,
                },
                templateUid: data.template_uid,
              }),
              issues: payload,
            });
            closeTaskForm();
            emit('refreshSymbols');
            return;
          }
          if (
            parsed_selected_features.value.length
           && get_filtered_defects_for_tasks_creation.value.length === 0
          ) {
            closeTaskForm();
            emit('toggleCreation');
            return;
          }
        }
        const payload = await getPayload(data);
        let response = {};
        if (data.template_uid)
          response = await tasks_store.load_from_template({ tasks: payload }, { method: 'Direct' }, 'Created');
        else
          response = await tasks_store.create_tasks({ tasks: payload }, { view: 'Therm', method: 'Direct' });
        if (Object.keys(response || {}).length === 1)
          router.push({
            ...route,
            query: {
              task: btoa(JSON.stringify({
                id: Object.keys(response || {})[0],
                store_key: 'therm_tasks_store',
              })),
            },
          });

        emit('refreshSymbols');
      }
      catch (err) {
        logger.log(err);
      }
    },
  },
});

openTaskForm();

async function getPayload(data) {
  const turf = (await import('@turf/turf'));
  let payload = [];
  const template_payload = (data.template_uid
    ? {
        action: 'load_template',
        include: {
          assignees: !data.users?.length,
          attachments: true,
          category: !data.category,
          checklists: {
            assignee: true,
            attachments: true,
            dueDate: true,
            resolved: true,
          },
          due_date: !data.due_date,
          labels: true,
          priority: !data.priority,
          properties: false,
          schedule: true,
          start_date: !data.start_date,
          status: true,
          sub_tasks: true,
          tags: !data.tags?.length,
          users: !data.users?.length,
          watchers: true,
        },
      }
    : {});

  let elements;
  const location = props.marker_location
    ? turf.centroid({
      type: 'Point',
      coordinates: Object.values(props.marker_location),
    })
    : null;

  if (!parsed_selected_features.value.length) {
    payload = [{
      ...data,
      ...template_payload,
      organization: auth_store.current_organization?.uid,
      properties: {

        projectUid: get_project_uid.value,
      },

      target_element: get_target_element.value,
      location: location
        ? {
            type: 'Point',
            coordinates: location.geometry?.coordinates,
          }
        : null,
    }];
  }
  else {
    const non_created_elements = parsed_selected_features.value.reduce((acc, item) => {
      if (isString(item.element || item.properties.element))
        acc.push(item.uid || item.properties.uid);

      return acc;
    }, []);

    if (non_created_elements.length) {
      const response = await $services.therm_view_service.get_elements({
        body: {
          uids: non_created_elements,
        },
      });

      elements = response.data;
    }
    payload = parsed_selected_features.value.map((feature) => {
      const element_object = elements?.[feature.uid || feature.properties.uid] ?? (feature.element || feature.properties.element);

      const element = pick(element_object, ['uid', 'type', 'asset', 'stage']);

      let centroid;
      if (location)
        centroid = {
          geometry: {
            coordinates: location.geometry?.coordinates,
          },
        };
      else if (feature.uid)
        centroid = feature.boundary
          ? turf.centroid(feature.boundary)
          : null;

      else
        centroid = turf.centroid(feature);
      const task_name = data.template_uid && !data.name ? template_store.tasks_map[data.template_uid]?.name : data.name;
      let name = task_name || feature.properties?.name || '';
      if (data.prefix_feature_name)
        name = [feature.properties?.string_number || feature.properties?.name || '', task_name].join(' ');

      const body = {
        ...data,
        ...template_payload,
        target_element: element,
        name,
        properties: {
          ...((feature.properties.name || feature.name) && {
            reference_name: feature.properties?.string_number || feature.properties?.name || feature.name,
          }),

          projectUid: feature.properties.project || feature.properties.projectUid,
          issue_uid: feature.properties.uid,

        },

        organization: auth_store.current_organization?.uid,
        location: centroid
          ? {
              type: 'Point',
              coordinates: centroid.geometry.coordinates,
            }
          : null,
      };

      return body;
    });
  }
  return payload;
}
</script>

<template>
  <div />
</template>
