<script setup>
import { computed, ref } from 'vue';
import { cloneDeep, isNil, keyBy, min } from 'lodash-es';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import minMax from 'dayjs/plugin/minMax';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { compareObjects } from '~/common/utils/common.utils.js';
import WorkflowFormDetails from '~/terra/components/workflow/workflow-form-details.vue';
import FormDetailBulkUpdateCta from '~/forms/components/form-detail-bulk-update/form-detail-bulk-update-cta.vue';
import useEmitter from '~/common/composables/useEmitter';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  selected_feature: { type: Object, default: null },
  selected_feature_workflow: { type: Object, default: null },
  bulk_update: {
    type: Boolean,
    default: false,
  },
  selected_features: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['close']);
dayjs.extend(minMax);

const { createTerraForm, getFormPayload } = useTerraHelperComposable();

const emitter = useEmitter();
const $t = inject('$t');
const $services = inject('$services');
const auth_store = useAuthStore();
const forms_store = useFormsStore('terra_form_store');
const terra_store = useTerraStore();
const $toast = inject('$toast');
const form = ref({});
const form_values_copy = ref({});
const field_type_init_101 = ref(null);
const advance_options = ref(false);
// Move everything here
const state = reactive({
  continue_loading: false,
  workflow_association_error: null,
});
const columns = ref({
  lg: {
    container: 12,
    label: 4,
    wrapper: 12,
  },
});

const workflow_progress_for_a_single_feature = computed(() => {
  if (props.bulk_update)
    return {};
  const progress = props.selected_feature.properties.workflowProgress;

  Object.keys(progress).forEach((a) => {
    progress[a].current = +progress[a]?.current;
    progress[a].total = +progress[a]?.total;
  });

  return progress;
});
const marked_as_completed = computed(() => {
  return form.value.fields.filter(field => field.mark_as_completed).map(field => field.uid);
});
const all_fields_completed = computed(() => {
  if (props.selected_feature_workflow.progress_type === 'cumulative')
    return false;
  else
    return form.value.fields.every((field) => {
      return field.current === field.total || !props.selected_feature_workflow.required[field.uid];
    });
});
const is_continue_progress = computed(() => {
  return all_fields_completed.value && (isNil(field_type_init_101.value) || field_type_init_101.value?.current === 1) && props.selected_feature_workflow?.destination;
});
const next_feature = computed(() => {
  return (
    terra_store.feature_types_by_uid[props.selected_feature_workflow.destination]
      ?.name || props.selected_feature_workflow.destination
  );
});

const is_progress_values_dirty = computed(() => {
  // Check if the progress object has changed
  const original = form.value.fields?.map(item => ({ value: item.value || 0, current: item.current, reset: item.reset }));
  const copy = form_values_copy.value.fields?.map(item => ({ value: item.value || 0, current: item.current, reset: item.reset }));

  if (form.value.fields && form_values_copy.value.fields)
    return (
      !compareObjects(
        original,
        copy,
      ) || Object.values(marked_as_completed.value).some(data => data)
    );
  return false;
});

const fields_data = computed(() => {
  return props.selected_feature_workflow.fields.reduce((acc, field) => {
    acc[field.uid] = field;
    return acc;
  }, {});
});
const progress_data_map = computed(() => {
  return form.value.fields.reduce((acc, cur) => {
    acc[cur.uid] = cur;
    return acc;
  }, {});
});

const get_max_date = computed(() => {
  if (props.bulk_update) {
    const features_with_progress_timestamp = props.selected_features.filter(
      feature => feature.properties.workflowProgressTimestamp,
    );
    if (features_with_progress_timestamp.length)
      return dayjs.max(
        features_with_progress_timestamp.map(feature =>
          dayjs(feature.properties.workflowProgressTimestamp),
        ),
      )?.toDate();
  }
  else if (props.selected_feature?.properties?.workflowProgressTimestamp) {
    return dayjs(props.selected_feature?.properties?.workflowProgressTimestamp);
  }
  return null;
});

function getWorkflowFormProperties(field) {
  if (field)
    return {
      type: 'INTG_101',
      field: { uid: field.uid, name: field.name },
      destination: { uid: props.selected_feature_workflow?.destination, name: terra_store.feature_types_by_uid[props.selected_feature_workflow?.destination]?.name },
    };
  return {};
}

function updateField(index) {
  const field = form.value.fields[index];
  if (form.value.fields[index].mark_as_completed) {
    if (props.bulk_update)
      return;
    form.value.fields[index].value = workflow_progress_for_a_single_feature.value[field.uid]?.total
            - (workflow_progress_for_a_single_feature.value[field.uid]?.current || 0);
  }
  else {
    form.value.fields[index].value = 0;
  }
}

function getDestinationForCumulative(feature) {
  const new_cumulative_progress = props.selected_feature_workflow.fields.reduce(
    (acc, field) => {
      const weight
              = props.selected_feature_workflow.weights[field.uid] ?? 1;
      let current_value;
      let added_value;
      let total;
      if (props.bulk_update) {
        current_value
                = feature.properties.workflowProgress[field.uid]?.current;
        if (marked_as_completed.value.includes(field.uid))
          added_value
                  = feature.properties.workflowProgress[field.uid]?.total
                  - feature.properties.workflowProgress[field.uid]?.current;

        else added_value = progress_data_map.value[field.uid].value;
        total = feature.properties.workflowProgress[field.uid]?.total;
      }
      else {
        current_value = workflow_progress_for_a_single_feature.value[field.uid].current;
        // we dont have to do the deduction here because when the checkbox is checked it already means the value this.form.progress[field.uid].value is modfied
        added_value = progress_data_map.value[field.uid].value;
        total = workflow_progress_for_a_single_feature.value[field.uid].total;
      }

      const current_progress = (current_value + added_value) / total;
      return acc + current_progress * weight;
    },
    0,
  );

  let new_feature_type;
  for (const [feature_type, [min, max]] of Object.entries(
    props.selected_feature_workflow.destination,
  ))
    if (min <= new_cumulative_progress && new_cumulative_progress <= max)
      new_feature_type = feature_type;

  return new_feature_type || '';
}
function getFieldProgress(uid, feature, next_destination = null) {
  let added_value;
  let current_value;
  let total;
  if (props.bulk_update) {
    current_value = next_destination?.fields[uid]?.current ?? feature.properties.workflowProgress[uid].current;
    added_value = marked_as_completed.value.includes(uid)
      ? feature.properties.workflowProgress[uid]?.total
              - feature.properties.workflowProgress[uid]?.current
      : progress_data_map.value[uid]?.value;
    total = next_destination?.fields[uid]?.total ?? feature.properties.workflowProgress[uid]?.total;
  }
  else {
    if (uid === field_type_init_101.value?.uid) {
      current_value = field_type_init_101.value.current;
      added_value = field_type_init_101.value.value;
    }
    else {
      current_value = next_destination?.fields[uid].current ?? workflow_progress_for_a_single_feature.value[uid].current;
      added_value = progress_data_map.value[uid]?.value || 0;
    }
    total = next_destination?.fields[uid].total ?? workflow_progress_for_a_single_feature.value[uid].total;
  }

  return (current_value + added_value) / total;
}
function checkThresholdReached(uid, feature, next_destination = null) {
  const current_progress = getFieldProgress(uid, feature, next_destination);
  const threshold_per = next_destination?.required[uid] ?? props.selected_feature_workflow.required[uid];
  return current_progress >= threshold_per;
}
function checkCanStatusChange(feature, next_destination = null) {
  if (props.selected_feature_workflow.progress_type === 'cumulative') {
    const old_feature_type = feature?.properties?.featureType ?? props.selected_feature?.properties?.featureType;
    const new_feature_type = getDestinationForCumulative(feature);
    return new_feature_type !== old_feature_type;
  }
  return getThresholdNotReachedFields(feature, next_destination).length === 0;
}
function getThresholdNotReachedFields(feature, next_destination = null) {
  const fields = [];
  const required = next_destination?.required ?? props.selected_feature_workflow.required;
  for (const uid in required)
    if (!checkThresholdReached(uid, feature, next_destination)) {
      const total = next_destination?.fields[uid].total ?? (props.bulk_update
        ? feature.properties.workflowProgress[uid].total
        : workflow_progress_for_a_single_feature.value[uid].total);
      const current = next_destination?.fields[uid].current ?? (props.bulk_update
        ? feature.properties.workflowProgress[uid].current
        : workflow_progress_for_a_single_feature.value[uid].current);
      const threshold_per = required[uid];
      const threshold_value = total * threshold_per;

      fields.push({
        name: fields_data.value[uid]?.name,
        required: (threshold_value - current).toFixed(2),
      });
    }

  return fields;
}
async function updateFormProgress() {
  const body = {};
  const progress = {};
  if (!props.bulk_update && field_type_init_101.value)
    progress[field_type_init_101.value.uid] = {
      current: field_type_init_101.value.current + field_type_init_101.value.value,
      value: field_type_init_101.value.value,
      current_form: field_type_init_101.value.current_form,
      reset: true,
    };
  body[props.selected_feature?.properties?.uid] = {
    attachments: form.value.attachments.map(attachment => attachment?.service_object),
    category: form.value.category,
    new_feature_type: null,
    notes: form.value.notes || '',
    old_feature_type: props.selected_feature.properties.featureType,
    progress,
    status_changed: false,
    timestamp: form.value.timestamp,
    user: form.value.user?.uid,
  };
  const response = await $services.features.update_workflow_progress(
    {
      container_id: terra_store.container.uid,
      body,
    },
  );
  if (response?.data?.features?.length)
    await updateFeatures(response.data.features, false);
}

async function updateProgress(data, form) {
  const payload = {
    old_feature_type: props.selected_feature.properties.featureType,
    attachments: form.data.attachments.map(attachment => ({
      ...attachment?.service_object,
      uid: attachment?.uid || crypto.randomUUID(),
      file_name: attachment.name,
      file_size: attachment.size,
      file_type: attachment.type,
      created_at: form.data.timestamp || new Date().toISOString(),
    })),
    notes: form.data.notes || '',
    category: form.data.category,
    user: form.data.user?.uid,
    timestamp: form.data.timestamp,
  };
  const check_events_properties = {
    mark_as_complete: false,
    reset: false,
  };
  const body = {};
  props.selected_features.forEach((feature) => {
    const progress = {};

    form.data.fields.forEach((field) => {
      check_events_properties.mark_as_complete = check_events_properties.mark_as_complete || field.mark_as_completed;
      check_events_properties.reset = check_events_properties.reset || field.reset;

      if (props.bulk_update) {
        const progress_values = feature.properties.workflowProgress;
        let reset = false;
        let current = 0;
        let value = 0;

        if (marked_as_completed.value.includes(field.uid)) {
          current = progress_values[field.uid]?.total;
          value = progress_values[field.uid]?.total - progress_values[field.uid]?.current;
        }
        else {
          value = field.value; // Input box value;
          if (form.data.bulk_update_type === 'add')
            current = min([
              progress_values[field.uid]?.current + value,
              progress_values[field.uid]?.total,
            ]);

          else
            current = min([value, progress_values[field.uid]?.total]);

          value = current - progress_values[field.uid]?.current;
        }

        if (value < 0) {
          value = field.value; // Input box value;
          if (form.data.bulk_update_type === 'set')
            reset = true;
        }

        progress[field.uid] = { current, value, ...(reset && { reset }) };
      }
      else {
        progress[field.uid] = { current: (field.current + field.value) || 0, value: field.value || 0, reset: field.reset };
      }
    });

    if (!props.bulk_update && field_type_init_101.value)
      progress[field_type_init_101.value.uid] = {
        current: field_type_init_101.value.current + field_type_init_101.value.value,
        value: field_type_init_101.value.value,
        current_form: field_type_init_101.value.current_form,
      };
    const status_changed = checkCanStatusChange(feature);
    let new_feature_type = null;
    if (status_changed)
      if (props.selected_feature_workflow.progress_type === 'cumulative') { new_feature_type = getDestinationForCumulative(feature); }
      else {
        new_feature_type = props.selected_feature_workflow.destination;
        let next_destination = cloneDeep(terra_store.terra_workflows?.[
          terra_store.selected_features[0]?.properties.workflow
        ]?.data[new_feature_type]);
        if (next_destination?.fields)
          next_destination.fields = keyBy(next_destination?.fields, 'uid');
        let auto_increment_steps = 1;
        const incrementStep = () => {
          new_feature_type = next_destination?.destination;
          next_destination = cloneDeep(
            terra_store.terra_workflows[feature.properties.workflow]?.data[
              new_feature_type
            ],
          );
          if (next_destination) {
            next_destination.fields = keyBy(
              next_destination.fields,
              'uid',
            );
            auto_increment_steps += 1;
          }
          else {
            auto_increment_steps = 0;
          }
        };

        while (auto_increment_steps) {
          const status_changed = checkCanStatusChange(feature, next_destination);

          if (status_changed && next_destination)
            incrementStep();
          else auto_increment_steps = 0;
        }
      }

    body[feature?.properties?.uid] = {
      ...payload,
      progress,
      status_changed,
      new_feature_type,
    };
  });
  logger.log(body);
  const response = await $services.features.update_workflow_progress(
    {
      container_id: terra_store.container.uid,
      body,
      // query: {
      //   dry_run: true,
      // },
    },
  );
  $toast({
    text: 'Progress updated successfully',
    type: 'success',
  });
  terra_store.gallery_view_state.show_load_progress_attachments ||= !!form.data.attachments.length;

  const common_properties = {
    attachments: form.data.attachments.length,
    notes: !!form.data.notes,
    category: !!form.data.category,
    pre_dated: !!form.data.timestamp,
    on_behalf: !!form.data.user?.uid,
    count: props.selected_features.length,
  };
  const uid = terra_store.selected_features[0]?.properties?.uid;
  terra_store.terra_track_events('Progress updated', {
    ...common_properties,
    mark_as_complete: check_events_properties.mark_as_complete,
    uid,
  });

  if (check_events_properties.reset)
    terra_store.terra_track_events('Progress reset', { ...common_properties, uid });

  if (response?.data?.features?.length)
    await updateFeatures(response.data.features);

  emit('close');
}
async function continueProgress() {
  try {
    state.continue_loading = true;
    const payload = {};
    props.selected_features.forEach((feature) => {
      logger.log(form.value);
      payload[feature?.properties?.uid] = {
        progress: form.value.fields.reduce((acc, item) => {
          acc[item.uid] = {
            current: item.current, total: item.total, value: item.value,
          };
          return acc;
        }, {}),
        status_changed: true,
        new_feature_type: props.selected_feature_workflow?.destination,
        attachments: form.value?.attachments.map(attachment => attachment?.service_object),
        old_feature_type: props.selected_feature.properties.featureType,
        notes: form.value.notes || '',
        category: form.value.category,
        user: form.value.user?.uid,
        timestamp: form.value.timestamp,
      };
    });
    const response = await $services.features.update_workflow_progress(
      {
        container_id: terra_store.container.uid,
        body: payload,
      // query: {
      //   dry_run: true,
      // },
      },
    );

    $toast({
      text: 'Progress updated successfully',
      type: 'success',
    });
    if (response?.data?.features?.length)
      await updateFeatures(response.data.features);
    state.continue_loading = false;
  }
  catch (err) {
    state.continue_loading = false;
  }
  emit('close');
}
async function updateFeatures(features, clear = true) {
  logger.log({ features });
  features = features.map(feature => ({
    ...feature,
    properties: {
      ...feature.properties,
      oldfeatureTypeId: terra_store.feature_types_by_uid[
        terra_store.selected_features[0]?.properties?.featureType
      ]?.id,
    },
  })); // THIS IS FOR DUPLICATION ISSUE IN STATE
  terra_store.selected_features = features;
  setSessionStorage();
  await terra_store.create_or_update_selected_features({
    reselectFeatures: true,
    updateFeatureRequest: false,
  });
}
function setSessionStorage() {
  const checkStorageAndSet = (key, data) => {
    if (data)
      sessionStorage.setItem(key, data);
    else sessionStorage.removeItem(key);
  };
  ['terra_workflow_timestamp', 'terra_workflow_category'].forEach((item) => {
    checkStorageAndSet(item, form.value[item.split('_')[2]]);
  });
  checkStorageAndSet('terra_workflow_user', JSON.stringify(form.value.user));
}

function getCumulativeToastMessage() {
  if (checkCanStatusChange()) {
    const next_feature
            = terra_store.feature_types_by_uid[getDestinationForCumulative()]
              ?.name || getDestinationForCumulative();
    const next_feature_sanitized = DOMPurify.sanitize(next_feature);
    return `<div>${$t('The status will be updated to')}<span> ${next_feature_sanitized}</span></div>`;
  }
}

function getToastMessage(fields) {
  const next_feature_sanitized = DOMPurify.sanitize(next_feature.value);
  if (fields.length) {
    const fields_text = fields
      .map((item) => {
        return `<li>${item.name}: ${item.required}</li>`;
      })
      .join('');
    const fields_text_sanitized = DOMPurify.sanitize(fields_text);

    return `
            <div>
              ${$t('Need the following quantities to update')} <span> ${next_feature_sanitized}</span>
            </div>
            <ul reversed>
                ${fields_text_sanitized}
            </ul>`;
  }
  else {
    return `<div>${$t('The status will be updated to')} <span> ${next_feature_sanitized}</span></div>`;
  }
}

function showToast() {
  if (props.bulk_update)
    return;
  let message;
  if (props.selected_feature_workflow.progress_type === 'cumulative') {
    message = getCumulativeToastMessage();
  }
  else {
    const fields = getThresholdNotReachedFields().reverse();

    message = getToastMessage(fields);
  }
  if (message)
    $toast({
      html_text: message,
      type: 'info',
      timeout: 2000,
      // position: 'top-center',
    });
}

function toggleAdvancedOption() {
  advance_options.value = !advance_options.value;
  sessionStorage.setItem('terra_workflow_advance_options', advance_options.value);
}

async function formSubmitCallback(data) {
  if (data.uid === field_type_init_101.value.current_form?.uid && data.data?.status?.submission_status === 'submitted') {
    field_type_init_101.value.current = 0;
    field_type_init_101.value.value = 1;
    field_type_init_101.value.current_form.status = 'submitted';

    await updateFormProgress();
    field_type_init_101.value.current = 1;
    field_type_init_101.value.value = 0;
  }
}

// TODO: Temporary fix will do a refactor
function initializeEmit() {
  if (props.bulk_update)
    return;
  deactivateEmits();
  emitter.on('form_submit', formSubmitCallback);
  const events = ['form_deleted', 'form_rollback', 'form_reopen'];
  events.forEach((event) => {
    emitter.on(event, deselectFeatures);
  });
}

function deselectFeatures() {
  terra_store.selected_features = [];
}

function deactivateEmits() {
  emitter.off('form_deleted', deselectFeatures);
  emitter.off('form_rollback', deselectFeatures);
  emitter.off('form_reopen', deselectFeatures);
  emitter.off('form_submit', formSubmitCallback);
}

onUnmounted(() => {
  if (!props.bulk_update)
    deactivateEmits();
});

function initForm() {
  try {
    let fields = {};
    const workflow = terra_store.terra_workflows?.[
      terra_store.selected_features[0]?.properties.workflow
    ]?.data;
    Object.values(workflow).forEach((item) => {
      fields = { ...fields, ...keyBy(item.fields, 'uid') };
    });
    Object.keys(fields).forEach((field) => {
      if (!props.bulk_update) {
        if (!workflow_progress_for_a_single_feature.value?.[field]) {
          $toast({
            text: `${fields[field].name} is not associated with ${props.selected_feature?.properties?.name || 'the feature'}. Associate workflow again to fix`,
            type: 'error',
            position: 'top-center',
            has_dismiss_button: false,
            timeout: 20000,
          });
          state.workflow_association_error = `${fields[field].name} is not associated with ${props.selected_feature?.properties?.name || 'the feature'}. Associate workflow again to fix`;
          throw new Error('Association error');
        }
      }
      else {
        const field_not_associated_in_feature_index = terra_store.selected_features.findIndex(
          item => !item.properties.workflowProgress[field],
        );
        if (field_not_associated_in_feature_index !== -1) {
          $toast({
            text: `${fields[field]?.name} is not associated with ${terra_store.selected_features[
                  field_not_associated_in_feature_index
                ]?.properties?.name || 'a feature'}. Associate workflow again to fix`,

            type: 'error',
            position: 'top-center',
            has_dismiss_button: false,
            timeout: 20000,

          });
          state.workflow_association_error = `${fields[field]?.name} is not associated with ${terra_store.selected_features[
                  field_not_associated_in_feature_index
                ]?.properties?.name || 'a feature'}. Associate workflow again to fix`;
          throw new Error('Association error');
        }
      }
    });
    form.value.fields = props.selected_feature_workflow.fields.reduce((acc, field) => {
      const field_data = { ...workflow_progress_for_a_single_feature.value?.[field.uid], ...field, value: 0, current: props.bulk_update ? 0 : workflow_progress_for_a_single_feature.value?.[field.uid]?.current };
      if (field_data.type === 'INTG_101')
        // For bulk selected features getting all selected forms
        if (props.bulk_update) {
          field_type_init_101.value = terra_store.selected_features.reduce((acc, curr) => {
            const form_uid = curr.properties.workflowProgress?.[field.uid]?.current_form?.uid;
            if (form_uid && forms_store.forms_map?.[form_uid])
              acc.selected_forms.push(forms_store.forms_map[form_uid]);
            else
              acc.features.push(curr);
            return acc;
          }, { selected_forms: [], features: [], field: field_data });
          field_type_init_101.value.createFormsCallback = async () => {
            try {
              const sync_api_payload = [];

              const workflow_form_properties = getWorkflowFormProperties(field_data);
              const form_data = getFormPayload(field_data);
              const data = { forms: { add: [form_data] }, prefix_feature_name: true, form_uid: form_data.template_uid };
              const { response } = await createTerraForm(data, { workflow_form_properties, workflow_form: true, features: field_type_init_101.value.features, do_not_open_form_details: true });
              response.forms.added.forEach((form) => {
                sync_api_payload.push({
                  event: 'FORMS_CREATED',
                  feature: form.properties.integration?.feature?.uid,
                  field: form.properties.integration?.field?.uid,
                  form: {
                    uid: form.uid,
                    status: 'open',
                  },
                });
              });

              await terra_store.syncApiForInspectionForms(null, { payload: sync_api_payload });

              emitter.emit('form_create');
              return response.forms.added;
            }
            catch (error) {
              logger.log('Failed to create forms', error);
              return [];
            }
          };
        }
        // for a single selected feature getting form details
        else {
          field_type_init_101.value = field_data;
        }

      else
        acc.push(field_data);

      return acc;
    }, []);
    for (const item of ['terra_workflow_timestamp', 'terra_workflow_category'])
      if (sessionStorage.getItem(item))
        form.value[item.split('_')[2]] = sessionStorage.getItem(item);
    const user_data = sessionStorage.getItem('terra_workflow_user');
    if (user_data && (user_data[0] + user_data.slice(-1)) === '{}')
      form.value.user = JSON.parse(user_data);
    form_values_copy.value = cloneDeep(form.value);
    if (sessionStorage.getItem('terra_workflow_advance_options'))
      advance_options.value = Boolean(JSON.parse(sessionStorage.getItem('terra_workflow_advance_options')));
  }
  catch (err) {
    logger.log(err);
  }
}

initForm();
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" sync size="sm" :display-errors="false" :endpoint="updateProgress"
      :attachment_config="{
        meta: {
          service: 'terra',
          id: selected_feature?.properties?.uid || 'upload',
        },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              <div v-if="!bulk_update">
                <span class="text-[18px] font-medium">
                  {{ selected_feature.properties.name || $t("Untitled") }}
                </span>
                <div class="text-[14px] font-normal -mt-1">
                  {{ $t("Update today's progress") }}
                </div>
              </div>
              <div v-else>
                <span class="text-[18px] font-medium">
                  {{ $t("Update progress") }}
                </span>
                <span class="text-[14px]">
                  ({{ selected_features.length }})
                </span>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false" class="p-1 max-h-[calc(100vh-40vh)] w-[600px] scrollbar !overflow-x-hidden">
          <RadiogroupElement
            v-if="bulk_update"
            class="mb-3"
            name="bulk_update_type"
            :items="[
              { label: 'Add quantities', value: 'add' },
              { label: 'Set quantities', value: 'set' },
            ]"
            :inline="true"
            :add-classes="{
              RadiogroupRadio: {
                container: 'mr-5 flex text-[14px]',
                text: 'font-medium text-[14px]',
              },
            }"
            default="add"
            :remove-class="{
              wrapper: ['flex-col'],
            }"
          >
            <template #item="{ item }">
              <v-radio :value="item.value">
                {{ item.label }}
              </v-radio>
            </template>
          </RadiogroupElement>
          <ListElement
            v-show="!state.workflow_association_error"
            name="fields"
            :rules="[
              'required',
            ]"
            :controls="{
              add: false,
              remove: false,
            }"
          >
            <template #default="{ index }">
              <ObjectElement :name="index">
                <div class="col-span-12">
                  <div class="flex flex-wrap gap-4 items-start justify-between py-3 -mt-5">
                    <div class="w-[360px] mt-3">
                      <QuantityElement
                        :label="`${form.fields[index].name} ${$t('today')} (${form.fields[index].units || 'nos'})`"
                        name="value"
                        :columns="{
                          lg: {
                            container: 12,
                            label: 6,
                            wrapper: 9,
                          },
                        }"
                        :min="0"
                        :max="bulk_update ? Number.MAX_SAFE_INTEGER : workflow_progress_for_a_single_feature?.[form.fields[index].uid]?.total
                          - form.fields[index].current
                        "
                        :rules="!bulk_update && selected_feature_workflow?.required?.[form.fields[index].uid] ? ['required', 'integer', 'nullable'] : ['integer']"

                        :disabled="
                          form.fields[index].mark_as_completed === true || form.fields[index].current
                            === workflow_progress_for_a_single_feature?.[form.fields[index].uid]?.total
                        "
                        :disable_input_clear="true"
                      >
                        <template #after>
                          <div v-if="!bulk_update" class="min-w-[200px] mt-0.75">
                            {{ form.fields[index]?.current }}/{{
                              workflow_progress_for_a_single_feature?.[form.fields[index].uid]?.total
                            }}
                            {{ form.fields[index]?.units }} {{ $t("finished") }}
                            <span v-show="form.fields[index].current !== 0" class="ml-1 font-semibold text-gray-600 cursor-pointer" @click="form.fields[index].current = 0, form.fields[index].reset = true">{{ $t('Reset') }}</span>
                          </div>
                        </template>
                      </QuantityElement>
                    </div>
                    <div v-show="workflow_progress_for_a_single_feature?.[form.fields[index].uid]?.current !== workflow_progress_for_a_single_feature?.[form.fields[index].uid]?.total || bulk_update" class="w-[160px] -mr-[68px] mt-3">
                      <CheckboxElement name="mark_as_completed" :default="false" :columns="{ container: 12, label: 12, wrapper: 3 }" :add-classes="{ CheckboxElement: { text: 'text-sm font-medium text-gray-700' } }" @change="updateField(index)">
                        {{ $t('Completed') }}
                      </CheckboxElement>
                    </div>
                  </div>
                </div>
              </ObjectElement>
            </template>
          </ListElement>
          <MultifileElement
            name="attachments"
            label="Attach Pictures"
            accept="image/*"
            :auto="false"
            :drop="true"
            :file="{
              rules: [
                'mimetypes:image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/tiff',
              ],
            }"
            :columns="columns"
            class="mt-4"
          />
          <TextareaElement
            label="Notes"
            name="notes"
            :placeholder="$t('Add notes here')"
            :columns="columns"
            class="my-4"
          />
          <template v-if="field_type_init_101">
            <FormDetailBulkUpdateCta
              v-if="auth_store.check_split('bulk_fill_forms') && bulk_update"
              :selected_forms="field_type_init_101.selected_forms"
              :forms_store="forms_store"
              :template_uid="field_type_init_101.field.config.template"
              :selected_features_without_forms="field_type_init_101.features"
              :create_forms_callback="field_type_init_101.createFormsCallback"
            />
            <WorkflowFormDetails
              v-else-if="!bulk_update"
              :field_details="field_type_init_101"
              :workflow_form_properties="getWorkflowFormProperties(field_type_init_101)"
              @initializeEmit="initializeEmit"
              @formCreated="($event) => {
                field_type_init_101.current_form = $event;
                updateFormProgress();
                initializeEmit()
              }"
            />
          </template>
          <div class="flex cursor-pointer font-semibold text-[14px] my-4 -ml-2 items-center text-primary-700" @click="toggleAdvancedOption">
            <IconHawkChevronRight v-if="!advance_options" class="w-4 h-4 text-primary-700 mr-1" />
            <IconHawkChevronDown v-else class="w-4 h-4 text-primary-700 mr-1" />
            {{ $t('Advanced options') }}
          </div>
          <div v-show="advance_options">
            <hawk-category-input
              class="mt-4"
              :options="{
                name: 'category',
                label: $t('Category'),
                description: $t('Select machine, trade, etc'),
                placeholder: $t('Select'),
                multi: false,
                canClear: true,
                columns,
              }"
            />
            <hawk-assignee-input
              class="mt-4"
              :options="{
                name: 'user',
                label: $t('Member'),
                description: $t('Select the user who made the update'),
                canClear: true,
                multi: false,
                placeholder: $t('Select'),
                size: 'sm',
                columns,
              }"
              :has_teams="false"
              :multi="false"
            />
            <DateTimeElement
              name="timestamp"
              :columns="columns"
              class="mt-4"
              :label="$t('Date')"
              :description="$t('Choose the date when the progress is reported')"
              :options="{
                'format': 'EEEE, MMMM dd yyyy',
                'placeholder': $t('Select Date'),
                'min-date': get_max_date,
                'max-date': dayjs(),
                'model-type': 'yyyy-MM-dd',
              }"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="!state.workflow_association_error" #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                :secondary="true"
                @click="$emit('close')"
              />
              <ButtonElement v-if="!bulk_update && is_continue_progress" name="continue" button-class="w-full bg-blue-600" :loading="state.continue_loading" @click="continueProgress">
                {{ $t("Continue") }}
              </ButtonElement>
              <ButtonElement v-else v-tippy="!terra_store.check_permission('update_progress') && $t('You do not have permissions to update progress.')" :disabled="!terra_store.check_permission('update_progress') || !is_progress_values_dirty" button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t("Update Progress") }}
              </ButtonElement>
            </div>
          </template>
          <template v-else #right>
            <HawkAlertBanner
              color="error"
            >
              <template #icon>
                <IconHawkAlertTriangle />
              </template>
              <template #content>
                {{ state.workflow_association_error }}
              </template>
            </HawkAlertBanner>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
