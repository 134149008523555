<script setup>
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  fields: {
    type: Array,
    default: () => ([]),
  },
});

const emit = defineEmits(['close', 'save']);

const inventory_store = useInventoryStore();

const initial_data = ref([]);

const form_data = ref({
  data: [],
});

const custom_fields = computed(() =>
  inventory_store.get_custom_fields({ attached_to: 'item' }, true),
);

const activities_tree = computed(() => [
  {
    label: 'General',
    name: 'item',
    uid: 'item',
    children: [
      {
        label: 'Name',
        name: 'name',
        uid: 'name',
      },
      {
        label: 'Number',
        name: 'number',
        uid: 'number',
      },
      {
        label: 'Type',
        name: 'category',
        uid: 'category',
      },
      {
        label: 'Description',
        name: 'description',
        uid: 'description',
      },
      {
        label: 'Quantity',
        name: 'physical_stock_quantity',
        uid: 'physical_stock_quantity',
      },
      {
        label: 'Scope',
        name: 'scope',
        uid: 'scope',
      },
      {
        label: 'UOM',
        name: 'uom',
        uid: 'uom',
      },
      {
        label: 'Remaining quantity',
        name: 'remaining_quantity',
        uid: 'remaining_quantity',
      },
      {
        label: 'Delivered quantity',
        name: 'delivered_quantity',
        uid: 'delivered_quantity',
      },
      {
        label: '% Delivered',
        name: 'delivered_percent',
        uid: 'delivered_percent',
      },
      // ...custom_fields.value.map(field => ({ label: field.name, name: field.uid, uid: field.uid })),
    ],
  },
  {
    label: 'Transacted quantities',
    name: 'workflow',
    uid: 'workflow',
    children: inventory_store.workflows.map(wf => ({ label: wf.quantities_label, name: 'workflow', uid: wf.uid })),
  },
  {
    label: 'Status',
    name: 'status',
    uid: 'status',
    children: inventory_store.statuses.map(status => ({ label: status.name, name: 'status', uid: status.uid })),
  },
]);

function emitUpdate() {
  if (!form_data.value?.data) {
    emit('close');
    return;
  }

  emit('save', form_data.value.data);
}

onMounted(() => {
  if (props.fields?.length)
    initial_data.value = props.fields;
});
</script>

<template>
  <HawkModalTemplate @close="emit('close')">
    <template #header>
      <div class="flex items-center justify-between p-4">
        <div class="text-lg font-semibold">
          {{ $t('Select fields') }}
        </div>
        <div class="text-gray-600 rounded-md hover:bg-gray-50 cursor-pointer flex justify-center items-center p-2 ml-2" @click="emit('close')">
          <IconHawkXClose />
        </div>
      </div>
      <hr>
    </template>
    <div class="flex flex-col justify-between w-[600px]">
      <Vueform
        v-model="form_data"
        size="sm"
        class="w-full [&>*]:grid-cols-1"
        :add-classes="{
          CheckboxElement: {
            text: 'whitespace-nowrap',
          },
        }"
      >
        <HawkTreeSelect
          :key="initial_data?.length"
          show_tree_select
          :show_expanded_search="true"
          :options="{
            name: 'data',
            search: true,
          }"
          data_type="object"
          select_type="LEAF_PRIORITY"
          :expand_all_items="true"
          :data="activities_tree"
          :initial_state="initial_data"
          children_key="children"
          label_key="label"
          value_key="uid"
          class="flex flex-col justify-between w-[600px]"
          add_classes="!h-[500px] border border-gray-300 border-solid rounded-lg"
          @updateForm="form_data.data = $event"
        />
      </Vueform>
    </div>
    <template #footer>
      <Vueform size="sm">
        <div class="flex justify-end w-full col-span-full">
          <ButtonElement
            name="cancel"
            class="mr-4"
            :secondary="true"
            @click="emit('close')"
          >
            {{ $t('Cancel') }}
          </ButtonElement>
          <ButtonElement
            name="save"
            @click="emitUpdate"
          >
            {{ $t('Save') }}
          </ButtonElement>
        </div>
      </Vueform>
    </template>
  </HawkModalTemplate>
</template>
