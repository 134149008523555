<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useThermStore } from '../store/therm.store';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import DefectStatus from '~/therm/components/atoms/defects-status.vue';
import DefectPriority from '~/therm/components/atoms/defects-priority.vue';
import DefectDetailsSlider from '~/therm/components/defect-details/defect-details-slider.vue';

const props = defineProps({
  table_data: {
    type: Array,
    required: true,
    default: () => [],
  },
});
const emit = defineEmits(['selectDefects', 'setDefectTableInstance']);
const $date = inject('$date');
const therm_store = useThermStore();

const router = useRouter();
const route = useRoute();
const columns = [
  {
    id: 'select',
    header: '',
    accessorKey: 'select',
    size: 15,
    enableSorting: false,
    enableResizing: false,
  },
  {
    header: 'Name',
    accessorKey: 'name',
    id: 'name',
    font_medium: true,
    size: 250,
  },
  {
    header: 'Inverter',
    accessorKey: 'invertor',
    id: 'invertor',
  },
  {
    header: 'String',
    accessorKey: 'string_number',
    id: 'string_number',
  },
  {
    header: 'Defect Type',
    accessorKey: 'defect_type',
    id: 'defect_type',
    sortingFn: (rowA, rowB) => {
      const a = therm_store.feature_types[rowA.original?.featureTypeId]?.name;
      const b = therm_store.feature_types[rowB.original?.featureTypeId]?.name;
      return (a > b) - (b > a);
    },
  },
  {
    header: 'Assignee',
    accessorKey: 'assignees',
    id: 'assignees',
    size: 180,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    id: 'status',
    size: 180,
  },
  {
    header: 'Priority',
    accessorKey: 'priority',
    id: 'priority',
    size: 180,
  },
  {
    header: 'Due Date',
    accessorKey: 'due_date',
    id: 'due_date',
    is_task_property: true,
  },
  {
    header: 'Timestamp',
    accessorKey: 'timestamp',
    id: 'timestamp',
  },
  {
    header: 'ΔT',
    accessorKey: 'temperature_difference',
    id: 'temperature_difference',
    sortingFn: 'basic',
  },
  {
    header: 'Tags',
    accessorKey: 'tags',
    id: 'tags',
    is_task_property: true,
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
    static: true,
  },
];
const state = reactive({
  selected_row: null,
});

const defects_table_data = computed(() => props.table_data.map((defect) => {
  const task = therm_store.tasks_hash?.[defect.uid] || {};
  const show_task = therm_store.use_tasks;
  return {
    ...defect,
    show_task,
    task,
    status: show_task ? task.status : defect.status,
    priority: show_task ? task.priority : defect.priority,
    assignees: show_task ? task.assignees : defect.assignees,
  };
}));
const filtered_columns = computed(() => columns.filter(val => !val.is_task_property || val.is_task_property === therm_store.use_tasks).map(column => ({ ...column, static: true })));
const custom_view_configuration = computed(() => ({
  service: 'therm',
  resource_type: 'defects',
  feature: 'list_view',
  name: route.params.id,
  store_key: 'therm_defects_list_view',
  modify_permissions: true,
}));
function handleSelectRow(e) {
  emit('selectDefects', e.map(defect => defect.original));
}
function handleRowClick(e, clear = false) {
  state.selected_row = e;
  therm_store.selected_features = clear ? [] : [therm_store.features_hash[e.uid]];
}
</script>

<template>
  <div>
    <TableWrapperVue container_class="mt-2 pr-4">
      <HawkTable
        :key="filtered_columns.length"
        class="rounded-xl"
        :custom_view_configuration="custom_view_configuration"
        :pagination_config="{ totalRows: table_data.length, pageSize: 25 }"
        :data="defects_table_data"
        :columns="filtered_columns"
        :is_loading="false"
        is_gapless
        @selectRow="handleSelectRow"
        @rowClicked="handleRowClick($event)"
        @tableInstance="emit('setDefectTableInstance', $event)"
      >
        <template #name="name">
          <div>
            <HawkText
              class="text-sm font-medium text-gray-900"
              :content="`${name.data.row.original?.string_number} ${
                therm_store.feature_types[name.data.row.original?.featureTypeId]
                  ? ` : ${
                    therm_store.feature_types[name.data.row.original?.featureTypeId]?.name}`
                  : ''
              }` || name.data.row.original?.name" :length="30"
            />
          </div>
        </template>
        <template #invertor="invertor">
          <div>
            <p class="font-normal text-gray-600 text-sm">
              {{ therm_store.active_group.projects[invertor.data.row.original?.projectUid]?.name }}
            </p>
          </div>
        </template>
        <template #defect_type="defect_type">
          <div>
            <p class="font-normal text-gray-600 text-sm">
              {{ therm_store.feature_types[defect_type.data.row.original?.featureTypeId]?.name }}
            </p>
          </div>
        </template>

        <template #assignees="assignees">
          <div v-if="assignees.data.getValue()?.length">
            <HawkMembers class="font-medium" :members="assignees.data.getValue()" type="badge" />
          </div>
          <template v-else>
            -
          </template>
        </template>
        <template #status="{ data: { row: { original } } }">
          <template v-if="original.status">
            <DefectStatus v-if="!original.show_task" :status="original.status" />
            <TaskStatus v-else :status="original.status" />
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #priority="{ data: { row: { original } } }">
          <template v-if="original.priority">
            <DefectPriority v-if="!original.show_task" :priority="original.priority" />
            <TaskPriorityDropdown v-else :priority="original.priority" />
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #due_date="{ data: { row: { original } } }">
          <div v-if="original?.task?.due_date">
            <span class="font-normal text-gray-600 text-sm">
              {{ $date(original.task.due_date, 'DD MMMM YYYY') }}
            </span>
          </div>
          <template v-else>
            -
          </template>
        </template>
        <template #timestamp="timestamp">
          <p class="font-normal text-gray-600 text-sm">
            {{
              timestamp.data.row.original?.timestamp
                ? timestamp.data.row.original?.timestamp
                : $t("NA")
            }}
          </p>
        </template>
        <template #temperature_difference="temperature_difference">
          <p class="font-normal text-gray-600 text-sm">
            {{ `${temperature_difference.data.row.original?.temperature_difference.toFixed(2)} °C` }}
          </p>
        </template>
        <template #tags="{ data: { row: { original } } }">
          <div v-if="original?.task?.tags?.length">
            <p v-tippy="original.task.tags.join(',')" class="font-normal text-gray-600 text-sm">
              {{ original.task.tags.length }}
            </p>
          </div>
          <template v-else>
            -
          </template>
        </template>
      </HawkTable>
    </TableWrapperVue>
    <DefectDetailsSlider
      :defect_details="therm_store.features_hash?.[state.selected_row?.uid]?.properties"
      @close="handleRowClick(null, true)"
    />
  </div>
</template>
