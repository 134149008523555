<script setup>
import { useFormsStore } from '~/forms/store/forms.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  selected_forms: {
    type: Array,
    default: () => [],
  },
  forms_store: { type: Object },
  fields: {
    type: Array,
    default: () => ['Status', 'Assignees', 'Due Date', 'Category'],
  },
});
const emit = defineEmits(['close', 'refresh']);
const data = ref(null);
const forms_store = props.forms_store || useFormsStore();
const common_store = useCommonStore();
const auth_store = useAuthStore();

const has_permission = computed(() => props.selected_forms.map(form => form?.can_modify).filter(permission => permission).length > 0);

function formatAssignee(form_data) {
  const members_add_array = [];
  const members_remove_array = [];
  const teams_add_array = [];
  const teams_remove_array = [];
  form_data?.assignees_group.assignees?.length && form_data?.assignees_group.assignees.forEach((item) => {
    const members_obj = {};
    const teams_obj = {};
    if (item.member) {
      members_obj.uid = item.uid;
      members_obj.access = 'assignee';
      members_add_array.push(item.uid);
      members_remove_array.push(item.uid);
    }
    else {
      teams_obj.uid = item.uid;
      teams_obj.access = 'assignee';
      teams_add_array.push(item.uid);
      teams_remove_array.push(item.uid);
    }
  });

  if (form_data?.assignees_group?.assignees_option)
    if (form_data?.assignees_group?.assignees_option === 'Assign')
      return { add: [...members_add_array, ...teams_add_array] };
    else
      return { remove: [...members_remove_array, ...teams_remove_array] };
  else
    return {};
}

function payloadFormat() {
  const arr = [];
  props.selected_forms.forEach((element) => {
    const obj = {};
    obj.uid = element.uid;
    obj.name = element.name;
    if (data.value.assignees_group)
      obj.assignees = formatAssignee(data.value);
    if (data?.value?.due_date)
      obj.due_date = new Date(data?.value?.due_date).toISOString();
    if (data?.value?.category)
      obj.category = data?.value?.category;
    arr.push(obj);
  });
  return arr;
}

async function onSave() {
  await forms_store.update_forms({ body: { forms: { update: payloadFormat() } } }, 'bulk');
  const count = props.selected_forms.length;
  if (count) {
    const properties = [];
    if (data?.value?.assignees_group?.assignees?.length)
      properties.push('Assignees');
    if (data?.value?.due_date)
      properties.push('Due date');
    if (data?.value?.category)
      properties.push('Category');
    properties.forEach((property) => {
      forms_store.forms_track_events(`${property} updated`, props.selected_forms[0].uid, { mode: count > 1 ? 'Bulk' : 'Single', count });
    });
  }
  emit('refresh');
  emit('close');
}
</script>

<template>
  <hawk-modal-container content_class="!w-[650px]">
    <Vueform
      v-model="data"
      :endpoint="onSave"
      size="sm"
      :display-errors="false"
      :presets="['']"
      sync
      :float-placeholders="false" class="grid grid-rows-1 grid-flow-col gap-4" :columns="{
        default: { container: 12 },
        sm: { container: 12, label: 12 },
        md: { container: 12, label: 4 },
      }"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('update') }} {{ selected_forms.length }} {{ $t('forms') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <template v-for="field in has_permission ? fields : []">
            <!-- Assignees -->
            <ObjectElement
              v-if="field === 'Assignees'"
              :key="field"
              class="mb-4"
              name="assignees_group"
              :label="$t('Assignees')"
            >
              <RadiogroupElement
                name="assignees_option"
                :items="[
                  { value: 'Assign', label: $t('Assign') },
                  { value: 'Unassign', label: $t('Unassign') },
                ]"
              />
              <hawk-assignee-input

                :add-classes="{
                  ElementLabel: {
                    container: ['hidden'],
                  },
                  ElementLayout: {
                    innerContainer: ['md:!col-span-12'],
                  },
                }"
                class="mb-6"
                :options="{
                  name: 'assignees',
                  has_teams: true,
                  placeholder: $t('select members and teams'),
                  rules: [{
                    required: ['assignees_group.assignees_option', ['Assign', 'Unassign']],

                  },
                  ],
                }"
                :multi="true"
              />
            </ObjectElement>
            <!-- Due Date -->
            <DateTimeElement
              v-if="field === 'Due Date'"
              :key="field"
              :options="{
                format: 'dd/MM/yyyy',
                minDate: new Date(),
                placeholder: $t('Select due date'),
              }"
              class="mb-4" name="due_date"
              :label="$t('Due date')"
            />
            <!-- Category -->
            <hawk-category-input
              v-if="field === 'Category'"
              :key="field" class="mb-4" :options="{
                name: 'category',
                label: $t('Category'),
                placeholder: $t('Select Category'),
                tags_removable: true,
              }"
            />
          </template>
          <FormDetailBulkUpdateCta v-if="auth_store.check_split('bulk_fill_forms')" :selected_forms="selected_forms" :forms_store="forms_store" />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class=" flex justify-end text-sm font-medium">
              <hawk-button
                class="mr-3"
                type="outlined"
                @click="emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                button-class="w-full bg-blue-600" name="submit" :submits="true"
              >
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
