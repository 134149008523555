<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { keyBy } from 'lodash-es';
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import TerraNewForm from '~/forms/components/new-form/terra-new-form.vue';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  show_template_info: {
    type: Boolean,
    default: false,
  },
  field_details: {
    type: Object,
  },
  workflow_form_properties: {
    type: Object,
    default: () => {},
  },
  has_permissions: {
    type: Boolean,
    default: true,
  },
  create_form: {
    type: Function,
    default: null,
  },
  form_store_options: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['initializeEmit', 'formCreated']);
const route = useRoute();
const router = useRouter();
const form_store = useFormsStore(props.form_store_options?.store_key || 'terra_form_store');

const { getFormPayload } = useTerraHelperComposable();

const state = reactive({
  is_loading: false,
  show_form_popup: false,
});

const form_status = computed(() => {
  if (props.field_details.current + props.field_details.value === 0) {
    if (props.field_details.config?.required) {
      if (props.field_details.current_form)
        return { type: 'warning', message: 'Fill form' };
      else
        return { type: 'warning', message: 'Create form' };
    }

    else
      if (props.field_details.current_form) {
        return { type: 'info', message: 'Fill form' };
      }
      else {
        return { type: 'info', message: 'Create form' };
      }
  }

  else
    if (props.field_details.current_form) {
      return { type: 'success', message: 'View form' };
    }
    else {
      return { type: 'error', message: 'ERROR' };
    }
});
const container_class = computed(() => {
  if (form_status.value.type === 'info')
    return 'primary';
  return form_status.value.type;
});
const assignees = computed(() => {
  return props.field_details.config?.assignees || [];
});
const template = computed(() => keyBy(form_store.form_template, 'uid')[props.field_details.config.template]);

async function createOrFillForm() {
  if (['Fill form', 'View form'].includes(form_status.value.message)) {
    emit('initializeEmit');
    router.push({
      ...route,
      query: {
        form: btoa(JSON.stringify({
          form_uid: props.field_details.current_form.uid,
          store_key: props.form_store_options?.store_key || 'terra_form_store',
          track_event_view: props.form_store_options?.track_event_view || 'TerraProgressUpdate',
        })),
      },
    });
  }
  else {
    state.show_form_popup = true;
    if (props.create_form) {
      await props.create_form(props.field_details);
      state.show_form_popup = false;
    }
  }
}

async function getFormTemplates() {
  const query = {
    isChild: false,
    public: false,
    status: 'published',
  };
  await form_store.get_templates({ query });
}
onMounted(async () => {
  if (!(form_store.form_template || []).length) {
    state.is_loading = true;
    await getFormTemplates();
    state.is_loading = false;
  }
});
</script>

<template>
  <div v-if="show_template_info" class="border p-6 rounded-xl w-full">
    <div class="flex justify-between">
      <div class="flex items-start">
        <div class="w-10 h-10">
          <HawkFeaturedIcon theme="light-circle" size="md" color="primary">
            <IconHawkDataflowOne />
          </HawkFeaturedIcon>
        </div>
        <div class="pl-6">
          <div class="flex items-center mb-2">
            <div class="text-md font-semibold mr-1">
              {{ field_details.name }}
            </div>
            <HawkBadge v-if="field_details.config?.required" size="sm">
              {{ $t('Required') }}
            </HawkBadge>
          </div>
          <div v-if="template?.description" class="text-xs text-gray-600 mb-2">
            {{ template.description }}
          </div>
          <div class="flex items-center">
            <div class="text-xs text-gray-600">
              {{ $t('Assignees') }}:
              <HawkMembers
                v-if="assignees?.length"
                :members="assignees"
                type="badge"
              />
            </div>
            <div class="text-xs text-gray-600 ml-6">
              {{ $t('Duration') }}: {{ field_details.config.duration }} {{ $t('days') }}
            </div>
          </div>
          <slot name="item_details" />
        </div>
      </div>
      <div v-if="has_permissions" class="flex justify-center">
        <div
          class="rounded-md cursor-pointer flex items-center justify-center text-base text-gray-500 font-semibold hover:bg-gray-200 w-8 h-8"
          @click="$emit('edit')"
        >
          <IconHawkPencilOne class="w-4 h-4" />
        </div>
        <div
          class="rounded-md cursor-pointer flex items-center justify-center hover:bg-gray-200 w-8 h-8 mx-2"
          @click="$emit('delete')"
        >
          <IconHawkTrashThree class="w-4 h-4" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="rounded-xl border p-6 text-gray-900" :class="`border-${container_class}-300 bg-${container_class}-25`">
    <HawkLoader v-if="state.is_loading" container_class="min-h-[108px]" />
    <template v-else>
      <div class="flex items-start">
        <div class="mr-6">
          <HawkFeaturedIcon theme="light-circle" size="md" :color="container_class">
            <IconHawkAlertTriangle v-if="form_status.type === 'warning'" />
            <IconHawkInfoCircle v-else-if="form_status.type === 'info'" />
            <IconHawkCheck v-else />
          </HawkFeaturedIcon>
        </div>
        <div>
          <div class="flex items-center mb-2">
            <h4 class="text-md font-semibold text-gray-900">
              {{ field_details.name }}
            </h4>
            <div v-if="!template" v-tippy="{ content: $t('Template not found'), placement: 'right' }" class="cursor-pointer">
              <icon-hawk-info-circle class="ml-2 w-4 h-4 text-gray-600" />
            </div>
          </div>
          <p class="text-xs text-gray-600">
            <template v-if="form_status.type === 'success'">
              {{ $t('The form has been completed/closed.') }}
            </template>
            <template v-else>
              {{ field_details.current_form ? $t('Please fill the form to continue to the next step') : $t('Please create/fill the form to continue to the next step') }}
            </template>
          </p>
        </div>
      </div>
      <HawkButton :loading="state.show_form_popup" :disabled="!template?.can_create_forms" block class="mt-6" type="outlined" @click="createOrFillForm()">
        <IconHawkFileMinusTwo class="w-5 h-5" />
        <span style="line-height: initial;">
          {{ $t(form_status.message) }} <span v-if="form_status.type === 'info'">({{ $t('Optional') }})</span>
        </span>
      </HawkButton>
      <TerraNewForm
        v-if="!props.create_form && state.show_form_popup"
        create_form_on_load
        workflow_form
        :template_uid="field_details.config.template"
        :workflow_form_properties="workflow_form_properties"
        :form_data="getFormPayload(field_details)"
        @form-created="$emit('formCreated', $event)"
        @close="state.show_form_popup = false"
      />
    </template>
  </div>
</template>
