<script setup>
import { cloneDeep } from 'lodash-es';
import { ref } from 'vue';
import { DynamicScroller } from 'vue-virtual-scroller';
import { parseStringifiedObjectsInArray } from '~/common/utils/common.utils';
import DefectStatus from '~/therm/components/atoms/defects-status.vue';
import { useThermStore } from '../store/therm.store';
import ThermDefectDetails from './therm-defect-details.vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  filtered_features: {
    type: Array,
    default: () => [],
  },
});

const therm_store = useThermStore();
const defect_index = ref(-1);
const scroller$ = ref({});
const feature_types = computed(() => {
  return therm_store.feature_types;
});
const selected_features = computed({
  get() {
    let features = [];
    if (therm_store?.selected_features?.length)
      features = cloneDeep(therm_store.selected_features.map(f => therm_store.features_hash[f.properties.uid]));
    return parseStringifiedObjectsInArray(features);
  },
  set(value) {
    therm_store.set_selected_features(value);
  },
});

async function selectDefect(defect, index) {
  const turf = (await import('@turf/turf'));
  defect_index.value = index;
  selected_features.value = [defect];
  const centroid = turf.centroid(defect);
  therm_store.map.flyTo({
    center: centroid.geometry.coordinates,
    zoom: 20,
  });
}

async function navigateDefects(e, type) {
  const turf = (await import('@turf/turf'));
  const features_value = cloneDeep(props.filtered_features);
  const newIndex = type === 'next' ? e + 1 : e - 1;
  const defect = features_value[newIndex];
  selected_features.value = [cloneDeep(therm_store.features_hash[defect.properties.uid])];
  defect_index.value = newIndex;
  const centroid = turf.centroid(defect);

  therm_store.map.flyTo({
    center: centroid.geometry.coordinates,
    zoom: 20,
  });
}
</script>

<template>
  <div class="h-[calc(100vh-250px)]">
    <div
      v-show="!selected_features.length || selected_features.length > 1"
      class="h-[calc(100vh-240px)] overflow-auto scrollbar "
    >
      <DynamicScroller
        ref="scroller$"
        v-slot="{ item, index }"
        class="h-full scrollbar"
        :items="filtered_features.map((f) => ({ uid: f.properties.uid, ...f }))"
        :min-item-size="45"
        key-field="uid"
        :prerender="10"
      >
        <div
          class="flex items-center justify-between flex-wrap gap-2 pt-3 pr-3 pb-3 pl-5 border-b border-gray-300 cursor-pointer hover:bg-blue-300 hover:bg-opacity-10"
        >
          <div class="flex items-center justify-start" @click="selectDefect(item, index)">
            <div
              class="mr-[6px] h-3 w-3 bg-blue-100 rounded-full shrink-0"
              :style="{
                background:
                  feature_types[item.properties.featureTypeId]
                  && feature_types[item.properties.featureTypeId].properties.color,
              }"
            />
            <div class="text-sm font-medium text-gray-900">
              <HawkText
                :content="`${item.properties.string_number} ${
                  feature_types[item.properties.featureTypeId]
                    ? ` : ${
                      feature_types[item.properties.featureTypeId].name}`
                    : ''
                }` || item.properties.name" :length="item.properties.status && item.properties.status !== '-1' ? 26 : 38"
              />
            </div>
          </div>

          <DefectStatus v-if="!therm_store.use_tasks && item.properties.status" :status="item.properties.status" type="badge" />
        </div>
      </DynamicScroller>
    </div>
    <div v-if="selected_features.length && selected_features.length === 1">
      <ThermDefectDetails
        :defect_index="defect_index"
        :data="selected_features.length && therm_store.features_hash[selected_features[0]?.properties?.uid].properties"
        :filtered_features="filtered_features"
        @next-defect="navigateDefects($event, 'next')"
        @prev-defect="navigateDefects($event, 'prev')"
        @close="() => therm_store.set_selected_features([])"
      />
    </div>
  </div>
</template>
