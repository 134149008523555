<script setup>
import { useModal } from 'vue-final-modal';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import InventoryQuantity from '~/inventory/components/inventory-quantity.vue';
import InventoryItemDetailsOverview from '~/inventory/components/inventory-items/inventory-item-details-overview.vue';
import InventoryItemDetailsStocks from '~/inventory/components/inventory-items/inventory-item-details-stocks.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import InventoryItemStatusPopup from '~/inventory/components/inventory-items/inventory-item-status-popup.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { renderAsTextarea } from '~/common/utils/common.utils';

const props = defineProps({
  warehouse_id: { type: String, default: '' },
  container_width: { type: Number, default: 0 },
});

const { $toast, route, router, $t, auth_store, $services } = useCommonImports();
const inventory_store = useInventoryStore();

const header_tabs = [
  { uid: 'overview', label: $t('Overview') },
  { uid: 'stock', label: $t('Stock') },
];

const state = reactive({
  active_item_detail_tab: 'overview',
});

const item_details = computed(() => inventory_store.get_active_item_details);

const menu_items = computed(() => ([
  ...(
    auth_store.check_permission('modify_items', route.params.asset_id)
      ? [{
          uid: 'edit',
          label: $t('Edit'),
          on_click: () => {
            router.push({
              name: 'inventory-item-form',
              params: { ...route.params, item_id: item_details.value.uid },
            });
          },
        }]
      : []
  ),
  ...(
    auth_store.check_permission('create_items', route.params.asset_id)
      ? [{
          uid: 'duplicate',
          label: $t('Duplicate'),
          on_click: () => {
            router.push({
              name: 'inventory-item-form',
              params: { ...route.params, item_id: item_details.value.uid },
              query: { duplicate: item_details.value.uid },
            });
          },
        }]
      : []
  ),
  {
    uid: 'active_status',
    label: item_details.value?.is_active ? $t('Mark as inactive') : $t('Mark as active'),
    on_click: async () => {
      itemStatusHandler();
    },
  },
  ...(item_details.value?.can_delete
    ? [{
        uid: 'delete',
        label: $t('Delete'),
        on_click: async () => itemDeleteHandler(),
      }]
    : []),
]));

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeleteOptions } = useModal({
  component: HawkDeletePopup,
});

function itemDeleteHandler() {
  patchDeleteOptions(
    {
      attrs: {
        header: $t('Delete item'),
        content: `Are you sure you want to delete ${item_details.value?.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const { status } = await $services.inventory_items.delete_item({
              id: item_details.value?.uid,
            });

            if (status === 204) {
              delete inventory_store.items_map[item_details.value?.uid];
              inventory_store.total_items_count = inventory_store?.total_items_count !== 0 ? inventory_store.total_items_count - 1 : 0;
              inventory_store.active_item_uid = inventory_store.items?.[0].uid || null;
              $toast({
                title: $t('Item deleted'),
                text: $t('The item has been successfully deleted'),
                type: 'success',
              });
              closeDeletePopup();
            }
          }
          catch ({ data: error }) {
            const { title, message } = inventory_store.get_error_status(error?.error_code) || {};
            $toast({
              title: title || 'Something went wrong',
              text: message || 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

const { open: openItemStatusPopup, close: closeItemStatusPopup, patchOptions } = useModal({
  component: InventoryItemStatusPopup,
});

function itemStatusHandler() {
  patchOptions(
    {
      attrs: {
        save: async () => {
          try {
            const { item } = await inventory_store.set_inventory_item_inactive({
              id: item_details.value.uid,
              body: { is_active: !item_details.value?.is_active },
            });
            if (item?.uid)
              $toast({
                title: item.uid ? $t('Item marked active') : $t('Item marked inactive'),
                text: item.uid ? $t('The item will be available to be added in future transactions.') : $t('The item will no longer be available for future transactions.'),
                type: 'success',
              });
            else
              throw new Error('Error in updating item status');
          }
          catch (error) {
            const { title, message } = inventory_store.get_error_status('update_item_status_failed') || {};

            $toast({
              title: title || 'Something went wrong',
              text: message || 'Please try again',
              type: 'error',
            });
          }
        },
        onClose() {
          closeItemStatusPopup();
        },
      },
    },
  );
  openItemStatusPopup();
}

function getQuantity() {
  if (!props?.warehouse_id)
    return item_details.value?.physical_stock_quantity;
  return item_details.value?.warehouse_stock_quantity[props?.warehouse_id]?.quantity;
}

watch(() => inventory_store?.active_item_uid, () => {
  state.active_item_detail_tab = 'overview';
}, { immediate: true });
</script>

<template>
  <div class="grid gap-6 p-6">
    <div>
      <div class="text-sm text-gray-500">
        #{{ item_details?.number }}
      </div>
      <div class="flex place-items-start justify-between">
        <div class="max-w-[800px]">
          <div class="flex items-center gap-3 text-lg font-semibold">
            <span class="break-all">{{ item_details?.name }} (<InventoryQuantity
              :quantity="getQuantity() || 0"
              :uom="item_details?.uom"
            />)</span>
            <div class="flex-shrink-0 text-sm font-medium">
              <HawkTag v-if="item_details?.category" color="white">
                <HawkText :content="inventory_store.item_types_map?.[item_details?.category]?.name" />
              </HawkTag>
            </div>
          </div>
          <div class="break-all text-sm text-gray-500" v-html="renderAsTextarea(item_details?.description)" />
        </div>
        <HawkMenu
          additional_trigger_classes="!ring-0 !focus:ring-0"
          :items="menu_items" position="fixed"
        >
          <template #trigger>
            <HawkButton icon type="outlined">
              <IconHawkDotsVertical />
            </HawkButton>
          </template>
        </HawkMenu>
      </div>
    </div>
    <HawkPageHeaderTabs v-if="item_details?.is_serial_number" :tabs="header_tabs" :active_item="state.active_item_detail_tab" @tabClick="state.active_item_detail_tab = $event.uid" />
    <InventoryItemDetailsOverview v-if="state.active_item_detail_tab === 'overview'" :warehouse_id="route?.params?.warehouse_id" :container_width="container_width" />
    <InventoryItemDetailsStocks v-else-if="state.active_item_detail_tab === 'stock'" :warehouse_id="route?.params?.warehouse_id" />
  </div>
</template>
