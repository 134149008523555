import { stringToNode } from '~/dashboard/print/utilities.js';

function createIndexChart(chart, index) {
  let danger_text = '';
  if (
    ['spi', 'cpi'].includes(chart.dataSource.type)
    && chart.dataSource.index < 1
  )
    danger_text = 'index-widget__value--danger';

  const index_name = chart.dataSource.configuration_name
    ? `
        <div class="index-widget__name">
          ${chart.dataSource.configuration_name}
        </div>
      `
    : '';

  const chart_container = stringToNode(`
        <div class="dashboard-row dashboard-row--half-w">
          <div class="dashboard-row__chart dashboard-row__chart--number">
            <h3 class="dashboard-row__name">
              ${chart.chart_name}
            </h3>
            ${index_name}
            <div class="index-widget__value ${danger_text}">
              ${chart.dataSource.index}
            </div>
          </div>
        </div>
      `);

  return chart_container;
};

export {
  createIndexChart,
};
