<script setup>
import { useRoute } from 'vue-router';

// stores
import { useDocumentStore } from '~/dms/store/document.store';

const props = defineProps({
  item: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['close']);

const route = useRoute();

const document_store = useDocumentStore();

const $toast = inject('$toast');
const $t = inject('$t');

const form$ = ref(null);

// Popup save
async function handleSave() {
  const type = props.item.type;
  const uid = props.item.uid;
  const name = `${form$.value.data.name}${getFileExtension()}`;
  try {
    await document_store.crud_documents({
      request: {
        body: {
          [`${type}s`]: {
            update: [
              {
                uid,
                name,
              },
            ],
          },
        },
      },
    });
    $toast({
      title: $t(`${type === 'file' ? 'File' : 'Folder'} renamed successfully`),
      text: $t(`The ${type} has been renamed successfully`),
      type: 'success',
      position: 'bottom-right',
    });
  }
  catch (e) {
    logger.error('RENAME FORM: ', e);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again later'),
      type: 'error',
      position: 'bottom-right',
    });
  }

  // update sidebar
  await document_store.set_hierarchy(route.params.asset_id);

  emit('close');
}

function getFileExtension() {
  if (props.item?.type === 'file' && props.item?.name.split('.')[1])
    return `.${props.item.name?.split('.').pop()}`;
  else return '';
}
function calculateDefault() {
  if (props.item?.type === 'file' && props.item?.name.split('.')[1])
    return props.item.name?.split('.').slice(0, -1).join('.');
  else
    return props.item.name;
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :should_validate_on_mount="false" :display-errors="false"
      class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="handleSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("Rename") }}
          </template>
        </hawk-modal-header>
        <!-- Body -->
        <hawk-modal-content>
          <div class="p-1 max-h-[575px]">
            <TextElement :rules="['required']" :default="calculateDefault()" :label="$t('Name')" name="name" />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template v-if="$slots.left_footer" #left>
            <slot name="left_footer" />
          </template>
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <hawk-submit-button :form$="form$">
                {{ $t('Save') }}
              </hawk-submit-button>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
