<script setup>
import { flatten, groupBy, map, omit, pick, uniqBy } from 'lodash-es';
import { computed, onBeforeMount } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import FeatureAnalytics from '~/terra/components/feature-details/feature-analytics.vue';
import AttachmentsViewer from '~/terra/molecules/attachments.vue';
import NotesList from '~/terra/molecules/notes-list.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  feature: {
    type: Object,
    default: null,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});

const { $t, $services, route } = useCommonImports();

const terra_store = useTerraStore();

const state = reactive({
  is_loading: false,
  feature_data: null,
  progress_activities: [],
  grouped_progress_data: [],
  tabs_list: [
    { id: 'all', label: $t('All') },
    { id: 'progress_updates', label: $t('Progress updates') },
    { id: 'attachments', label: $t('Attachments') },
  ],
  active_tab: 'all',
  activities_count: 0,
  selected_workflow_uid: null,
});

const feature_details = computed(() => terra_store.selected_features[0]);

const filtered_field_uids = computed(() => {
  const workflow_progress = feature_details.value?.properties?.workflowProgress || {};
  return Object.keys(workflow_progress).reduce((acc, curr) => {
    if (workflow_progress[curr].current)
      acc.push(curr);
    return acc;
  }, []);
});

const selected_workflow_uid = computed(() => state.selected_workflow_uid || feature_details.value?.properties?.workflow);
const has_activities = computed(() => props.options?.is_sm ? state.activities_count > 0 : true);

const selected_feature_workflow_fields = computed(() => {
  const workflow_uid = selected_workflow_uid.value;
  const fields = [];
  Object.values(
    terra_store.terra_workflows?.[workflow_uid]?.data || {},
  ).forEach((item) => {
    fields.push(...(item.fields || []));
  });
  return uniqBy(fields, 'uid').filter(field =>
    !!state.selected_workflow_uid || filtered_field_uids.value.includes(field.uid),
  );
});
const data_list = computed(() => {
  if (!state.feature_data)
    return [];
  if (state.active_tab === 'progress_updates')
    return state.grouped_progress_data;
  if (state.active_tab === 'attachments') {
    const activity_attachments = flatten(state.grouped_progress_data.map(activity => (activity.attachments || []).map((attachment, i) => ({
      ...attachment,
      uid: `${activity.uid}_${i}`,
      owner: { uid: activity.created_by },
      created_at: activity.created_at,
      thumbnail_small: attachment.meta?.thumbnails?.small,
      hide_delete: true,
    }))));
    const user_attachments = state.feature_data.properties.attachments.map((attachment) => {
      if (!attachment?.owner?.uid)
        attachment.owner = { uid: attachment.created_by };
      return attachment;
    });
    return flatten([activity_attachments, user_attachments]);
  }

  const attachments_group_notes = groupBy(state.feature_data.properties.attachments, (item) => {
    if (item?.attached_to?.type === 'notes')
      return item.attached_to.uid;
    return 'attachments';
  });
  const feature_notes = state.feature_data.properties.notes.map(note => ({
    ...note,
    attachments: attachments_group_notes[note.uid] || [],
  }));

  return [
    ...state.grouped_progress_data,
    ...feature_notes,
  ];
});

async function handleUpdate(payload) {
  try {
    const { data } = await $services.features.patch({
      body: [{
        uid: feature_details.value?.properties?.uid,
        ...payload,
      }],
      attribute: `container/${route.params.id}/project/${feature_details.value?.properties?.project}`,
    });
    state.feature_data = {
      ...feature_details.value,
      properties: {
        ...feature_details.value,
        ...pick(data[0], ['notes', 'attachments']),
      },
    };

    updateFeatureAttachments();
  }
  catch (error) {
    logger.error(error);
  }
}

function updateFeatureAttachments() {
  const feature_uid = feature_details.value?.properties?.uid;
  const project_uid = feature_details.value?.properties?.project;
  const { project_features_attachment_map } = terra_store.gallery_view_state;
  const project_attachment_details = project_features_attachment_map?.[project_uid];
  const updated_feature = {
    feature_uid,
    project_uid,
    feature_attachments: state.feature_data.properties.attachments,
  };

  project_features_attachment_map[project_uid] = {
    ...(project_attachment_details || {}),
    [feature_uid]: {
      ...(project_attachment_details?.[feature_uid] || {}),
      ...updated_feature,
    },
  };

  terra_store.set_gallery_state('project_features_attachment_map', project_features_attachment_map);
}

function getParsedActivity(activity) {
  const field = selected_feature_workflow_fields.value?.find(
    item => item.uid === activity.field,
  );
  return {
    ...activity,
    message: activity.notes,
    created_by: activity.user,
    created_at: activity.userTimestamp,
    is_progress_history: true,
    units: field?.units ?? '',
    field_name: field?.name ?? '',
    attachments: (activity.attachments || []).map(attachment => ({
      ...attachment,
      file_name: attachment?.file_name || terra_store.get_filename_from_key(attachment?.key),
    })),
  };
}

async function getActivities() {
  const field = selected_feature_workflow_fields.value.map(field => field.uid);
  const feature = [feature_details.value?.properties?.uid];

  const payload = {
    field,
    feature,
  };
  const { data } = await $services.terra_view_service.export_progress_data({
    body: {
      ...payload,
    },
  });
  state.progress_activities = data.map(activity => getParsedActivity(activity));
  state.grouped_progress_data = map(groupBy(state.progress_activities, item => `${item.created_at}_${item.created_by}`), (items) => {
    const static_fields = omit(items[0], ['field_name', 'past', 'current', 'units']);
    return {
      ...static_fields,
      fields_updated: items.map(({ field_name, past, current, units }) => ({
        field_name,
        past,
        current,
        units,
      })),
    };
  });
}

async function changeWorkflowHandler(workflow_uid) {
  state.selected_workflow_uid = workflow_uid;
  await getActivities();
  state.activities_count = data_list.value.length;
}

onBeforeMount(async () => {
  try {
    state.is_loading = true;
    if (props.feature) {
      terra_store.selected_features = [props.feature];
      state.feature_data = feature_details.value;
      await terra_store.set_terra_workflows({
        asset_id: route.params.asset_id,
      });
    }
    else {
      state.feature_data = await terra_store.get_feature_details(feature_details.value);
    }
    await getActivities();
    state.activities_count = data_list.value.length;
    state.is_loading = false;
  }
  catch (error) {
    logger.error(error);
    state.is_loading = false;
  }
});

function attachmentHandler(position) {
  if (props.options?.is_sm) {
    if (position === 'top') {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }
}
</script>

<template>
  <div>
    <FeatureAnalytics
      v-if="!state.is_loading && state.feature_data"
      :options="{
        ...options,
        selected_workflow: state.selected_workflow_uid,
      }"
      :progress_activities="state.progress_activities"
      @change-workflow="changeWorkflowHandler"
    />
    <HawkLoader v-if="state.is_loading" />
    <template v-else>
      <div v-if="has_activities" class="min-h-40">
        <div :class="{ 'flex gap-3': options?.is_sm }">
          <div class="font-semibold text-lg mb-4">
            {{ $t('Activity') }} <span v-if="state.activities_count">({{ state.activities_count }})</span>
          </div>
          <div class="flex items-center gap-3 mb-4">
            <HawkButton
              v-for="tab in state.tabs_list" :key="tab.id" type="outlined"
              :color="state.active_tab === tab.id ? 'active' : 'gray'" size="xs" class="!px-2 !py-0 focus:!ring-0"
              @click="state.active_tab = tab.id"
            >
              <div class="text-sm font-semibold">
                {{ tab.label }}
              </div>
            </HawkButton>
          </div>
        </div>
        <AttachmentsViewer
          v-if="state.active_tab === 'attachments'"
          :attachment_config="{ meta: { service: 'terra', id: feature_details.properties.uid } }"
          :attached_to="{ type: 'feature', uid: feature_details.properties.uid }"
          :attachments="data_list || []"
          :on_update="handleUpdate"
          :options="{ ...options, attachmentHandler }"
        />
        <template v-else-if="state.active_tab === 'progress_updates' && !data_list.length">
          <div class="text-sm text-gray-900 mt-4 font-medium">
            {{ $t('No progress updates available') }}
          </div>
        </template>
        <NotesList
          v-else
          :class="options.is_sm ? 'max-h-[600px] scrollbar' : null"
          :notes="data_list"
          :options="{ ...options, attachmentHandler }"
          :show_editor="options.show_editor ?? state.active_tab === 'all'"
          :attachment_config="{ meta: { service: 'terra', id: feature_details?.properties?.uid } }"
          :on_update="handleUpdate"
        />
      </div>
    </template>
  </div>
</template>
