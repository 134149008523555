<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';

import { useDashboardInventoryStore } from '~/dashboard/store/dashboard-inventory.store.js';

import DashboardInventoryFieldsPopup from '~/dashboard/components/inventory-fields/dashboard-inventory-fields-popup.vue';

const emit = defineEmits(['change']);

const dashboard_inventory_store = useDashboardInventoryStore();

const { inventory_configuration } = storeToRefs(dashboard_inventory_store);

const form$ = ref(null);
const form_data = ref([]);

const { open: openInventoryFieldsPopup, close: closeInventoryFieldsPopup, patchOptions } = useModal({
  component: DashboardInventoryFieldsPopup,
});

function emitUpdate() {
  const payload = form_data.value.fields.map((field) => {
    const data = field;
    if (data.type !== 'workflow') {
      delete data.timerange_type;
      delete data.timerange_range;
    }
    return data;
  });
  emit('change', payload);
}

function handleAddField() {
  patchOptions(
    {
      attrs: {
        fields: form_data.value.fields,
        onClose() {
          closeInventoryFieldsPopup();
        },
        onSave: async (data) => {
          form_data.value.fields = data.map((item) => {
            const data = { ...item, type: item.parent_id };
            delete data.parent_id;

            if (data.type === 'workflow')
              return { ...data, timerange_type: 'all_time' };
            return data;
          });
          closeInventoryFieldsPopup();
          emitUpdate();
        },
      },
    },
  );
  openInventoryFieldsPopup();
}

onMounted(async () => {
  await new Promise(resolve => setTimeout(resolve, 100));
  form_data.value.fields = inventory_configuration.value?.fields || [];
});
</script>

<template>
  <div class="text-sm text-gray-700 font-medium mt-4">
    {{ $t('Fields') }}<span class="text-amber-600">*</span>
  </div>
  <Vueform
    ref="form$"
    v-model="form_data"
    size="sm"
    sync
    class="my-4"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
  >
    <ListElement
      name="fields"
      :sort="true"
      :initial="0"
      :min="1"
      :controls="{ add: false, remove: true, sort: true }"
      @sort="emitUpdate"
      @remove="emitUpdate"
    >
      <template #default="{ index }">
        <ObjectElement
          :name="index"
          :add-classes="{
            ObjectElement: {
              wrapper: 'flex w-full',
            },
          }"
          :remove-classes="{
            ObjectElement: {
              wrapper: 'grid grid-cols-12',
            },
          }"
        >
          <HiddenElement name="type" />
          <TextElement
            name="label"
            :readonly="true"
            :add-classes="{
              ElementLayout: {
                container: 'flex w-full',
                outerWrapper: 'w-full',
              },
            }"
            :remove-classes="{
              ElementLayout: {
                container_sm: 'col-span-12 sm:col-span-12',
              },
            }"
          />
          <SelectElement
            v-bind="{
              name: 'timerange_type',
              items: dashboard_inventory_store.timerange_type_options,
              default: dashboard_inventory_store.timerange_type_options[0].value,
              closeOnSelect: true,
              search: true,
              native: false,
              canClear: false,
              canDeselect: false,
              inputType: 'search',
              autocomplete: 'off',
              valueProp: 'value',
              conditions: [
                [
                  'fields.*.type',
                  'workflow',
                ],
              ],
              addClasses: {
                ElementLayout: {
                  container: 'flex w-full',
                  outerWrapper: 'w-full',
                },
              },
              removeClasses: {
                ElementLayout: {
                  container_sm: 'col-span-12 sm:col-span-12',
                },
              },
            }"
            @change="emitUpdate"
          />
        </ObjectElement>
      </template>
    </ListElement>
  </Vueform>

  <div class="flex items-center text-primary-700 font-medium text-sm cursor-pointer mb-8" @click="handleAddField">
    <IconHawkPlus class="mr-2" /> Add Field
  </div>
</template>
