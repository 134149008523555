<script setup>
// --------------------------------- Imports -------------------------------- //
import { useModal } from 'vue-final-modal';
import { groupBy, isArray } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useThermStore } from '~/therm/store/therm.store.js';
import RawImageMapModal from '~/therm/components/raw-images-modal.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DocumentUploadForm from '~/dms/components/documents/forms/document-upload-form.vue';
import useEmitter from '~/common/composables/useEmitter';
import NotesList from '~/terra/molecules/notes-list.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  feature_types: {
    type: Object,
  },
});
const emits = defineEmits(['update']);
const $toast = inject('$toast');
const $t = inject('$t');
const $services = inject('$services');

const emitter = useEmitter();
const auth_store = useAuthStore();
const common_store = useCommonStore();
const therm_store = useThermStore();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));
const { open: openRawImageModal, close: closeRawImageModal, patchOptions: rawImagePatchOptions } = useModal({
  component: RawImageMapModal,
  attrs: {
    onClose: () => {
      closeRawImageModal();
    },
  },
});
const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose: () => {
      closeDeletePopup();
    },
  },
});
const document_upload_modal = useModal({
  component: DocumentUploadForm,
  attrs: {
    onClose: () => {
      document_upload_modal.close();
    },
  },

});

const state = reactive({
  raw_images: [],
  downloading_multiple: false,
  raw_images_loading: false,
  message: '',
  edit_note: null,
  show_loader: '',
});

const permissions = computed(() => therm_store.features_hash[props.data?.uid]);
const notes_list = computed(() => {
  const attachments_group_notes = groupBy((props.data?.user_attachments || []), (item) => {
    if (item?.attached_to?.type === 'notes')
      return item.attached_to.uid;
    return 'attachments';
  });
  return props.data?.notes?.map(note => ({
    ...note,
    attachments: attachments_group_notes[note.uid] || [],
  })) || [];
});
function getDefectName(defect) {
  return (
          `${defect.string_number} ${
            props.feature_types[defect.featureTypeId]
              ? ` : ${props.feature_types[defect.featureTypeId].name}`
              : ''
          }`
  );
}
function openRawImage(index) {
  rawImagePatchOptions({
    attrs: {
      active_image: index,
    },
  });
  openRawImageModal();
}
async function getRawImages() {
  try {
    state.raw_images_loading = true;
    const raw_images_data = props.data?.raw_images;
    if (raw_images_data && typeof raw_images_data === 'string') {
      const res = await therm_store.get_raw_images(raw_images_data);
      state.raw_images = res.data;
      therm_store.set_raw_images(res.data);
      if (!therm_store.raw_images_map)
        therm_store.raw_images_map = {};
      therm_store.raw_images_map[props.data.uid] = state.raw_images;
      state.raw_images_loading = false;
    }
    else if (isArray(raw_images_data)) {
      state.raw_images = raw_images_data;
      therm_store.set_raw_images(raw_images_data);
      // For image load delay
      setTimeout(() => {
        state.raw_images_loading = false;
      }, 500);
    }
    else {
      state.raw_images_loading = false;
    }
  }
  catch (err) {
    state.raw_images_loading = false;
  }
}
function downloadImage(image) {
  window.open(image.url, '_blank');
}
async function updateIssue(payload) {
  await therm_store.updateIssue({ uid: props.data?.uid, ...payload });
}
async function downloadAllFiles() {
  const files = getAttachments().reduce((acc, attachment) => {
    const url = attachment.url;
    if (url)
      acc.push({
        uid: attachment.uid,
        name: attachment.file_name,
        size: attachment.file_size,
        url,
      });
    return acc;
  }, []);
  if (files.length > 1)
    emitter.emit('download_zip', { files, name: `${getDefectName(props.data)}.zip`, is_zip_item: true });
  else if (files.length === 1)
    downloadImage(files[0]);
  else
    $toast({
      text: $t('No attachments for this feature'),
      type: 'warning',
      position: 'bottom-right',
    });
}
function openFileUploadModal() {
  document_upload_modal.patchOptions({
    attrs: {
      attachment_config: { meta: { service: 'therm', id: props.data?.uid } },
      file_input_config: {
        accept: 'image/*',
        file: {
          rules: [
            'mimetypes:image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/tiff',
          ],
        },
      },
      is_single_file: false,
      submit: async (form) => {
        const files = form.data.File.map(file => ({
          service: file.service_object,
          filename: file.name,
          file_name: file.name,
          file_size: file.size,
          file_type: file.type,
          mimetype: file.type,
          meta: file.meta,
          attached_to: { type: 'feature', uid: props.data?.uid },
        }));
        await updateIssue({ user_attachments: { add: files } });
        document_upload_modal.close();
      },
    },
  });
  document_upload_modal.open();
}
function attachmentDeleteHandler(file) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Attachment'),
        content: `Are you sure you want to delete ${file.file_name}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          await updateIssue({ user_attachments: { remove: [file.uid] } });
          closeDeletePopup();
        },
      },
    },
  );
  openDeletePopup();
}
async function handleNoteUpdate({ notes, attachments }) {
  try {
    await updateIssue({
      notes,
      user_attachments: attachments,
    });
  }
  catch (error) {
    logger.error(error);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'warning',
      position: 'bottom-right',
    });
  }
}
function isMessageEmpty(message) {
  return (message || '').replaceAll('<br>', '').replaceAll(/<p>\s*<\/p>/gmi, '').trim().length === 0;
}
function getAttachments() {
  const raw_images = (state.raw_images || []).map((image, index) => ({
    url: image.src,
    file_name: `image_${index}.jpg`,
    file_type: '.jpg',
    uid: index,
  }));
  const user_attachments = (props.data?.user_attachments || []);
  return [...raw_images, ...user_attachments];
}
watch(() => props.data?.uid, async (uid) => {
  const raw_images = therm_store.raw_images_map?.[uid];
  if (!raw_images)
    await getRawImages();
  else
    state.raw_images = raw_images;
}, { immediate: true });
</script>

<template>
  <div>
    <HawkLoader v-if="state.raw_images_loading" />
    <div v-else>
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium text-gray-500">
          {{ $t("Attachments") }} ({{ getAttachments().length }})
        </p>
      </div>
      <div class="flex items-center justify-between -ml-3 my-2">
        <HawkButton v-if="permissions.add_attachments" type="link" @click="openFileUploadModal()">
          <IconHawkPlus class="w-5 h-5" />
          {{ $t('Add attachments') }}
        </HawkButton>
        <HawkButton v-if="getAttachments().length" type="text" :loading="state.is_downloading" @click="downloadAllFiles()">
          <IconHawkDownloadOne class="w-5 h-5" />
          {{ $t('Download all') }}
        </HawkButton>
      </div>

      <div class="pb-3">
        <template v-if="getAttachments().length">
          <hawk-attachments-grid
            v-if="data?.user_attachments?.length"
            class="mb-3 !px-0"
            variant="small"
            :items="data.user_attachments"
            :image_dimensions="[90, 90]"
            :grid_gap="3"
            :can_delete="false"
            @delete="attachmentDeleteHandler"
          />
          <div v-if="state.raw_images?.length" class="flex flex-row flex-wrap gap-3 items-center">
            <div
              v-for="(image, i) in state.raw_images"
              :key="i"
              class="group relative inline w-[90px] h-[90px]"
              @click="openRawImage(i)"
            >
              <div class="flex justify-center items-center cursor-pointer h-8 w-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 invisible bg-white border border-gray-300 rounded-lg group-hover:visible">
                <IconHawkEye />
              </div>
              <img
                :src="image.src"
                alt=""
                width="90"
                height="90"
                class="cursor-pointer object-cover h-full w-full rounded-lg"
              >
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Notes -->
    <div class="my-4">
      <div class="text-sm font-medium text-gray-500">
        {{ $t('Notes') }} ({{ notes_list?.length }})
      </div>
      <NotesList
        :show_editor="permissions.add_notes"
        :notes="notes_list"
        :attachment_config="{ meta: { service: 'therm', id: data?.uid } }"
        :on_update="handleNoteUpdate"
      />
    </div>
  </div>
</template>
