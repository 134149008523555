<script setup>
import { inject } from 'vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const emit = defineEmits(['close']);

const $toast = inject('$toast');

const dashboard_store = useDashboardStore();
const loading = ref(false);
const form$ = ref(null);

const current_dashboard = computed(() => dashboard_store.current_dashboard);

async function shareDashboardHandler(form$) {
  try {
    loading.value = true;
    const { assignees_type } = form$.data.assignees_input;
    const sharing_data = {
      ...(assignees_type === 'Everyone'
        ? { public: true, members: {} }
        : {
            members: form$.data.assignees_input.assignees.reduce((acc, member) => {
              acc[member.uid] = 'read';
              return acc;
            }, {}),
            public: false,
          }
      ),
    };
    await dashboard_store.update_dashboard({ ...current_dashboard.value, ...sharing_data });
    $toast({
      title: 'Dashboard Shared',
      text: 'The dashboard has been successfully shared with the selected users.',
      type: 'success',
    });
  }
  catch (err) {
    logger.error(err);
    $toast({
      title: 'Sharing Dashboard Failed',
      text: 'Dashboard sharing failed. Please verify the recipients and try again.',
      type: 'error',
    });
  }
  finally {
    loading.value = false;
    emit('close');
  }
}

function onFormMounted() {
  const assignees_type = current_dashboard.value.public ? 'Everyone' : 'Custom';
  form$.value.el$('assignees_input.assignees_type')?.load(assignees_type);

  if (!current_dashboard.value.public) {
    const assignees = Object.keys(current_dashboard.value.members || {});
    form$.value.el$('assignees_input.assignees')?.load(assignees, true);
  }
}
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      ref="form$"
      :float-placeholders="false"
      :display-errors="false"
      size="sm"
      :format-load="data => data"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="shareDashboardHandler"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            <div class="flex items-center justify-between text-lg font-semibold text-gray-800">
              <div class="flex items-center">
                <div class="flex items-center border rounded-xl p-3 mr-4 border-gray-200">
                  <IconHawkUsersShare />
                </div>
                <div class="flex flex-col justify-start">
                  {{ $t('Share with people') }}
                  <span class="font-normal text-sm text-gray-600">
                    {{ $t('The following people have access to this project:') }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <ObjectElement
            name="assignees_input"
            :label="$t('Share with')"
          >
            <RadiogroupElement
              name="assignees_type"
              :items="[
                'Everyone',
                'Custom',
              ]"
            />
            <hawk-assignee-input
              :options="{
                name: 'assignees',
                conditions: [
                  ['assignees_input.assignees_type', 'Custom'],
                ],
                has_teams: true,
                placeholder: $t('Select members'),
                columns: {
                  default: { container: 12, label: 4, wrapper: 12 },
                  sm: { container: 12, label: 4, wrapper: 12 },
                  md: { container: 12, label: 4, wrapper: 12 },
                },
              }"
              :multi="true"
              :format_load="true"
            />
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium gap-3">
              <hawk-button type="outlined" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Share')"
                :loading="loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
