<script setup>
import { ref } from 'vue';
import { useThermStore } from '../store/therm.store';
import ThermDefectsTable from './therm-defects-table.vue';

defineProps({
  table_data: {
    type: Array,
    required: true,
    default: () => [],
  },
});
const emits = defineEmits(['selectedDefects']);
const defect_table_instance = ref(null);
const therm_store = useThermStore();
function handleDefectsSelection(defects = []) {
  emits('selectedDefects', defects);
}

function handleDefectTableInstance(instance) {
  defect_table_instance.value = instance;
  therm_store.defect_table_instance = instance;
}
</script>

<template>
  <div class="mt-[85px] mx-2 w-full flex flex-col">
    <ThermDefectsTable :table_data="table_data" @setDefectTableInstance="handleDefectTableInstance" @selectDefects="handleDefectsSelection" />
  </div>
</template>
