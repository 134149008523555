<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { compact, keyBy, orderBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';

import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';

import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

import { sleep } from '~/common/utils/common.utils.js';
import TaskDependency from '~/tasks/components/atoms/task-dependency.vue';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import TaskName from '~/tasks/components/molecule/task-name.vue';

import TasksBulkActionsRow from '~/tasks/components/molecule/tasks-bulk-actions-row.vue';
import ContextMenuDropdown from '~/tasks/components/organisms/context-menu-dropdown.vue';
import { useTaskNavigation } from '~/tasks/composables/task.navigation.js';
import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
  },
  table_options: {
    type: Object,
    default: () => ({}),
  },
  is_compact_view: {
    type: Boolean,
    default: false,
  },
  is_widget: {
    type: Boolean,
    default: false,
  },
  id: {},
  is_modal_view: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'viewTask',
  'showSearch',
  'copyTask',
  'duplicateTask',
  'addDependencies',
  'convertToSubtask',
  'archiveTask',
  'deleteTask',
  'columnResized',
]);

const $toast = inject('$toast');
const task_store = inject('task_store');
const route = useRoute();

const auth_store = useAuthStore();

const { checkTaskPermission } = useTasksPermissions();

const { subscribe_navigator, unsubscribe_navigator, setNavigationContext } = useTaskNavigation(task_store, 'list_view');

const state = reactive({
  is_loading: false,
  is_pdf_exporting: false,
  page_size: props.options?.page_size || 25,
  page_number: 1,
  freeze_table: '-1',
  info_slot_data: {},
});

const data = computed(() => task_store.tasks(props?.options?.include_subtasks || false));

const table_instance = ref(null);
const selected_tasks = ref([]);
const task_compact_view = ref(null);

const optional_columns = [
  {
    accessorKey: 'description',
    header: 'Description',
    id: 'description',
    size: '250',
    enableSorting: false,
    render_as: {
      field_type: 'text',
      options: {
        rich_text: true,
        text_wrap: true,
        truncate_config: {
          type: 'fixed',
          length: 10,
        },
      },
    },
  },
  {
    accessorKey: 'start_date',
    header: 'Start Date',
    id: 'start_date',
    render_as: {
      field_type: 'date',
      options: {
        format: 'DD MMMM YYYY',
      },
    },
  },
  {
    accessorKey: 'tags',
    header: 'Tags',
    id: 'tags',
    size: '200',
    enableSorting: false,
    render_as: {
      field_type: 'tag',
      options: {
        max_tags_to_display: 3,
      },
    },
  },
  {
    accessorKey: 'owner',
    header: 'Created by',
    id: 'owner',
    enableSorting: false,
    render_as: {
      field_type: 'member',
    },
  },
  {
    accessorKey: 'created_at',
    header: 'Created on',
    id: 'created_at',
    render_as: {
      field_type: 'date',
      options: {
        format: 'DD MMM YYYY hh:mm A',
      },
    },
  },
  {
    accessorKey: 'attachments_count',
    header: 'Attachments',
    id: 'attachments_count',
    render_as: {
      field_type: 'number',
    },
  },
  {
    accessorFn: row => row.latest_comment?.text,
    header: 'Latest comment',
    id: 'latest_comment',
    size: '200',
    enableSorting: false,
    render_as: {
      field_type: 'text',
      options: {
        truncate_config: {
          type: 'adaptive',
          style: 'truncate',
        },
        text_wrap: true,
      },
    },
  },
  {
    accessorKey: 'last_commented_at',
    header: 'Latest comment on',
    id: 'last_commented_at',
    render_as: {
      field_type: 'date',
      options: {
        format: 'DD MMM YYYY hh:mm A',
      },
    },
  },
  {
    accessorKey: 'checklists_stats',
    header: 'Checklist progress',
    id: 'checklists_stats',
    size: '200',
  },
];

const default_columns = [
  {
    accessorKey: 'select',
    header: '',
    id: 'select',
    size: '5',
    enableSorting: false,
    enableResizing: false,
    show_on_hover: 'true',
    static: true,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    id: 'name',
    static: true,

  },
  {
    accessorKey: 'category',
    header: 'Category',
    id: 'category',
    render_as: {
      field_type: 'category',
    },
    static: true,

  },
  {
    accessorKey: 'priority',
    header: 'Priority',
    id: 'priority',
    render_as: {
      field_type: 'task-priority',
    },
  },
  {
    accessorKey: 'due_date',
    header: 'Due Date',
    id: 'due_date',
    render_as: {
      field_type: 'date',
      options: {
        flag_overdue: true,
        format: 'DD MMMM YYYY',
      },
    },
    static: true,
  },
  {
    accessorKey: 'assignees',
    header: 'Assignees',
    id: 'assignees',
    render_as: {
      field_type: 'member',
      options: {
        is_fixed: true,
      },
    },
    static: true,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    id: 'status',
    render_as: {
      field_type: 'task-status',
    },
    static: true,
  },
];

const columns = computed(() => {
  const options = [
    ...default_columns,
    ...optional_columns,
    {
      accessorKey: 'context_menu',
      header: '',
      id: 'context_menu',
      size: '5',
      show_on_hover: 'true',
      static: true,
    },
  ];

  const columns = keyBy(options, 'id');

  if (props.table_options?.column_config) {
    return Object.keys(props.table_options.column_config).reduce((arr, column_id) => {
      if (columns[column_id])
        arr.push({ ...columns[column_id], ...props.table_options.column_config[column_id] });
      return arr;
    }, []);
  }

  return options;
});

const wrapper_style = computed(() => {
  if (props?.table_options?.no_table_wrapper)
    return `height:${props.table_options?.table_height}px`;
  else
    return '';
});

const wrapper_class = computed(() => {
  if (props?.table_options?.height)
    return `${props.table_options.height} scrollbar`;

  return null;
});

subscribe_navigator(async (arg) => {
  await getTasks({
    sort_by: null,
    pagination_state: {
      pageIndex: state.page_number + arg,
      pageSize: state.page_size,
    },
  });
  table_instance?.value?.resetPagination(state.page_number - 1);
  return table_instance.value.getAllRows().map(row => row?.original).filter(task => task?.uid);
}, handleViewTask);

onBeforeUnmount(() => {
  unsubscribe_navigator();
});

async function getTasks({ sort_by, pagination_state }, append = false) {
  state.is_loading = true;
  if (props.is_modal_view && ((pagination_state?.pageIndex || state.page_number) === 1))
    task_store.tasks_map = {};
  clearTaskSelection();
  state.page_number = pagination_state?.pageIndex || state.page_number;
  state.page_size = pagination_state?.pageSize || state.page_size;
  const query = {
    page_number: state.page_number,
    page_size: state.page_size,
    is_template: false,
    latest_comment: true,
    ...(route.params.asset_id && { asset_uid: route.params.asset_id }),
    ...(props.options?.query || {}),
    ...(sort_by
      ? { sort: sort_by.desc ? `-${sort_by.id}` : sort_by.id }
      : {}),
  };

  await task_store.set_tasks(query, append);
  table_instance?.value?.collapseAll();
  state.is_loading = false;
}

async function handleViewTask(current_task_uid, create_new_context = true) {
  setNavigationContext(
    create_new_context,
    {
      current_task_uid,
      tasks: table_instance.value.getAllRows().map(row => row?.original).filter(task => task?.uid),
      strict: props?.options?.include_subtasks ? false : !!task_store.get_current_task(current_task_uid)?.parent_task_uid,
    },
    { page_number: state.page_number, page_size: state.page_size },
  );

  emit('viewTask', current_task_uid);
}

async function taskRefreshAfterBulkAction() {
  const total_pages = Math.ceil(task_store.total_task_count / state.page_size);
  // Make request for tasks if total_pages is >= 1 after archive/delete
  if (total_pages >= 1) {
    const is_last_page = total_pages === state.page_number - 1;
    if (is_last_page) {
      // If store has tasks on last page -> do nothing
      if (task_store.tasks().length) {
        state.is_loading = false;
        return;
      }
      else {
        // state has run out of tasks so go to previous page which has tasks
        state.page_number = state.page_number - 1;
      }
    }
    table_instance?.value?.resetPagination(state.page_number - 1);
  }
}

/* --------- HAWK TABLE HELPERS --------- */
async function rowExpanded(e) {
  task_store.get_sub_tasks(e.row.uid);
}
function getChildren(row) {
  delete state.info_slot_data[row.uid];

  if (props.options.hide_subtasks) {
    delete state.info_slot_data[row.uid];
    return null;
  }
  else {
    const total_subtask_count = row?.sub_task_uids?.length;
    const subtasks = compact(row?.sub_task_uids?.map(uid => task_store.tasks_map[uid]));
    if (total_subtask_count && !subtasks?.length) {
      // eslint-disable-next-line unicorn/no-new-array
      return new Array(total_subtask_count);
    }
    else {
      if (total_subtask_count > subtasks?.length)
        state.info_slot_data[row.uid] = 'Few subtasks are hidden';
      return orderBy(subtasks, 'subtask_orderindex');
    }
  }
}

function handleTaskSelection(e) {
  selected_tasks.value = e.map(tasks_list_row => tasks_list_row.original);
  if (selected_tasks.value.length)
    emit('showSearch', false);
  else
    emit('showSearch', true);
}
function clearTaskSelection() {
  table_instance?.value?.clearSelect();
  selected_tasks.value = [];
  emit('showSearch', true);
}

/* --------- CREATE SUBTASK --------- */
const { open: openSubtaskForm, close: closeSubtaskForm, patchOptions: patchTaskForm } = useModal({
  component: TaskForm,
  slots: {
    title: '<p>New Subtask</p>',
  },
});
function handleSubtaskCreate(task, row) {
  patchTaskForm(
    {
      attrs: {
        task_type: 'subtask',
        parent_task: task,
        allow_multi_task_create: true,
        onClose() {
          closeSubtaskForm();
        },
        async on_submit(data) {
          try {
            const previous_subtask_uid = task.sub_task_uids.length ? task.sub_task_uids.at(-1) : '';
            let payload = [];
            if (data.has_multiple_tasks) {
              data.payload.forEach((item) => {
                payload.push({
                  ...item,
                  ...{
                    target_element: task.target_element,
                    parent_task_uid: task.uid,
                    previous_task_uid: previous_subtask_uid,
                    next_task_uid: null,
                  },
                });
              });
            }
            else {
              data.target_element = task.target_element;
              data.parent_task_uid = task.uid;
              payload = [data];
            }

            await task_store.create_sub_tasks({
              tasks: payload,
            });
            $toast({
              title: 'Subtasks created successfully',
              text: 'Your changes have been saved and your subtasks are added to the list',
              type: 'success',
              position: 'bottom-right',
            });
            await sleep(2000);
            if (!row.getIsExpanded())
              table_instance?.value?.toggleExpandedRow(task.uid);
          }
          catch (error) {
            logger.log(error);
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openSubtaskForm();
}

async function searchTasks(value) {
  table_instance?.value?.collapseAll();
  task_store.set_search_key(value);
  task_compact_view.value?.scrollToTop();
  await getTasks({
    pagination_state: { pageIndex: 1 },
  }, false);
}

function emitColumnResized(resized_column, columns) {
  emit('columnResized', resized_column, columns);
};

function onTasksBulkDelete() {
  taskRefreshAfterBulkAction();
  clearTaskSelection();
}
function onTasksBulkArchive() {
  taskRefreshAfterBulkAction();
}

function getChecklistProgress(checklists_stats) {
  return Number.parseInt((checklists_stats.checked_items / checklists_stats.total_items) * 100);
}

defineExpose({
  view: 'list',
  getTasks,
  searchTasks,
});
const custom_view_configuration = {
  service: 'tickets',
  resource_type: 'tasks',
  feature: 'list_view',
  name: auth_store.current_organization.uid,
  store_key: 'tickets_tasks_list_view',
  modify_permissions: auth_store.check_permission('modify_tasks', route.params.asset_id),
};
</script>

<template>
  <TasksBulkActionsRow
    v-if="selected_tasks.length"
    class="mb-8"
    :selected_tasks="selected_tasks"
    @clear-selections="clearTaskSelection"
    @delete-tasks="onTasksBulkDelete"
    @archive-tasks="onTasksBulkArchive"
  />

  <div v-if="is_compact_view">
    <TaskCompactView
      :id="id"
      ref="task_compact_view"
      :options="options"
      :class="[{ 'px-2': is_widget }, wrapper_class]"
      :tasks="data"
      :wrapper_style="wrapper_style"
      :pagination_state="{
        totalRows: task_store.total_task_count,
        page_number: state.page_number,
        page_size: state.page_size,
      }"
      :load_more="(e) => getTasks(e, true)"
      @view-task="($event) => emit('viewTask', $event.uid)"
    >
      <template #context_menu="{ task }">
        <slot name="context_menu" :task="task" />
      </template>
    </TaskCompactView>
  </div>

  <component :is="props?.table_options?.disable_resize ? 'div' : 'a'" v-else>
    <component
      :is="props?.table_options?.no_table_wrapper ? 'div' : TableWrapperVue"
      :class="{ scrollbar: props?.table_options?.no_table_wrapper }"
      container_class="border-0"
      :style="wrapper_style"
    >
      <HawkTable
        :pagination_config="{ totalRows: task_store.total_task_count, pageSize: 25 }"
        :data="data"
        :is_loading="state.is_loading"
        :columns="columns"
        :default_columns="default_columns"
        :non_sortable_columns="['context_menu']"
        :manual_pagination="true"
        :manual_sorting="true"
        :get-children="getChildren"
        :freeze_table="state.freeze_table"
        :show_menu_header="props.table_options?.hide_context_menu"
        :additional_row_classes="props.table_options?.additional_row_classes"
        :additional_table_classes="props.table_options?.additional_table_classes"
        :disable_resize="props.table_options?.disable_resize"
        :custom_view_configuration="custom_view_configuration"
        :header_context_menu_config="custom_view_configuration.modify_permissions ? {
          is_menu_fixed: true,
          column_settings: true,
        } : {}"
        is_gapless
        @table-instance="table_instance = $event"
        @sub-rows="rowExpanded($event)"
        @pagination="getTasks($event)"
        @sort="getTasks($event)"
        @select-row="handleTaskSelection"
        @row-clicked="($event) => handleViewTask($event.uid)"
        @column-resized="emitColumnResized"
      >
        <template v-for="(value, key) in state.info_slot_data" :key="key" #[`row_info_${key}`]>
          <div class="bg-warning-200 text-warning-700 inline-flex w-auto rounded-md items-center py-1 px-2 ml-10 mt-1">
            <IconHawkAlertTriangle class="mr-1 h-4" />
            <p class="text-xs font-medium">
              {{ value }}
            </p>
          </div>
        </template>
        <template #name="task_name">
          <slot name="task_name" :element="task_name.data.row.original">
            <TaskName
              :task="task_name.data.row.original"
              :asset_uid="route.params.asset_id ? null : task_name.data.row.original?.target_element?.asset"
              :hide_breadcrumbs="options.hide_breadcrumbs"
              :show_asset="true"
            >
              <template #right>
                <div class="flex items-center">
                  <TaskDependency :uid="task_name.data.row.original?.uid" />
                  <HawkBadge
                    v-if="task_name.data.row.original?.sub_task_uids?.length && !props.options.include_subtasks"
                    v-tippy="`${task_name.data.row.original?.sub_task_uids?.length} Subtasks`"
                    class="mx-1" color="blue"
                  >
                    <IconHawkDependencyLinked class="text-primary h-3" />
                    {{ task_name.data.row.original?.sub_task_uids?.length }}
                  </HawkBadge>
                  <HawkBadge
                    v-if="!props.options?.disable_subtask_create
                      && checkTaskPermission({ permission: 'can_create_tasks' })
                    "
                    class="mx-1 invisible group-hover:visible" color="blue"
                    @click.stop="handleSubtaskCreate(task_name.data.row.original, task_name.data.row)"
                  >
                    <IconHawkPlusWhite
                      class="text-primary h-2.5"
                    />
                  </HawkBadge>
                </div>
              </template>
            </TaskName>
          </slot>
        </template>
        <template #checklists_stats="checklists_stats">
          <div v-if="checklists_stats.data.getValue()?.total_items" class="flex items-baseline w-full">
            <p class="text-md font-medium text-gray-600 mr-4">
              {{ getChecklistProgress(checklists_stats.data.getValue()) }}%
            </p>
            <div class="min-w-[150px] w-full bg-gray-100 h-[8px] rounded-lg">
              <div
                class="bg-primary-500 h-full rounded-lg"
                :style="{
                  width:
                    `${getChecklistProgress(checklists_stats.data.getValue())}%`,
                }"
              />
            </div>
          </div>
          <span v-else>
            -
          </span>
        </template>
        <template #context_menu="task">
          <slot name="context_menu" :element="task">
            <ContextMenuDropdown
              :task="task?.data?.row?.original"
              location="task_list"
              @add-dependencies="$event => emit('addDependencies', $event)"
              @archive-task="$event => emit('archiveTask', $event)"
              @convert-to-subtask="$event => emit('convertToSubtask', $event)"
              @copy-task="$event => emit('copyTask', $event)"
              @delete-task="$event => emit('deleteTask', $event)"
              @duplicate-task="$event => emit('duplicateTask', $event)"
              @view-task="$event => handleViewTask($event.uid)"
              @click.stop=""
              @open="state.freeze_table = task?.data?.row?.id"
              @close="state.freeze_table = '-1'"
            >
              <template #trigger>
                <IconHawkDotsVertical class="h-4 w-4" />
              </template>
            </ContextMenuDropdown>
          </slot>
        </template>
        <template #rowLoader>
          <HawkSkeleton custom_classes="!w-32 py-2" />
        </template>
      </HawkTable>
    </component>
  </component>
</template>
